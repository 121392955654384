import { API_ENDPOINT } from '../../constants/config.js';
import * as types from '../ActionTypes.js';

const defaultRequestState = { pending: false, error: undefined, result: undefined };

const initialState = {
    getTariffs: defaultRequestState,
    createCheckoutToken: defaultRequestState,
};

const TariffsReducer = (state = initialState, { type, result, error, ...action }) => {
    if (type === types.tariffs.createCheckoutToken.success) {
        const { stripe_token } = result;
        window.location.href = `${API_ENDPOINT}/stripe/create-checkout-session?stripe_token=${stripe_token}`;
    }
    switch (type) {
        case types.tariffs.getTariffs.start:
            return { ...state, getTariffs: { ...state.getTariffs, pending: true } };
        case types.tariffs.getTariffs.success:
            return { ...state, getTariffs: { ...state.getTariffs, pending: false, result } };
        case types.tariffs.getTariffs.fail:
            return { ...state, getTariffs: { ...state.getTariffs, pending: false, error } };

        case types.tariffs.createCheckoutToken.start:
            return { ...state, createCheckoutToken: { ...state.createCheckoutToken, pending: true } };
        case types.tariffs.createCheckoutToken.success:
            return { ...state, createCheckoutToken: { ...state.createCheckoutToken, pending: false, result } };
        case types.tariffs.createCheckoutToken.fail:
            return { ...state, createCheckoutToken: { ...state.createCheckoutToken, pending: false, error } };

        default:
            return state;
    }
};

export default TariffsReducer;
