/*eslint-disable*/
import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    serversMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const ServersReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_SERVER:
        case types.UPDATE_SERVER:
        case types.DELETE_SERVER:
        case types.LOAD_SERVERS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_SERVER_FAIL:
        case types.UPDATE_SERVER_FAIL:
        case types.DELETE_SERVER_FAIL:
        case types.LOAD_SERVERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_SERVER_SUCCESS:
        case types.UPDATE_SERVER_SUCCESS:
            return {
                ...state,
                loading: false,
                serversMap: state.serversMap.set(action.server.id, action.server)
            }


        case types.DELETE_SERVER_SUCCESS:
            return {
                ...state,
                loading: false,
                serversMap: state.serversMap.delete(action.id),
            }


        case types.LOAD_SERVERS_SUCCESS:
            return {
                ...state,
                loading: false,
                serversMap: state.serversMap
                    .merge(action.servers.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default ServersReducer;

/*eslint-enable*/