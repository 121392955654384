/*eslint-disable*/
const initialState = {
    project: {},
    audioRef: undefined,
    audioBlob: undefined,
    history: [],
    activeUpdate: +new Date(),
    hasChanges: false,
    selectedLayerId: undefined,
    resizeWindowVisible: false,
    resolutionWindowVisible: false,
    store: undefined,
    resolutionDrop: +localStorage.getItem(`resolutionDrop`) || 1 / 2,
    fpsDrop: +localStorage.getItem(`fpsDrop`) || 15,
};

export default (state = initialState, action) => {
    let newProject, newHistory, newActiveUpdate
    switch (action.type) {

        case `setProject`:
            newHistory = { date: +new Date(), content: JSON.stringify(action.data) }
            return { ...state, project: action.data, history: [...state.history.filter((a, b) => a.date <= state.activeUpdate), newHistory], activeUpdate: newHistory.date };
        case `setAudio`: return { ...state, audioRef: action.data.ref, audioBlob: action.data.blob };
        case `setActiveUpdate`: return { ...state, activeUpdate: action.data };
        case `setSelectedLayerId`: return { ...state, selectedLayerId: action.data, store: undefined, };
        case `setLayers`:
            newProject = { ...state.project, layers: action.data }
            newHistory = { date: +new Date(), content: JSON.stringify(newProject) }
            return { ...state, project: newProject, hasChanges: state.project.layers.map(i => i.id) === action.data.map(i => i.id) ? state.hasChanges : true, history: [...state.history.filter((a, b) => a.date <= state.activeUpdate), newHistory], activeUpdate: newHistory.date };
        case `setResizeWindowVisible`: return { ...state, resizeWindowVisible: action.data };
        case `setResolutionWindowVisible`: return { ...state, resolutionWindowVisible: action.data };
        case `setDimensions`:
            newProject = { ...state.project, dimensions: action.data }
            newHistory = { date: +new Date(), content: JSON.stringify(newProject) }
            return { ...state, project: newProject, hasChanges: state.project.dimensions === action.data ? state.hasChanges : true, history: [...state.history.filter((a, b) => a.date <= state.activeUpdate), newHistory], activeUpdate: newHistory.date };
        case `setResolutionDrop`: localStorage.setItem(`resolutionDrop`, action.data); return { ...state, resolutionDrop: action.data };
        case `setFpsDrop`: localStorage.setItem(`fpsDrop`, action.data); return { ...state, fpsDrop: action.data };
        case `UPDATE_PROJECT_SUCCESS`: return { ...state, hasChanges: false };
        case `setStore`: return { ...state, store: action.data, selectedLayerId: undefined, };
        case `onParamChange`:
            // console.log({ ...state.project, layers: state.project.layers.map(i => ({ ...i, ...action.datta })) }.layers[0].width_p);
            newProject = { ...state.project, layers: state.project.layers.map(i => ({ ...i, ...action.datta })) }.layers[0].width_p
            // newProject = { ...state.project, layers: state.project.layers.map(i => { if (i.id === state.selectedLayerId) { i = { ...i, ...action.data } }; return i }) }
            newHistory = { date: +new Date(), content: JSON.stringify(newProject) }
            return { ...state, hasChanges: true, project: newProject, history: [...state.history.filter((a, b) => a.date <= state.activeUpdate), newHistory], activeUpdate: newHistory.date };
        case `undoHistory`:
            newActiveUpdate = state.history.length > 1 ? state.history[state.history.map(i => i.date).indexOf(state.activeUpdate) - 1].date : state.activeUpdate
            return { ...state, activeUpdate: newActiveUpdate, project: { ...JSON.parse(state.history.filter(i => i.date === newActiveUpdate)[0].content) } };
        case `redoHistory`:
            newActiveUpdate = state.history.length > -1 && state.history.map(i => i.date).indexOf(state.activeUpdate) < state.history.length - 1 ? state.history[state.history.map(i => i.date).indexOf(state.activeUpdate) + 1].date : state.activeUpdate
            return { ...state, activeUpdate: newActiveUpdate, project: { ...JSON.parse(state.history.filter(i => i.date === newActiveUpdate)[0].content) } };
        case `resetEditorState`: return { ...initialState };

        default: return state;
    }
}
/*eslint-enable*/