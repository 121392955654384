/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import WebFont from 'webfontloader';

import { FontList } from '../../../constants/mvConsts';
import * as projectsActions from '../../../redux/actions/ProjectsActions';

import PreloaderPage from '../../pages/preloader-page';
import Editor from '../tools/Editor';

import { customHandler } from '../../helpers/useCustomDispatch';

let ProjectAmpPanel = (props) => {
    let path = useLocation().pathname.split(`/`).slice(1);
    let id = path[1];

    let dispatch = useDispatch();
    let [project, setProject] = useState(undefined);

    useEffect(() => {
        customHandler(`OPEN_PRELOADER`);
        WebFont.load({
            google: { families: FontList },
            fontloading: async (a, b) => {
                let project = (await dispatch(projectsActions.getProject(id))).projects.get(id);
                setProject(project);
                customHandler(`CLOSE_PRELOADER`);
            },
        });
    }, [id]);

    return project === undefined ? <PreloaderPage /> : <Editor project={project} />;
};

export default ProjectAmpPanel;
/*eslint-enable*/
