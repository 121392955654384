import moment from 'moment';
import React from 'react';
import InputMask from 'react-input-mask';
import Select, { makeAnimated } from 'react-select';
import styled from 'styled-components';
import { Checkbox, Frame, Input, Text, Textarea } from './styled-templates';

const animatedComponents = makeAnimated();

const validateDate = (date) => {
    date = date.split(`.`);
    if (!isNaN(+date[0])) {
        date[0] = Math.min(31, +date[0]);
    }
    if (!isNaN(+date[1])) {
        date[1] = Math.min(12, +date[1]);
    }
    if (!isNaN(+date[2])) {
        date[2] = Math.max(+moment().format(`YYYY`), Math.min(2120, +date[2]));
    }
    return date.join(`.`);
};

const Form = (props) => {
    let { fields = [], onChange = () => {}, data = {}, editable = true, extra = `` } = props;

    return (
        <Wrapper extra={extra}>
            {fields.map((item, index) => {
                let key = item.name.toLocaleLowerCase().split(` `).join(`_`);
                if (editable === false) {
                    return (
                        <Field key={index}>
                            <Label>{item.name}</Label>
                            <Text extra={`margin: 0.5vw;`} bold>
                                {data ? data[key] || `` : ``}
                            </Text>
                        </Field>
                    );
                }
                switch (item.type) {
                    case `string`:
                    case `number`:
                        return (
                            <Field key={index}>
                                <Input
                                    {...item}
                                    value={data ? data[key] || `` : ``}
                                    onChange={(e) => {
                                        onChange(key, e.target.value);
                                    }}
                                    placeholder={item.placeholder || item.name}
                                />
                            </Field>
                        );
                    case `password`:
                        return (
                            <Field key={index}>
                                <Input
                                    value={data ? data[key] || `` : ``}
                                    type={`password`}
                                    onChange={(e) => {
                                        onChange(key, e.target.value);
                                    }}
                                    short={item.short}
                                    extra={item.extra}
                                    placeholder={item.name || `Password`}
                                />
                            </Field>
                        );
                    case `date`:
                        return (
                            <Field key={index}>
                                <InputMask
                                    mask="99.99.9999"
                                    value={data ? data[key] || `` : ``}
                                    onChange={(e) => {
                                        onChange(key, validateDate(e.target.value));
                                    }}
                                    short={item.short}
                                    extra={item.extra}
                                    placeholder={item.name}
                                    pattern={`[0-9]*`}
                                >
                                    {(props) => <Input number {...props} />}
                                </InputMask>
                            </Field>
                        );
                    case `phone`:
                        return (
                            <Field key={index}>
                                <InputMask
                                    mask="+370 99 999 999"
                                    value={data ? data[key] || `` : ``}
                                    onChange={(e) => {
                                        onChange(key, e.target.value);
                                    }}
                                    short={item.short}
                                    extra={item.extra}
                                    placeholder={item.name}
                                    pattern={`[0-9]*`}
                                >
                                    {(props) => <Input {...props} />}
                                </InputMask>
                            </Field>
                        );
                    case `textarea`:
                        return (
                            <Field key={index}>
                                <Textarea
                                    value={data ? data[key] || `` : ``}
                                    onChange={(e) => {
                                        onChange(key, e.target.value);
                                    }}
                                    short={item.short}
                                    extra={item.extra}
                                    placeholder={item.name}
                                />
                            </Field>
                        );
                    case `select`:
                        let labelTransformer = (i) => {
                            if (item.labelTransformer !== undefined) {
                                return item.labelTransformer(i);
                            }
                            return i;
                        };

                        const cutString = (string, length) => (string.length > length ? string.slice(0, length) + `...` : string);
                        return (
                            <Field key={index}>
                                <MySelect
                                    isMulti={item.isMulti === true}
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    defaultValue={[]}
                                    value={
                                        data
                                            ? {
                                                  value: data[key],
                                                  label:
                                                      typeof labelTransformer(data[key]) === 'string'
                                                          ? cutString(labelTransformer(data[key]), 13)
                                                          : labelTransformer(data[key]),
                                              } || { value: `option`, label: `option` }
                                            : { value: `option`, label: `option` }
                                    }
                                    options={item.options.map((i) => ({ label: labelTransformer(i), value: i })) || []}
                                    onChange={(e) => {
                                        onChange(key, e.value);
                                    }}
                                    extra={item.extra}
                                />
                            </Field>
                        );
                    case `checkbox`:
                        return (
                            <Field key={index} row>
                                <Checkbox
                                    checked={data[key] === true}
                                    onChange={(e) => {
                                        onChange(key, data[key] === false);
                                    }}
                                />
                                <Text extra={`margin-left: 15px; font-size: 16px; margin-bottom: 0px;`}>{item.name}</Text>
                            </Field>
                        );
                    case `custom`:
                        return <Field key={index}>{item.component()}</Field>;
                    default:
                        return null;
                }
            })}
        </Wrapper>
    );
};

const MySelect = styled(Select).attrs((props) => {
    return {
        styles: {
            control: (styles) => ({
                ...styles,
                width: `350px`,
                height: `40px`,
                borderRadius: `6px`,
                border: `1px solid ${props.theme.background.support}`,
                background: `rgba(255, 255, 255, 0.05)`,
                fontSize: `14px`,
                ...props.extra,
            }),

            menu: (style) => ({
                ...style,
                borderRadius: `6px`,
                margin: 0,
                padding: 0,
                maxHeight: `400px`,
                background: props.theme.background.overlay,
                overflow: `hidden`,
            }),

            menuList: (style) => ({
                ...style,
                margin: 0,
                padding: 0,
            }),

            placeholder: (style) => ({ ...style, marginLeft: `12px`, fontSize: `14px` }),
            input: (style) => ({ marginLeft: `12px`, display: `none` }),

            indicatorSeparator: (style) => ({
                opacity: 0,
            }),
            option: (styles) => ({
                ...styles,
                background: props.theme.background.overlay,
                color: props.theme.text.primary,
                zIndex: 2000,
                fontSize: `14px`,
                ':hover': { backgroundColor: props.theme.background.support },
            }),
            singleValue: (style) => ({
                color: props.theme.text.primary,
            }),
        },
    };
})``;

const Field = styled(Frame)`
    align-items: flex-start;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
        margin-bottom: 6.25vw;
    }
`;

const Label = styled(Text)`
    margin: 0.5vw;
    color: ${(props) => props.theme.text.secondary};
`;

const Wrapper = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 540px;
    ${(props) => props.extra} @media only screen and(max-width: 600 px) {
        width: 90vw;
        margin: 0 !important;
    }
`;

export default Form;
