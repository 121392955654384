import TariffsApi from '../../api/TariffsApi';

import * as types from '../ActionTypes';

const tariffsActions = {
    getTariffs: {
        start: () => ({ type: types.tariffs.getTariffs.start }),
        success: (result) => ({ type: types.tariffs.getTariffs.success, result }),
        fail: (error) => ({ type: types.tariffs.getTariffs.fail, error }),
    },
    createCheckoutToken: {
        start: () => ({ type: types.tariffs.createCheckoutToken.start }),
        success: (result) => ({ type: types.tariffs.createCheckoutToken.success, result }),
        fail: (error) => ({ type: types.tariffs.createCheckoutToken.fail, error }),
    },
};

export const getTariffs = () => {
    return (dispatch, getState) => {
        dispatch(tariffsActions.getTariffs.start());
        return TariffsApi.getTariffs().then(
            (result) => dispatch(tariffsActions.getTariffs.success(result)),
            (error) => dispatch(tariffsActions.getTariffs.fail(error))
        );
    };
};

export const createCheckoutToken = (tariffId) => {
    return (dispatch, getState) => {
        dispatch(tariffsActions.createCheckoutToken.start());
        return TariffsApi.createCheckoutToken(tariffId)
            .then((result) => dispatch(tariffsActions.createCheckoutToken.success(result)))
            .catch((error) => dispatch(tariffsActions.createCheckoutToken.fail(error)));
    };
};
