/*eslint-disable*/
import React, {useState, useEffect, useCallback} from 'react'
import styled from 'styled-components'
import {useMappedState} from 'redux-react-hook'
import uuid from 'uuid'

import {Frame} from '../../UIKit/styled-templates'
import useCustomDispatch from '../../helpers/useCustomDispatch'

let StoreTemplates = (props) => {

    let { type } = props

    let { templates, projects } = useMappedState(useCallback((state) => ({
        templates: state.templates.templatesMap.toArray().filter(i => i.type === type),
        projects: state.projects.projectsMap.toArray().filter(i => i.preset),
    }), [type]))

    let onAddLayer = (item) => {
        let new_layer = {
            ...default_properties, ...item.defaultValues,
            availableSettings: item.availableSettings,
            template_id: item.id,
            template: item.code,
            type: item.type,
            id: uuid()
        }
        window.dispatchEvent(new CustomEvent(`ADD_LAYER`, { detail: new_layer }))
    }

    let onApplyPreset = (preset) => {
        window.dispatchEvent(new CustomEvent(`APPLY_PRESET`, { detail: preset }))
    }

    return (
        <Wrapper>
            {
                (type === `template` ? projects : templates).map((item, index) => {
                    return <Template key={index} content={item.name} preview={item.preview} onClick={() => { type === `template` ? onApplyPreset(item) : onAddLayer(item) }}/>
                })
            }
        </Wrapper>
    )
}

const Wrapper = styled(Frame)`
  width: 270px;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled(Frame)`
  width: 90%;
  align-items: flex-start;
  margin-bottom: 10px;

  &:before {
    font-size: 16px;
    font-weight: bold;
    content: '${props => props.content}';
  }
`;

const Template = styled(Frame)`
  width: 130px;
  height: 130px;
  border-radius: 20px;
  margin-bottom: 10px;
  /* background: red; */
  color: white;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;

  ${props => props.preview ? `background: url("${props.preview}") no-repeat center center / contain;` : `background: rgba(255, 255, 255, 0.1);`}
  &:hover {
    opacity: 0.8;
  }
;

  &:before {
    display: flex;
    content: '${props => props.preview ? `` : props.content}';
  }
`;

let default_properties = {
    top: 0.25,
    left: 0.25,
    width_p: 0.5,
    height_p: 0.5,
    color: `#2DC76D`
}

export default StoreTemplates;
/*eslint-enable*/
