import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { API_ENDPOINT } from '../../constants/config';
import { Frame, H, Link } from '../UIKit/styled-templates';

const RenderingPage = () => {
    const [jobs, setJobs] = useState([]);
    let [renderingStatus, setRenderingStatus] = useState({});

    let path = useLocation().pathname.split(`/`).slice(1);
    let id = path[1];

    useEffect(() => {
        axios
            .get(`${API_ENDPOINT}/project/${id}/jobs`)
            .then((d) => d.data.result)
            .then((arr) => {
                setJobs(arr.sort((a, b) => b.updatedTimestamp - a.updatedTimestamp));
            });
    }, [id, renderingStatus.status]);

    let finishedJobs = jobs.filter((x) => x.status === 'finished');

    const interval = useRef();
    useEffect(() => {
        interval.current = setInterval(async () => {
            setRenderingStatus((await axios.get(`https://api.makewave.online/project/${id}/rendering_status`)).data.result);
            console.log((await axios.get(`https://api.makewave.online/project/${id}/rendering_status`)).data);
        }, 1000 * 3);
        return () => {
            clearInterval(interval.current);
        };
    }, [id]);

    console.log(renderingStatus);
    console.log(finishedJobs);

    return (
        <Wrapper>
            {(() => {
                switch (renderingStatus.status) {
                    case 'queue':
                        return <H>In queue...</H>;
                    case 'in_progress':
                        return <H>{`Rendering... ${Math.round(renderingStatus.progress || 0)}%`}</H>;
                    case 'finished':
                        return (
                            <Frame row extra={`align-items: flex-start; z-index: 1;`}>
                                <JobsListPlaceholder>
                                    {finishedJobs.slice(0, 1).map((job) => {
                                        return (
                                            <JobItem key={job.id}>
                                                <Video controls={true}>
                                                    <source src={job.resultUrl} />
                                                </Video>
                                            </JobItem>
                                        );
                                    })}
                                </JobsListPlaceholder>

                                <Frame extra={`margin-left: 25px; z-index: 1;`}>
                                    <a href={finishedJobs.slice(0, 1)[0].resultUrl}>
                                        <DownloadButton>Download video</DownloadButton>
                                    </a>
                                    <Link to={`/project/${id}/amp`}>
                                        <EditButton />
                                    </Link>
                                </Frame>
                            </Frame>
                        );
                    default:
                        return <H>Loading...</H>;
                }
            })()}
        </Wrapper>
    );
};

const DownloadButton = styled(Frame)`
    width: 350px;
    height: 55px;
    border-radius: 6px;
    background: ${({ theme }) => theme.red};
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 16px;
    font-family: Bold;
    color: white;
`;

const EditButton = styled(Frame)`
    width: 350px;
    height: 55px;
    border-radius: 6px;
    background: ${(props) => props.theme.green};
    margin-bottom: 25px;
    cursor: pointer;
    &:after {
        content: 'Edit video';
        font-size: 16px;
        font-family: Bold;
        color: white;
    }
`;

const Wrapper = styled(Frame)`
    /* width: 100vw; */
    /* height: 100vh; */
    height: calc(100vh - 80px);
`;

const Video = styled.video`
    height: 300px;
`;

const JobsListPlaceholder = styled.div``;

const JobItem = styled.div`
    margin-bottom: 10px;
`;

export default RenderingPage;
