/*eslint-disable*/
import TemplatesAPI from '../../api/TemplatesAPI'

import * as types from '../ActionTypes'

let loadTemplates_ = () => {
    return {
        type: types.LOAD_WAVE_TEMPLATES
    }
}
let loadTemplatesSuccess = (templates) => {
    return {
        type: types.LOAD_WAVE_TEMPLATES_SUCCESS,
        templates: templates
    }
}
let loadTemplatesFailed = (error) => {
    return {
        type: types.LOAD_WAVE_TEMPLATES_FAIL,
        error: error
    }
}

//thunk
export function loadAllTemplates() {
    return (dispatch, getState) => {
        dispatch(loadTemplates_());
        return TemplatesAPI.getAllTemplates().then(
            templates => dispatch(loadTemplatesSuccess(templates)),
            error => dispatch(loadTemplatesFailed(error))
        )
    }
}

let createTemplate_ = () => {
    return {
        type: types.CREATE_WAVE_TEMPLATE
    }
}
let createTemplateSuccess = (template) => {
    return {
        type: types.CREATE_WAVE_TEMPLATE_SUCCESS,
        template: template
    }
}
let createTemplateFailed = (error) => {
    return {
        type: types.CREATE_WAVE_TEMPLATE_FAIL,
        error: error
    }
}

//thunk
export function createTemplate(data) {
    return (dispatch, getState) => {
        dispatch(createTemplate_());
        return TemplatesAPI.createTemplate(data).then(
            template => dispatch(createTemplateSuccess(template)),
            error => dispatch(createTemplateFailed(error))
        )
    }
}

let updateTemplate_ = () => {
    return {
        type: types.UPDATE_WAVE_TEMPLATE
    }
}
let updateTemplateSuccess = (template) => {
    return {
        type: types.UPDATE_WAVE_TEMPLATE_SUCCESS,
        template: template
    }
}
let updateTemplateFailed = (error) => {
    return {
        type: types.UPDATE_WAVE_TEMPLATE_FAIL,
        error: error
    }
}

//thunk
export function updateTemplate(data) {
    return (dispatch, getState) => {
        dispatch(updateTemplate_());
        return TemplatesAPI.updateTemplate(data).then(
            template => dispatch(updateTemplateSuccess(template)),
            error => dispatch(updateTemplateFailed(error))
        )
    }
}

let deleteTemplate_ = () => {
    return {
        type: types.DELETE_WAVE_TEMPLATE
    }
}
let deleteTemplateSuccess = (id) => {
    return {
        type: types.DELETE_WAVE_TEMPLATE_SUCCESS,
        id: id
    }
}
let deleteTemplateFailed = (error) => {
    return {
        type: types.DELETE_WAVE_TEMPLATE_FAIL,
        error: error
    }
}

//thunk
export function deleteTemplate(id) {
    return (dispatch, getState) => {
        dispatch(deleteTemplate_());
        return TemplatesAPI.deleteTemplate(id).then(
            () => dispatch(deleteTemplateSuccess(id)),
            error => dispatch(deleteTemplateFailed(error))
        )
    }
}



/*eslint-enable*/