/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'

import { Frame, convertHex } from '../UIKit/styled-templates'

import useCustomDispatch from '../helpers/useCustomDispatch'

let PopUpWrapper = (props) => {

    let { name = `` } = props

    let [visible, setVisible] = useState(false)

    useCustomDispatch(`OPEN_${name}_POP_UP`, () => { setVisible(true) })
    useCustomDispatch(`CLOSE_${name}_POP_UP`, () => { setVisible(false) })

    useEffect(() => {
        document.getElementsByTagName(`body`)[0].style.overflowY = visible ? `hidden` : `scroll`
    }, [visible])

    let onClose = () => { window.dispatchEvent(new CustomEvent(`CLOSE_${name}_POP_UP`)) }

    return (
        <Frame>
            <DarkOverlay visible={visible} onClick={onClose} />
            <OpenProjectTab visible={visible}>
                <Cross onClick={onClose} />
                {props.children}
            </OpenProjectTab>
        </Frame >
    );
}



let Cross = styled.img.attrs(() => {
    let img
    try { img = require(`../../assets/images/cross_r.svg`) } catch (error) { }
    return ({ src: img, })
})`
    width: 24px;
    height: 24px;
    position: absolute;
    top: -40px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        transform: rotate(90deg);
    };
`

const OpenProjectTab = styled(Frame)`
    min-width: 480px;
    height: auto;
    padding: 30px 60px;
    border-radius: 6px;
    background: ${props => props.theme.background.overlay};
    position: fixed;
    top: 50%;
    transform: translateY(${props => props.visible ? `-50%` : `100vh`});
    z-index: 3;
    box-sizing: border-box;

    @media only screen and (max-width: 600px) {
        min-width: auto;
        width: 96vw;
        padding: 9vw 3vw;
        transition: 0.2s;
    }
`;

const DarkOverlay = styled(Frame)`
    width: 100vw;
    height: 100vh;
    background: ${props => convertHex(props.theme.background.primary, props.visible ? 0.75 : 0)};
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible * props.blur * 8}px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
`;

export default PopUpWrapper;
/*eslint-enable*/