/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useMappedState } from 'redux-react-hook'

import { Frame, H1, H, Button } from '../UIKit/styled-templates'
import Form from '../UIKit/Form'

import useCurrentUser from '../helpers/useCurrentUser'

let fields = [
    { type: `string`, name: `Name` },
    { type: `string`, name: `Email` },
]

let SettingsPage = (props) => {

    let { currentUser } = useCurrentUser()
    let [data, setData] = useState({ email: currentUser.email })

    return (
        <Frame>
            <Content>
                <Title>Profile</Title>
                <Form
                    data={data}
                    fields={fields}
                    onChange={(key, value) => { setData({ ...data, [key]: value }) }}
                />
                <SaveButton />
            </Content>
        </Frame>
    )
}

const SaveButton = styled(Frame)`
    width: 220px;
    height: 55px;
    border-radius: 27.5px;
    background: ${props => props.theme.yellow};
    color: ${props => props.theme.background.primary};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:after {
        content: 'Save';
        font-size: 14px;
        font-family: Bold;
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    > * {
        &:nth-child(3) {
            margin-top: 45px;
        }
    }
`;

const SelectorItem = styled(Frame)`
    width: 170px;
    height: 45px;
    left: calc(170px * ${props => props.index} - 2px);
    position: absolute;
    z-index: 1;
    &:hover {
        opacity: 0.8;
    }
    &:after {
        content: '${props => props.content}';
        color: ${props => props.selected ? `#00063A` : `#C4C4C4`};
        font-size: 18px;
        font-weight: bold;
    }
`;

const SelectorWrapper = styled(Frame)`
    flex-direction: row;
    width: 340px;
    height: 45px;
    border-radius: 22.5px;
    border: 2px solid ${props => props.theme.yellow};
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        width: 170px;
        height: 45px;
        border-radius: 45px;
        background: ${props => props.theme.yellow};
        transform: translateX(calc(${props => props.selected === 0 ? -1 : +1} * 50%));
        transition: 0.3s cubic-bezier(0.31, 0.06, 0.56, 2.01);
    }
`;

const Title = styled(H)`
    margin: 60px 0px;
`;

export default SettingsPage;
/*eslint-enable*/