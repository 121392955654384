import React from 'react';
import styled from 'styled-components';

import imageInvoice from '../../assets/images/invoice.svg';
import { Frame, H, P } from '../UIKit/styled-templates';

const BillingPage = (props) => {
    const onUnsubscribe = () => {};

    return (
        <Frame>
            <Content>
                <Title>Billing</Title>
                <BillingWrapper>
                    <Heading>
                        <Frame>Plan name</Frame>
                        <Frame>10 video / month</Frame>
                        <Frame>24$ / month</Frame>
                    </Heading>
                    <ColumnNames>
                        <Frame>Data</Frame>
                        <Frame>Product</Frame>
                        <Frame>Total</Frame>
                        <Frame>Status</Frame>
                        <Frame>Invoice</Frame>
                    </ColumnNames>
                    <List>
                        {new Array(2).fill(0).map((item, index) => {
                            return (
                                <Item key={index}>
                                    <Frame>12.12.20</Frame>
                                    <Frame>Medium plan</Frame>
                                    <Frame>$12</Frame>
                                    <Frame>PAID</Frame>
                                    <Frame />
                                </Item>
                            );
                        })}
                    </List>
                </BillingWrapper>
                <Frame row extra={`margin-top: 60px;`}>
                    <P extra={`margin-bottom: 0;`}>If you want to cancel the subscription, click on the</P>
                    <P extra={(props) => `margin-bottom: 0; color: ${props.theme.pink}; margin: 0 5px; cursor: pointer;`} onClick={onUnsubscribe}>
                        Unsubscribe
                    </P>
                    <P extra={`margin-bottom: 0;`}>button</P>
                </Frame>
            </Content>
        </Frame>
    );
};

const List = styled(Frame)`
    > * {
        margin-bottom: 20px;
        &:last-child {
            margin: 0;
        }
    }
`;

const Item = styled(Frame)`
    width: 730px;
    flex-direction: row;
    > * {
        align-items: flex-start;
        font-size: 14px;
        &:nth-child(1) {
            width: 100%;
            flex: 160;
        }
        &:nth-child(2) {
            width: 100%;
            flex: 230;
        }
        &:nth-child(3) {
            width: 100%;
            flex: 135;
        }
        &:nth-child(4) {
            width: 100%;
            flex: 120;
            color: ${(props) => props.theme.blue};
        }
        &:nth-child(5) {
            width: 100%;
            flex: 80;
            align-items: center;
            transition: 0.2s;
            cursor: pointer;
            &:hover {
                opacity: 0.3 !important;
            }
            &:after {
                content: '';
                width: 24px;
                height: 24px;
                background: url('${imageInvoice}') no-repeat center center / contain;
            }
        }
    }
`;

const ColumnNames = styled(Item)`
    flex-direction: row;
    padding-bottom: 0 !important;
    > * {
        color: ${(props) => props.theme.text.secondary} !important;
        font-size: 10px;
        &:nth-child(5) {
            width: 100%;
            flex: 80;
            &:after {
                content: '';
                width: 0;
                height: 0;
                background: none;
            }
        }
    }
`;

const Heading = styled(Frame)`
    width: 730px;
    flex-direction: row;
    justify-content: space-between;
    > * {
        align-items: flex-end;
        font-family: Bold;
        font-size: 14px;
        &:nth-child(1) {
            width: 100%;
            flex: 2;
            align-items: flex-start;
        }
        &:nth-child(2) {
            width: 100%;
            flex: 1;
        }
        &:nth-child(3) {
            width: 100%;
            flex: 1;
        }
    }
    border-bottom: 2px solid ${(props) => props.theme.background.primary};
`;

const BillingWrapper = styled(Frame)`
    width: auto;
    height: auto;
    border-radius: 10px;
    background: ${(props) => props.theme.background.secondary};
    > * {
        padding: 30px;
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    min-height: calc(100vh - 80px - 50px);
    justify-content: flex-start;
    color: white;
`;

const Title = styled(H)`
    margin: 60px 0px;
    color: white;
    font-family: Bold;
`;

export default BillingPage;
