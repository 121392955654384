import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useDispatch, useMappedState } from 'redux-react-hook';
import { createCheckoutToken, getTariffs } from '../../redux/actions/TariffsActions';
import store from '../../redux/store';
import { Frame, H, H1 } from '../UIKit/styled-templates';

const onBuyClicked = (tariffId) => (e) => {
    store.dispatch(createCheckoutToken(tariffId));
};

const PricingPage = () => {
    const dispatch = useDispatch();

    const tariffs = useMappedState((state) => state.tariffs.getTariffs.result || []);
    const isAuthorized = useMappedState((state) => !!state.users.currentUserId);
    const currentTariffId = useMappedState((state) => state.users.usersMap.get(state.users?.currentUserId)?.currentTariffId);

    useEffect(() => {
        dispatch(getTariffs());
    }, [dispatch]);

    return (
        <Frame>
            <Content>
                <Title>Pricing</Title>
                {tariffs.map((tariff, index) => {
                    const { id, minutes, name, price } = tariff;
                    const isCurrent = currentTariffId === id;
                    return (
                        <InlineBox key={index}>
                            <PlanName>{name}</PlanName>
                            <Options>
                                <span>{minutes} minutes</span>
                            </Options>
                            <H1>${price}</H1>
                            {isAuthorized && (
                                <PurchaseButton purchased={isCurrent} onClick={onBuyClicked(id)}>
                                    {isCurrent ? 'Current' : 'Buy now'}
                                </PurchaseButton>
                            )}
                        </InlineBox>
                    );
                })}
            </Content>
        </Frame>
    );
};

const PurchaseButton = styled(Frame)`
    width: 200px;
    height: 60px !important;
    border-radius: 30px;
    ${(props) => (props.purchased ? `background: linear-gradient(#DF72E6, #AE3EFF);` : `border: 2px solid white;`)}
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
        transform: scale(1.02);
    }
    font-size: 18px;
    font-family: bold;
`;

const Options = styled(Frame)`
    align-items: flex-start;
    width: 200px;
    > * {
        font-size: 16px;
        line-height: 32px;
        &:nth-child(1) {
            font-family: Bold;
        }
    }
`;

const PlanName = styled(Frame)`
    width: 200px;
    align-items: flex-start;
    font-size: 20px;
    font-family: Bold;
`;

const InlineBox = styled(Frame)`
    width: 1100px;
    height: 90px;
    padding: 40px 0;
    border-top: 1px solid #0d1244;
    box-shadow: 0 0 0.5vw ${({ theme, preset }) => (preset ? theme.green : `transparent`)};
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;
    color: white;
    > * {
        &:nth-child(3) {
            font-family: Bold;
            font-weight: bold;
            font-size: 48px;
            width: 100px;
        }
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    > * {
        &:nth-child(3) {
            margin-top: 45px;
        }
    }
`;

const Title = styled(H)`
    margin: 60px 0px;
`;

export default PricingPage;
