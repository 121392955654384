/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Frame, H1, H, P, Link } from '../../UIKit/styled-templates'

let Second = (props) => {

    return (
        <Content>
            <Title>
                <Frame>MakeWave makes</Frame>
                <Frame>cool video from audio</Frame>
            </Title>
            <ApplicationsList>
                <Frame>Social media Post</Frame>
                <Frame>Stories</Frame>
                <Frame>YouTube</Frame>
                <Frame>Ads</Frame>
            </ApplicationsList>
            <PhotosWrapper>
                <Photo />
                <Photo />
                <Photo />
            </PhotosWrapper>
        </Content>
    )
}

const Photo = styled(Frame)`
    width: 350px;
    height: 350px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
`;

const PhotosWrapper = styled(Frame)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
`;

const ApplicationsList = styled(Frame)`
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin: 50px 0;
    > * {
        color: white;
        font-size: 18px;
        margin-right: 106px;
        cursor: pointer;
        &:nth-child(1) {
            font-weight: Bold;
        }
    }
`;

const SaveButton = styled(Frame)`
    width: 220px;
    height: 55px;
    border-radius: 27.5px;
    background: ${props => props.theme.yellow};
    color: ${props => props.theme.background.secondary};
    margin-top: 50px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:after {
        content: 'Sign up for FREE';
        font-size: 14px;
        font-family: Bold;
    }
`;

const Title = styled(Frame)`
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    > * {
        font-family: Bold;
        font-size: 80px;
        line-height: 120px;
        &:nth-child(1) {
            color: ${props => props.theme.pink};
        }
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    min-height: calc(100vh - 80px - 50px);
    justify-content: flex-start;
`;

export default Second;
/*eslint-enable*/