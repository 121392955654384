import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import WebFont from 'webfontloader';
import imageDoubleBackArrow from '../../../assets/images/double-back-arrow.svg';

import { FontList } from '../../../constants/mvConsts';
import Form from '../../UIKit/Form';
import { Frame, H1, Input, Range, Switch, Text } from '../../UIKit/styled-templates';
import pro_settings from '../helpers/pro_settings';

import { customHandler } from '../../helpers/useCustomDispatch';

const ParametersAndLayers = (props) => {
    const { dimensions = `1080x1080`, selectedLayer = {}, onDelete = () => {} } = props;

    const onParamChange = (key, value) => {
        customHandler(`ON_PARAM_CHANGE`, { [key]: value });
    };

    const [color_picker, set_color_picker] = useState(undefined);
    const [pro_wave, set_pro_wave] = useState(true);

    const set_bold = () => {
        if (selectedLayer !== undefined) {
            onParamChange('font_weight', selectedLayer.font_weight === `bold` ? `normal` : `bold`);
        }
    };
    const set_italic = () => {
        if (selectedLayer !== undefined) {
            onParamChange('font_style', selectedLayer.font_style === `italic` ? `normal` : `italic`);
        }
    };
    const set_underline = () => {
        if (selectedLayer !== undefined) {
            onParamChange('font_underline', selectedLayer.font_underline == true ? false : true);
        }
    };
    const set_text_align = (value) => {
        if (selectedLayer !== undefined) {
            onParamChange('text_align', value);
        }
    };

    useEffect(() => {
        const listener = (e) => {
            if (e.keyCode === 8 || e.keyCode === 64 || e.key === `Delete`) {
                if (document.activeElement.tagName.toLowerCase() !== `input`) {
                    onDelete();
                }
                return false;
            }
            if (e.key === 'B' || e.key === 'b') {
                if (document.activeElement.tagName.toLowerCase() !== `input`) {
                    set_bold();
                }
                return false;
            }
            if (e.key === 'I' || e.key === 'i') {
                if (document.activeElement.tagName.toLowerCase() !== `input`) {
                    set_italic();
                }
                return false;
            }
        };
        window.addEventListener('keydown', listener);
        return () => {
            window.removeEventListener('keydown', listener);
        };
    }, [selectedLayer]);

    const lh = document.getElementById(`layer_0`) ? document.getElementById(`layer_0`).height : 0;
    return (
        <>
            <Title
                content={`Close`}
                onClick={() => {
                    customHandler(`UNSELECT_LAYER`);
                }}
            />
            {selectedLayer !== undefined && selectedLayer.type === `text` ? (
                <Frame extra={`align-items: flex-start;`}>
                    <H1>Text</H1>
                    <Frame row extra={`width: 270px;`}>
                        <Form
                            data={selectedLayer}
                            onChange={(key, value) => {
                                if (key === `font_family`) {
                                    WebFont.load({
                                        google: { families: [value] },
                                        fontloading: (a, b) => {
                                            onParamChange(key, value);
                                        },
                                    });
                                }
                                onParamChange(key, value);
                            }}
                            fields={[
                                {
                                    type: `select`,
                                    name: `font_family`,
                                    options: FontList,
                                    extra: { width: `180px` },
                                },
                                {
                                    type: `select`,
                                    name: `height_p`,
                                    options: new Array(10).fill(0).map((item, index, self) => ((index + 1) / 10) * 0.2),
                                    labelTransformer: (i) => Math.round(+dimensions.split(`x`)[1] * i),
                                    extra: { width: `86px` },
                                },
                            ]}
                        />
                    </Frame>
                    <Frame row extra={`width: 100%; justify-content: flex-start;`}>
                        <TextDecorationButton onClick={set_bold} selected={selectedLayer.font_weight === `bold`}>
                            <LogoImg name={`bold`} width={2} />
                        </TextDecorationButton>
                        <TextDecorationButton onClick={set_italic} selected={selectedLayer.font_style === `italic`}>
                            <LogoImg name={`italic`} width={2} />
                        </TextDecorationButton>
                        <TextDecorationButton onClick={set_underline} selected={selectedLayer.font_underline === true}>
                            <LogoImg name={`underline`} width={2} />
                        </TextDecorationButton>
                        <ColorBox
                            color={selectedLayer.color}
                            onClick={() => {
                                set_color_picker(color_picker !== `color` ? `color` : undefined);
                            }}
                        />
                    </Frame>
                    <Frame row extra={`width: 100%; justify-content: flex-start; margin-bottom: 15px;`}>
                        <TextDecorationButton
                            onClick={() => {
                                set_text_align(`start`);
                            }}
                            selected={selectedLayer.text_align === `start`}
                        >
                            <LogoImg name={`align_left`} width={2} />
                        </TextDecorationButton>
                        <TextDecorationButton
                            onClick={() => {
                                set_text_align(`center`);
                            }}
                            selected={selectedLayer.text_align === `center` || [`start`, `center`, `end`].indexOf(selectedLayer.text_align) === -1}
                        >
                            <LogoImg name={`align_center`} width={2} />
                        </TextDecorationButton>
                        <TextDecorationButton
                            onClick={() => {
                                set_text_align(`end`);
                            }}
                            selected={selectedLayer.text_align === `end`}
                        >
                            <LogoImg name={`align_right`} width={2} />
                        </TextDecorationButton>
                    </Frame>
                    <Input
                        placeholder={`Text`}
                        value={selectedLayer.text}
                        onChange={(e) => {
                            onParamChange(`text`, e.target.value);
                        }}
                        extra={`width: 240px;`}
                    />
                </Frame>
            ) : null}
            {selectedLayer !== undefined &&
            selectedLayer.type !== `image` &&
            (selectedLayer.type !== `text` || (selectedLayer.type === `text` && color_picker !== undefined)) ? (
                <Frame extra={`align-items: flex-start; margin-bottom: 1vw;`}>
                    <ParameterName>{selectedLayer.type !== `text` ? `Color` : `Text ${color_picker}`}</ParameterName>
                    <ChromePicker
                        disableAlpha={false}
                        width={`100%`}
                        color={selectedLayer[color_picker || `color`]}
                        onChange={(e) => {
                            onParamChange(color_picker || 'color', `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`);
                        }}
                    />
                </Frame>
            ) : null}
            {/* {
                selectedLayer !== undefined && selectedLayer.type === `wave` ? <Frame>
                    <Frame extra={`flex-direction: row; width: 100%; justify-content: space-between;`}>
                        <Text bold >Pro settings</Text>
                        <Switch value={pro_wave} onChange={(e) => { set_pro_wave(e) }} />
                    </Frame>
                </Frame> : null
            } */}
            {selectedLayer !== undefined && pro_wave ? (
                <Frame>
                    {pro_settings
                        .filter((i) => i.layers.indexOf(selectedLayer.type) > -1)
                        .filter((i) =>
                            typeof i.visible === `string` || [`image`, `text`].indexOf(selectedLayer.type) > -1
                                ? true
                                : (selectedLayer.availableSettings || []).indexOf(i.name) > -1
                        )
                        .map((item, index) => {
                            let visible = typeof item.visible === `string` ? selectedLayer[item.visible] : item.visible;
                            let value = selectedLayer !== undefined ? selectedLayer[item.name] || 0 : 0;
                            let title = item.name
                                .split(`_`)
                                .join(` `)
                                .split(``)
                                .map((a, b) => (b === 0 ? a.toUpperCase() : a))
                                .join(``);

                            switch (item.type) {
                                case `switch`:
                                    return (
                                        <Frame
                                            key={index}
                                            extra={`max-height: ${
                                                visible ? 11 : 0.00001
                                            }vw; opacity: ${+visible}; overflow: hidden; flex-direction: row; width: 100%; justify-content: space-between;`}
                                        >
                                            <Text>{title || `Some title`}</Text>
                                            <Switch
                                                value={+value === 1}
                                                onChange={(e) => {
                                                    onParamChange(item.name, e);
                                                }}
                                            />
                                        </Frame>
                                    );
                                case 'range':
                                    return (
                                        <Frame
                                            key={index}
                                            extra={`max-height: ${
                                                visible ? 11 : 0.00001
                                            }vw; opacity: ${+visible}; overflow: hidden; width: 100%; align-items: flex-start;`}
                                        >
                                            <Text>{title || `Some title`}</Text>
                                            <Frame extra={`flex-direction: row; width: 100%; justify-content: space-between;`}>
                                                <Range
                                                    value={value}
                                                    onChange={(e) => {
                                                        onParamChange(item.name, e);
                                                    }}
                                                />
                                                <Text extra={`width: 4vw;`}>{value}</Text>
                                            </Frame>
                                        </Frame>
                                    );
                                default:
                                    return null;
                            }
                        })}
                </Frame>
            ) : null}
        </>
    );
};

export default ParametersAndLayers;

const Title = styled(Frame)`
    width: 270px;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;
    color: white;
    font-weight: bold;

    &:before {
        font-size: 24px;
        font-weight: bold;
        content: '${(props) => props.content}';
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url('${imageDoubleBackArrow}') center no-repeat;
        cursor: pointer;
    }
`;

const LogoImg = styled(Frame)`
    width: 20px;
    height: 20px;
    background: url('${(props) => require(`../../../assets/images/${props.name}_w.svg`)}') no-repeat center center;
`;

const TextDecorationButton = styled(Frame).attrs(() => ({ pointer: true }))`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin: 0 6px 10px 0;
    background: rgba(255, 255, 255, ${(props) => (props.selected ? 0.2 : 0.05)});
`;

const ColorBox = styled(TextDecorationButton)`
    background: ${(props) => props.color || props.theme.background.overlay};
`;

const ParameterName = styled(Text)`
    margin: 0.5vw;
    font-weight: bold;
`;
