import interpolator from 'natural-spline-interpolator';

let spectrogramCtx = undefined;

let default_height = 1;

export let setSpectrogramCtx = (new_spectrogram) => {
    spectrogramCtx = new_spectrogram;
};

let greyscale = (array = [0, 0, 0]) => {
    let weights = [0.3, 0.59, 0.11];
    return array.map((a, b) => a * weights[b]).reduce((a, b) => a + b) / 255;
};

let getFreqs = (t_p = 0, n = 30) => {
    try {
        let [w, h] = [spectrogramCtx.canvas.width, spectrogramCtx.canvas.height / 2];
        return new Array(n)
            .fill(0)
            .map((a, b) =>
                greyscale(Array.from(spectrogramCtx.getImageData(Math.round(w * t_p), Math.round(h * (1 - b / n)), 1, 1).data).slice(0, 3))
            );
    } catch (error) {
        return new Array(n).fill(0).map((i, b) => Math.max(0, Math.sin((b / n) * 80 - 20)));
    }
};

let downsample_array = (points, max = 100) => {
    if (points.length <= max) {
        return points;
    }
    let arr = [];
    let n = points.length;

    let step = (1.0 * n) / max;
    for (let i = 0; i < max; i++) {
        let a = Math.ceil(step * i);
        let b = Math.floor(step * (i + 1));
        b = Math.min(b, n - 1);
        let sum = 0;
        let kk = 0;
        for (let j = a; j <= b; j++) {
            sum += +points[j];
            kk++;
        }
        let avr = (1.0 * sum) / kk;
        arr.push(avr);
    }
    return arr;
};

let fit_array = (array, number) => {
    if (array.length > number) {
        array = downsample_array(array, number);
    }

    if (array.length < number) {
        array = [0, ...array, 0];
        let spline = interpolator(array.map((a, b) => [b, a]));
        let grid = new Array(number).fill(0).map((a, b) => Math.max(0, spline((b / number) * array.length)));
        array = grid;
    }

    return array;
};

let defaultDotsNumber = 200;

let polar_to_decartes = (phi, rho, x_0 = 0, y_0 = 0) => ({
    x: x_0 + Math.cos((phi * Math.PI) / 180) * rho,
    y: y_0 + Math.sin((phi * Math.PI) / 180) * rho,
});

let array_processing = (array, props) => {
    let n = Math.round((props.dots_number || 1) * defaultDotsNumber);

    // if (props.EQ) {
    //     array = array.filter((a, b) => array.length * props.HPF < b && b < array.length * props.LPF)
    // }

    if (props.reversed) {
        array = array.reverse();
    }

    if (props.limiter) {
        array = array.map((i) => (i * (i - props.min_amplitude)) / (1 - props.min_amplitude));
    }

    if (props.smooth) {
        array = fit_array(array, n);
    }

    if (props.mirror) {
        array = [...array, ...array.reverse()];
        array = downsample_array(array, Math.round(array.length / 2));
    }

    return array;
};

let convertHex = (hex, opacity) => {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
};

let shake_effect = `if (shake) {
        //if (layer['shake_x-axis']) {
            ll += Math.sin(t * shake_vel * 100) * shake_amp * ampRatio * 10;
            cx = ll + lw / 2;
        //}
        //if (layer['shake_y-axis']) {
            lt += Math.cos(t * shake_vel * 100) * shake_amp * ampRatio * 10;
            cy = lt + lh / 2;
        //}
    }`;

let scale_on_amp_effect = `if (scale_on_amp) {
        let max_amp = lw * 0.25
        ll = ll - max_amp * ampRatio * scale_on_amp_ratio / 2;
        lw = lw + max_amp * ampRatio * scale_on_amp_ratio;
        lt = lt - max_amp * ampRatio * scale_on_amp_ratio / 2;
        lh = lh + max_amp * ampRatio * scale_on_amp_ratio;
    }`;

let shadow_effect = `if (shadow) { ctx.shadowColor = color; ctx.shadowBlur = 15; }`;

export let draw = (ctx, ampRatio = 0, t = 0, options, resDrop = 1, audioFrequency = undefined) => {
    options.wavePoints = options.wavePoints || new Array(20).fill(0);
    let [w, h] = [ctx.canvas.width, ctx.canvas.height];
    let time_p = t / (options.wavePoints.length / 30.0);

    options.layers
        .filter((i) => i.type === `background`)
        .forEach((background) => {
            ctx.fillStyle = background.color;
            ctx.fillRect(0, 0, w, h);
        });

    options.layers.forEach((layer) => {
        let [ll, lt, lw, lh] = [w * layer.left, h * layer.top, w * layer.width_p, h * layer.height_p];
        // let [ll, lt, lw, lh] = [0, 0, w, h]
        let [cx, cy] = [ll + lw / 2, lt + lh / 2];

        let {
            shake_vel = 0,
            shake_amp = 0,
            shake = false,
            shadow,
            color = `#2DC76D`,
            delay = 0,
            blur = false,
            blur_radius = 0,
            blur_radius_ratio = 0,
            limiter = false,
            min_amplitude = 0,
            side = 0,
            scale_on_amp = false,
            scale_on_amp_ratio = 0,
            fade = false,
            fade_amount = 0,
            fade_ratio = 0,
            polar_coordinates = false,
        } = layer;

        eval(shadow_effect);
        eval(shake_effect);
        eval(scale_on_amp_effect);

        if (layer.type === `wave`) {
            try {
                eval(layer.template);
            } catch (error) {}
        }
        if (layer.type === `progress`) {
            try {
                eval(layer.template);
            } catch (error) {}
        }
        if (layer.type === `text`) {
            function chunkSubstr(str, size) {
                const numChunks = Math.ceil(str.length / size);
                const chunks = new Array(numChunks);
                for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
                    chunks[i] = str.substr(o, size) + '-';
                }
                chunks[chunks.length - 1] = chunks[chunks.length - 1]?.replace('-', '');
                return chunks;
            }

            function wrapText(context, text, marginLeft, marginTop, maxWidth, lineHeight) {
                let line = '';
                let count = 1;
                let words = text
                    .split(' ')
                    .map((word, index) => {
                        if (context.measureText(line + word + ' ').width > maxWidth) {
                            return chunkSubstr(word, Math.ceil(word.length / Math.ceil(context.measureText(line + word + ' ').width / maxWidth)));
                        } else {
                            return word;
                        }
                    })
                    .flat();
                for (let n = 0; n < words.length; n++) {
                    let testLine = line + words[n] + ' ';
                    let testWidth = context.measureText(testLine).width;
                    if (testWidth > maxWidth) {
                        context.fillText(line, marginLeft, marginTop);
                        line = words[n] + ' ';
                        marginTop += lineHeight;
                        count = count + 1;
                    } else {
                        line = testLine;
                    }
                }
                layer.newHeight = (count * lineHeight) / h;
                context.fillText(line, marginLeft, marginTop);
            }

            ctx.fillStyle = layer.background || `transparent`;
            ctx.fillRect(ll, lt, lw, lh);
            ctx.font = `${layer.font_style || `normal`} ${layer.font_weight || `normal`} ${lh}px ${layer.font_family || `Montserrat`}`;
            ctx.fillStyle = layer.color || `white`;
            ctx.textAlign = layer.text_align || `center`;
            ctx.textBaseline = `middle`;
            eval(shake_effect);

            wrapText(
                ctx,
                layer.text || ``,
                ll +
                    lw / 2 +
                    (lw / 2) *
                        {
                            center: 0,
                            end: 1,
                            start: -1,
                        }[layer.text_align || `center`],
                lt + lh / 2,
                lw,
                lh
            );
            // ctx.fillText(layer.text || ``,
            //     ll + lw / 2 + (lw / 2 * {
            //     center: 0,
            //     end: 1,
            //     start: -1
            // }[layer.text_align || `center`]), lt + lh / 2);
        }
        if (layer.type === `image`) {
            let img = new Image();
            // img.addEventListener('load', function () {
            //     ctx.drawImage(img, ll, lt, lw, lh)
            // })
            img.src = layer.url;

            if (layer.blur === true) {
                ctx.filter = `blur(${10 * (+blur_radius + (1 - +blur_radius) * +blur_radius_ratio * +ampRatio)}px)`;
            }

            ctx.drawImage(img, ll, lt, lw, lh);

            if (fade === true) {
                // ctx.filter = `brightness(${100 - 100 * (+fade_amount + (1 - +fade_amount) * +fade_ratio * +ampRatio)}%)`
                ctx.fillStyle = `rgba(0, 0, 0, ${1 - +fade_amount + +fade_amount * +ampRatio * +fade_ratio})`;
                ctx.fillRect(ll, lt, lw, lh);
            }
        }

        ctx.filter = 'blur(0px)';
        ctx.shadowColor = `transparent`;
        ctx.shadowBlur = 0;
    });

    return ctx;
};
