import React from 'react';
import styled from 'styled-components';
import imageLandingTabImage0 from '../../../assets/images/landing-tab-image-0.png';

import { Frame } from '../../UIKit/styled-templates';

const Fourth = () => {
    return (
        <Content>
            <Wrapper>
                <Frame>
                    <Frame>
                        <Frame>Share your audio</Frame>
                        <Frame>on</Frame>
                        <Frame
                            extra={`background: -webkit-linear-gradient(#FF0155, #FF0155); -webkit-background-clip: text; -webkit-text-fill-color: transparent;`}
                        >
                            YouTube
                        </Frame>
                    </Frame>
                    <Frame>Do not be limited by podcast platforms. Post your audio to any social network that supports video content</Frame>
                </Frame>
            </Wrapper>
            <Wrapper reverse>
                <Frame>
                    <Frame>
                        <Frame>Get audience from</Frame>
                        <Frame
                            extra={`background: -webkit-linear-gradient(#F1D025, #FF3D45); -webkit-background-clip: text; -webkit-text-fill-color: transparent;`}
                        >
                            Instagram
                        </Frame>
                    </Frame>
                    <Frame>
                        Prepare effective sharebale video from your audio that you can publish on Instagram. Do not loose your audience and get more
                        subscribers
                    </Frame>
                </Frame>
            </Wrapper>
            <Wrapper>
                <Frame>
                    <Frame>
                        <Frame>Prepare for any</Frame>
                        <Frame
                            extra={`background: -webkit-linear-gradient(#82BCFF, #0175FF); -webkit-background-clip: text; -webkit-text-fill-color: transparent;`}
                        >
                            social
                        </Frame>
                        <Frame>network</Frame>
                    </Frame>
                    <Frame>Do not be limited by podcast platforms. Post your audio to any social network that supports video content</Frame>
                </Frame>
            </Wrapper>
        </Content>
    );
};

const Wrapper = styled(Frame)`
    width: 1400px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    > * {
        width: calc(100% / 2 - 50px);
        align-items: flex-start;
        > * {
            &:nth-child(1) {
                margin-bottom: 50px;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 480px;
                > * {
                    font-size: 48px;
                    font-weight: bold;
                    margin-right: 10px;
                }
            }
            &:nth-child(2) {
                font-size: 20px;
                color: rgb(255, 255, 255, 0.75);
            }
        }
    }
    &: ${({ reverse }) => (reverse ? 'after' : 'before')} {
        content: '';
        width: calc(1400px / 2);
        height: calc(1400px / 2 * 5 / 7);
        background: url('${imageLandingTabImage0}') no-repeat center center / contain;
    }
`;

const Content = styled(Frame)`
    width: 1400px;
    margin-bottom: 100px;
    min-height: calc(100vh - 80px - 50px);
    justify-content: flex-start;
`;

export default Fourth;
