import UsersAPI from '../../api/UsersAPI';
import * as types from '../ActionTypes';

//LOGIN
const startLoggingIn = () => ({ type: types.LOGIN });
const onLoggedIn = (user) => ({ type: types.LOGIN_SUCCESS, user });
const onLoginFailed = (error) => ({ type: types.LOGIN_FAIL, error });
//thunk
export const logIn = (data) => {
    return (dispatch, getState) => {
        dispatch(startLoggingIn());
        return UsersAPI.parselessLogin(data.email, data.password).then(
            (user) => dispatch(onLoggedIn(user)),
            (error) => dispatch(onLoginFailed(error))
        );
    };
};

//SIGNUP
const startSigningUp = () => ({ type: types.SIGNUP });
const onSignedUp = (user) => ({ type: types.SIGNUP_SUCCESS, user });
const onSignUpFail = (error) => ({ type: types.SIGNUP_FAIL, error });
//thunk
export const signUp = (data) => {
    return (dispatch, getState) => {
        dispatch(startSigningUp());
        return UsersAPI.parselessSignup(data).then(
            (user) => dispatch(onSignedUp(user)),
            (error) => dispatch(onSignUpFail(error))
        );
    };
};

//LOGOUT
const startLoggingOut = () => ({ type: types.LOGOUT });
const onLogoutFail = () => ({ type: types.LOGOUT_FAIL });
const onLoggedOut = () => ({ type: types.LOGOUT_SUCCESS });
//thunk
export const logOut = () => {
    return (dispatch, getState) => {
        var usersState = getState().users;
        if (usersState.currentUserId === undefined) {
            return Promise.resolve();
        }
        dispatch(startLoggingOut());
        return UsersAPI.parselessLogout().then(
            () => dispatch(onLoggedOut()),
            () => dispatch(onLogoutFail())
        );
    };
};

//AUTH_INIT
const startAuthInit = () => ({ type: types.INITIALIZE_AUTH });
const authInitFailed = (error) => ({ type: types.INITIALIZE_AUTH_FAIL, error });
const authInitSuccess = (d) => ({ type: types.INITIALIZE_AUTH_SUCCESS, ...d });
//thunk
export const initializeAuthorization = () => {
    return (dispatch, getState) => {
        dispatch(startAuthInit());
        return UsersAPI.parselesslyGetCurrentUser().then(
            (d) => dispatch(authInitSuccess(d)),
            (err) => dispatch(authInitFailed())
        );
    };
};

//USERS
const loadUsers_ = () => ({ type: types.LOAD_USERS });
const loadUsersFail = (error) => ({ type: types.LOAD_USERS_FAIL, error });
const loadUsersSuccess = (users, links) => ({ type: types.LOAD_USERS_SUCCESS, users });
//thunk
export const loadUsersByIds = (ids) => {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return UsersAPI.getUsersByIds(ids).then(
            (users) => dispatch(loadUsersSuccess(users)),
            (error) => dispatch(loadUsersFail(error))
        );
    };
};

// update user
const updateUser_ = () => ({ type: types.UPDATE_USER });
const updateUserFail = (error) => ({ type: types.UPDATE_USER_FAIL, error });
export const updateUserSuccess = (user) => ({ type: types.UPDATE_USER_SUCCESS, user });
//thunk
export const updateUser = (data) => {
    return (dispatch, getState) => {
        dispatch(updateUser_());
        return UsersAPI.updateUser(data).then(
            (user) => dispatch(updateUserSuccess(user)),
            (err) => dispatch(updateUserFail(err))
        );
    };
};

//load all users
export const loadAllUsers = () => {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return UsersAPI.getAllUsers().then(
            (users) => dispatch(loadUsersSuccess(users)),
            (err) => dispatch(loadUsersFail(err))
        );
    };
};

export const loadAllFranchisees = () => {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return UsersAPI.getAllFranchisees().then(
            (users) => dispatch(loadUsersSuccess(users)),
            (err) => dispatch(loadUsersFail(err))
        );
    };
};

export const loadAllTrainers = () => {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return UsersAPI.getAllTrainers().then(
            (users) => dispatch(loadUsersSuccess(users)),
            (err) => dispatch(loadUsersFail(err))
        );
    };
};

export const loadAllTrainersAndFranchisees = () => {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return UsersAPI.getAllFranchiseesAndTrainers().then(
            (users) => dispatch(loadUsersSuccess(users)),
            (err) => dispatch(loadUsersFail(err))
        );
    };
};

export const loadUserById = (userId) => {
    return (dispatch, getState) => {
        dispatch(loadUsers_());
        return UsersAPI.getUserById(userId).then(
            (u) => dispatch(loadUsersSuccess([u])),
            (err) => dispatch(loadUsersFail(err))
        );
    };
};

// CREATE USER
const createUser_ = () => ({ type: types.CREATE_USER });
const createUserSuccess = (user) => ({ type: types.CREATE_USER_SUCCESS, user });
const createUserFailed = (error) => ({ type: types.CREATE_USER_FAIL, error });
//thunk
export const createUser = (data) => {
    return (dispatch, getState) => {
        dispatch(createUser_());
        return UsersAPI.createUser(data).then(
            (user) => dispatch(createUserSuccess(user)),
            (error) => dispatch(createUserFailed(error))
        );
    };
};

// DELETE USER
const deleteUser_ = () => ({ type: types.DELETE_USER });
const deleteUserSuccess = (id) => ({ type: types.DELETE_USER_SUCCESS, id });
const deleteUserFailed = (error) => ({ type: types.DELETE_USER_FAIL, error });
//thunk
export const deleteUser = (id) => {
    return (dispatch, getState) => {
        dispatch(deleteUser_());
        return UsersAPI.deleteUser(id).then(
            (data) => dispatch(deleteUserSuccess(id)),
            (error) => dispatch(deleteUserFailed(error))
        );
    };
};
