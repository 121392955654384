import React from 'react';
import styled from 'styled-components';

import { Frame } from '../UIKit/styled-templates';
import First from './landing/first';
import Fourth from './landing/fourth';
import Second from './landing/second';
import Third from './landing/third';
import Pricing from './pricing-page';

const LandingPage = () => {
    return (
        <Wrapper>
            <First />
            <Second />
            <Third />
            <Fourth />
            <Pricing />
        </Wrapper>
    );
};

const Wrapper = styled(Frame)`
    > * {
        z-index: 1;
    }

    color: white;
`;

export default LandingPage;
