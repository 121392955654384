/*eslint-disable*/
import Sidebar from 'arui-feather/sidebar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import * as actions from '../../../redux/actions/ServersActions';
import { Button } from '../../ui/Button';
import UpdateServerForm from '../forms/UpdateServerForm';

export default function AdminServersPanel(props) {
    const { loading, servers, isAdmin, currentUserId } = useMappedState(
        useCallback((state) => {
            return {
                loading: state.servers.loading,
                servers: state.servers.serversMap.toArray().sort((a, b) => +b.timestamp - +a.timestamp),
                currentUser: state.users.usersMap.get(state.users.currentUserId),
                currentUserId: state.users.currentUserId,
            };
        }, [])
    );
    const [selectedId, setSelectedId] = useState();
    const [addModalVisible, setAddModalVisible] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllServers());
    }, []);

    let selectedServer = selectedId == undefined ? undefined : servers.filter((a) => a.id == selectedId)[0];

    return (
        <Wrapper>
            <TopPlaceholder>
                <TopName>Servers</TopName>
                <TopRight>
                    <Button
                        onClick={() => {
                            setAddModalVisible(true);
                        }}
                    >
                        Add server
                    </Button>
                </TopRight>
            </TopPlaceholder>

            <Content>
                {servers.map((srv, j) => {
                    return (
                        <InlineBox
                            key={`${srv.id}_${j}`}
                            onClick={() => {
                                setSelectedId(srv.id);
                            }}
                        >
                            <ServerName>{srv.name}</ServerName>
                            <div>{srv.url}</div>
                        </InlineBox>
                    );
                })}
            </Content>

            <Sidebar
                visible={selectedServer != undefined}
                onCloserClick={() => {
                    setSelectedId(undefined);
                }}
            >
                {selectedServer == undefined ? null : (
                    <div>
                        <UpdateServerForm
                            loading={loading}
                            {...selectedServer}
                            onSave={async (d) => {
                                await dispatch(actions.updateServer({ ...d, id: selectedServer.id }));
                                setSelectedId(undefined);
                            }}
                        />
                    </div>
                )}
            </Sidebar>

            <Sidebar
                visible={addModalVisible}
                onCloserClick={() => {
                    setAddModalVisible(false);
                }}
            >
                {addModalVisible == false ? null : (
                    <div>
                        <UpdateServerForm
                            loading={loading}
                            onSave={async (d) => {
                                await dispatch(actions.createServer(d));
                                setAddModalVisible(false);
                            }}
                        />
                    </div>
                )}
            </Sidebar>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
`;

const Content = styled.div`
    margin-top: 40px;
    margin-left: -10px;
`;

const InlineBox = styled.div`
    width: calc(33% - 20px);
    box-sizing: border-box;
    margin: 10px;
    padding: 20px;
    background: red;
    border-radius: 12px;
    display: inline-block;
    color: white;
    vertical-align: top;
`;

const ServerName = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopName = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 46px;
    color: red;
`;

const TopRight = styled.div``;

/*eslint-enable*/
