/*eslint-disable*/
import * as types from '../ActionTypes'
export default {
    setMainScreen: (data) => {
        return {
            type: types.SET_MAIN_SCREEN,
            data: data
        }
    },
    setTheme: (data) => {
        localStorage.setItem(`theme`, data)
        return {
            type: types.SET_THEME,
            data: data
        }
    },
    setThemeShift: (data) => {
        localStorage.setItem(`theme_shift`, data)
        return {
            type: types.SET_THEME_SHIFT,
            data: data
        }
    },
    setLang: (lang = `ENG`) => {
        localStorage.setItem(`lang`, lang)
        return ({ type: types.SET_LANG, lang: lang })
    }
}
/*eslint-enable*/