/*eslint-disable*/

let gradients = [
    `#F1D025, #FF3D45`,
    `90deg, #C182FF, #9001FF`,
    `90deg, #82BCFF, #0175FF`,
]

let basePalette = {
    yellow: `#FEE610`,
    green: `#2DC76D`,
    purple: `#7540EE`,
    lightblue: `#2F61D5`,
    vk: `#3A60A1`,
    red: `#FF7052`,
    pink: `#E43D99`,
    blue: `#0075FF`,
}

export let theme = {
    ...basePalette,
    background: {
        primary: `#00042B`,
        // secondary: `#050D44`,
        secondary: `#00063A`,
        support: `#11194D`,
        overlay: `#363D69`,
    },
    text: {
        primary: `#ffffff`,
        secondary: `#7B7F9E`,
        support: `#939393`,
    }
}

export let FontList = [
    'Arial',
    'Roboto',
    'Open Sans',
    'Trade Winds',
    'Montserrat',
    'Roboto Condensed',
    'Raleway',
    'Lato',
    'Verdana',
    'Sans-serif',
    'Ubuntu',
    'PT Sans',
    'Nunito'
];

export default {
    api: `https://dcam.pro/api`,
    mobile_media_query: (d) => `@media (min-width: 320px) and (max-width: 480px) { ${d}; transition: 0; }`,
    weekDays: {
        short: [`ПН`, `ВТ`, `СР`, `ЧТ`, `ПТ`, `СБ`, `ВС`],
        full: [`воскресенье`, `понедельник`, `вторник`, `среда`, `четверг`, `пятница`, `суббота`]
    },
    theme: theme,
    month: [`Январь`, `Февраль`, `Март`, `Апрель`, `Май`, `Июнь`, `Июль`, `Август`, `Сентябрь`, `Октябрь`, `Ноябрь`, `Декабрь`,],
    screens: {
        laundry: `LAUNDRY`,
        admin: `TOOLS`,
        event_spaces: `SPACES`,
        dev: `DEV`,
    },
    theme_shift: {
        system: `Системная`,
        disabled: `Не использовать`,
        // scheduled: `По расписанию`,
        // automatic: `Автоматически`,
    }
}
/*eslint-enable*/