/*eslint-disable*/
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import axios from 'axios';
import App from './App';
import { API_ENDPOINT } from './constants/config';

const getToken = () => {
    let tk = window.localStorage.getItem('auth_token');
    if (tk === undefined || tk === '') {
        return;
    }
    return tk;
};

axios.interceptors.request.use(
    (config) => {
        let token = getToken();
        if (token !== undefined) {
            config.headers['x-token'] = token;
            config.baseURL = API_ENDPOINT;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

window.createId = () =>
    Math.random()
        .toString(36)
        .substring(3)
        .split(``)
        .map((i) => i[Math.random() > 0.5 ? `toLowerCase` : `toUpperCase`]())
        .join(``);

var swapArrayElements = function (arr, indexA, indexB) {
    var temp = arr[indexA];
    if (indexA > -1 && indexB > -1 && indexB < arr.length) {
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
    }
    return temp;
};

Array.prototype.swap = function (indexA, indexB) {
    swapArrayElements(this, indexA, indexB);
    return this;
};

Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
    return this;
};

Array.prototype.remove = function (index) {
    if (index > -1) {
        this.splice(index, 1);
    }
    return this;
};

Array.prototype.get = function (key) {
    return this.filter((i) => i.id === key)[0];
};

Array.prototype.crossing = function (anotherArray) {
    return [...this, ...anotherArray].some((i) => this.indexOf(i) > -1 && anotherArray.indexOf(i) > -1);
};

Array.prototype.unique = function () {
    return this.filter((v, i, a) => a.indexOf(v) === i);
};

Array.prototype.allIndexesOf = function (value) {
    let array = [];
    this.forEach((a, b) => {
        if (a === value) {
            array.push(b);
        }
    });
    return array;
};

String.prototype.allIndexesOf = function (char) {
    let array = [];
    this.split(``).forEach((a, b) => {
        if (a === char) {
            array.push(b);
        }
    });
    return array;
};

String.prototype.uppercaseFirstLetter = function (char) {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

ReactDOM.render(<App />, document.getElementById('root'));

/*eslint-enable*/
