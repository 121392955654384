/*eslint-disable*/
import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {API_ENDPOINT} from "../../constants/config";

import UpdateConfigInputTool from "../configs/tools/UpdateConfigInputTool";

const paid_max_video_quality = 'paid_max_video_quality';
const paid_upload_file_size_max = 'paid_upload_file_size_max';
const paid_upload_video_duration_max = 'paid_upload_video_duration_max';

const free_max_video_quality = 'free_max_video_quality';
const free_upload_file_size_max = 'free_upload_file_size_max';
const free_upload_video_duration_max = 'free_upload_video_duration_max';

const watermark_image_url = 'watermark_image_url';

const render_frame_code = 'render_frame_code';

export default function AdminConfigsPage() {

    return (
            <Wrapper>

                <Section>

                    <SectionHeading>
                        Render frame settings
                    </SectionHeading>

                    <Field>
                        <Label>
                            {`Code. Parameter "${render_frame_code}"`}
                        </Label>
                        <UpdateConfigInputTool
                            mode={'textarea'}
                            type={'string'} paramName={render_frame_code}/>
                    </Field>

                </Section>

                <Section>

                    <SectionHeading>
                        Free Users
                    </SectionHeading>

                    <Field>
                        <Label>
                            {`Free Users - file size limits (in megabytes). Parameter "${free_upload_file_size_max}"`}
                        </Label>
                        <UpdateConfigInputTool type={'number'} paramName={free_upload_file_size_max}/>
                    </Field>

                    <Field>
                        <Label>
                            {`Free Users - max video duration (in minutes). Parameter "${free_upload_video_duration_max}"`}
                        </Label>
                        <UpdateConfigInputTool type={'number'} paramName={free_upload_video_duration_max}/>
                    </Field>

                    <Field>
                        <Label>
                            {`Free Users - max rendered video quality (vertical_pixels). Parameter "${free_max_video_quality}"`}
                        </Label>
                        <UpdateConfigInputTool type={'number'} paramName={free_max_video_quality}/>
                    </Field>

                </Section>

                <Section>

                    <SectionHeading>
                        Paid Users
                    </SectionHeading>

                    <Field>
                        <Label>
                            {`Paid Users - file size limits (in megabytes). Parameter "${paid_upload_file_size_max}"`}
                        </Label>
                        <UpdateConfigInputTool type={'number'} paramName={paid_upload_file_size_max}/>
                    </Field>

                    <Field>
                        <Label>
                            {`Paid Users - max video duration (in minutes). Parameter "${paid_upload_video_duration_max}"`}
                        </Label>
                        <UpdateConfigInputTool type={'number'} paramName={paid_upload_video_duration_max}/>
                    </Field>

                    <Field>
                        <Label>
                            {`Paid Users - max rendered video quality (vertical_pixels). Parameter "${paid_max_video_quality}"`}
                        </Label>
                        <UpdateConfigInputTool type={'number'} paramName={paid_max_video_quality}/>
                    </Field>

                </Section>

                <Section>
                    <SectionHeading>
                        Workers
                    </SectionHeading>

                    <Field>
                        <Label>
                            {`Watermark (.PNG image URL)`}
                        </Label>
                        <UpdateConfigInputTool type={'string'} paramName={watermark_image_url}/>
                    </Field>

                </Section>


            </Wrapper>
    );
}


const SectionHeading = styled.div`
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: bold;
`;

const Section = styled.div`
    padding: 20px;
    border: 1px solid white;
    border-radius: 4px;
    background: white;
    width: 100%;
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const Field = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.div`
    
`;

/*eslint-enable*/