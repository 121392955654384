import React from 'react';
import styled, { css } from 'styled-components';

import { Frame, Link } from '../../UIKit/styled-templates';

import imgMakeWaveLogo from '../../../assets/images/makewave-online-logo.svg';

const First = () => {
    return (
        <Content>
            <img alt="MakeWave logo" src={imgMakeWaveLogo} style={{ width: '5rem' }} />
            <TopLine>
                <Frame>For Musicians</Frame>
                <Frame>|</Frame>
                <Frame>For Podcasters</Frame>
                <Frame>|</Frame>
                <Frame>For Radio</Frame>
            </TopLine>
            <Title>
                <span>Share audio on</span>
                <span>Social</span>
                <span>platforms</span>
            </Title>
            {/* <Frame row>
                <SocialMedia name="twitter" />
                <SocialMedia name="linkedin" />
                <SocialMedia name="facebook" />
                <SocialMedia name="instagram" />
            </Frame> */}
            <Frame extra="flex-direction: row; gap: 0.5rem;">
                <Link to="/login">
                    <SignUpButton outlined={true}>Login</SignUpButton>
                </Link>
                <Link to="/signup">
                    <SignUpButton>Sign up for FREE</SignUpButton>
                </Link>
            </Frame>
        </Content>
    );
};

const SignUpButton = styled(Frame)`
    width: 220px;
    height: 55px;
    border-radius: 27.5px;
    background: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.background.secondary};
    margin-top: 50px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    font-size: 14px;
    font-family: Bold;

    ${({ outlined }) =>
        outlined &&
        css`
            background: transparent;
            border: 2px solid ${({ theme }) => theme.yellow};
            color: ${({ theme }) => theme.yellow};
        `}
`;

const SocialMedia = styled(Frame)`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: ${(props) => props.theme.background.secondary};
    margin-left: 30px;
    cursor: pointer;
    &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: url('${(props) => (props.name ? require(`../../../assets/images/social-media-${props.name}.svg`) : null)}') no-repeat center
            center / contain;
    }
`;

const Title = styled(Frame)`
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;
    flex-direction: row;

    > * {
        font-family: Bold;
        font-size: 90px;
        &:nth-child(2) {
            margin-left: 20px;
            color: ${(props) => props.theme.pink};
        }
    }
`;

const TopLine = styled(Frame)`
    width: 600px;
    margin-top: 75px;
    flex-direction: row;
    justify-content: space-around;
    > * {
        font-size: 16px;
        font-family: Bold;
        color: ${(props) => props.theme.yellow};
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    min-height: calc(100vh);
    justify-content: center;
`;

export default First;
