/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import styled, { keyframes } from 'styled-components';

import { Frame } from '../UIKit/styled-templates'

let n = 15
let r = 5

export default function FullPagePreloader(props) {
    let visible = (props.visible === undefined) ? false : props.visible;
    if (visible === false) {
        return null;
    }

    return (
        <Frame>
            {
                new Array(n).fill(0).map((item, index) => <Dot key={index} index={index} />)
            }
        </Frame>
    );
}

const move = (index) => keyframes`
    0% {
        transform:
            translateX(0)
            translateY(0);
    }
    50% {
        transform:
            translateX(${Math.sin(index / n * Math.PI * 2) * r * 2}vw)
            translateY(${Math.cos(index / n * Math.PI * 2) * -r * 2}vw);
    }
    100% {
        transform:
            translateX(0)
            translateY(0);
    }
`;

const Dot = styled(Frame)`
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 50%;
    /* background: ${props => props.theme.green}; */
    /* box-shadow: 0 0 2vw ${props => props.theme.green}; */
    background: ${props => props.theme.yellow};
    position: absolute;
    top: calc(50% + ${props => Math.sin(props.index / n * 2 * Math.PI + Math.PI / 2) * r}vw - 0.25vw);
    left: calc(50% + ${props => Math.cos(props.index / n * 2 * Math.PI + Math.PI / 2) * r}vw - 0.25vw);
    animation: ${props => move(props.index)} 1s ease-in-out infinite ${props => props.index / n * 3}s;
`;

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${props => props.theme.background.primary};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
`;

const Inner = styled.div`
    box-sizing: border-box;
    overflow: hidden !important;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImagePlaceholder = styled.div`
    height: calc(100% - 0px);
    text-align: center;
`;

const SubImagePlaceholder = styled.div`
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    color: red;
`;

const Img = styled.img`
    height: 100%;
    display: inline-block;
`;

/*eslint-enable*/