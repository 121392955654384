/*eslint-disable*/
export default [
    // {
    //     name: `smooth`,
    //     type: `switch`,
    //     layers: [`wave`],
    //     visible: true,
    // },
    {
        name: `polar_coordinates`,
        type: `switch`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `mirror`,
        type: `switch`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `reversed`,
        type: `switch`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `side`,
        type: `switch`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `stroke_width`,
        type: `range`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `only_border`,
        type: `switch`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `fill`,
        type: `switch`,
        layers: [`wave`],
        visible: `only_border`,
    },
    {
        name: `shadow`,
        type: `switch`,
        layers: [],
        // layers: [`wave`, `text`, `progress`, `shape`],
        visible: true,
    },
    // {
    //     name: `blur`,
    //     type: `switch`,
    //     layers: [],
    //     visible: true,
    // },
    // {
    //     name: `blur_radius`,
    //     type: `range`,
    //     layers: [`wave`, `image`],
    //     visible: `blur`,
    // },
    // {
    //     name: `blur_radius_ratio`,
    //     type: `range`,
    //     layers: [`wave`, `image`],
    //     visible: `blur`,
    // },
    {
        name: `fade`,
        type: `switch`,
        layers: [`image`],
        visible: true,
    },
    {
        name: `fade_amount`,
        type: `range`,
        layers: [`image`],
        visible: `fade`,
    },
    {
        name: `fade_ratio`,
        type: `range`,
        layers: [`image`],
        visible: `fade`,
    },
    {
        name: `amplitude_multiplier`,
        type: `range`,
        layers: [`wave`],
        visible: true,
    },
    // {
    //     name: `limiter`,
    //     type: `switch`,
    //     layers: [`wave`],
    //     visible: true,
    // },
    // {
    //     name: `min_amplitude`,
    //     type: `range`,
    //     layers: [`wave`],
    //     visible: `limiter`,
    // },
    {
        name: `dots_number`,
        type: `range`,
        layers: [`wave`],
        visible: true,
    },
    {
        name: `sides`,
        type: `switch`,
        layers: [],
        visible: false,
    },
    {
        name: `shake`,
        type: `switch`,
        layers: [`wave`, `progres`, `text`, `image`, `shape`],
        visible: true,
    },
    // {
    //     name: `shake_x-axis`,
    //     type: `switch`,
    //     layers: [`wave`, `progres`, `text`, `image`, `shape`],
    //     visible: `shake`,
    // },
    // {
    //     name: `shake_y-axis`,
    //     type: `switch`,
    //     layers: [`wave`, `progres`, `text`, `image`, `shape`],
    //     visible: `shake`,
    // },
    {
        name: `shake_amp`,
        type: `range`,
        layers: [`wave`, `progres`, `text`, `image`, `shape`],
        visible: `shake`,
    },
    {
        name: `shake_vel`,
        type: `range`,
        layers: [`wave`, `progres`, `text`, `image`, `shape`],
        visible: `shake`,
    },
    {
        name: `scale_on_amp`,
        type: `switch`,
        layers: [`wave`, `progres`, `text`, `image`, `shape`],
        visible: true,
    },
    {
        name: `scale_on_amp_ratio`,
        type: `range`,
        layers: [`wave`, `progres`, `text`, `image`, `shape`],
        visible: `scale_on_amp`,
    },
    // {
    //     name: `delay`,
    //     type: `range`,
    //     layers: [`wave`],
    //     visible: true,
    // },
]
/*eslint-enable*/