/*eslint-disable*/
import { useCallback } from 'react'
import { useDispatch, useMappedState } from 'redux-react-hook'

import uiActions from '../../redux/actions/UiActions'
// import dict from './dict'

let langs = [`ENG`]

let useDictionary = () => {

    const dispatch = useDispatch();
    let { lang, dict } = useMappedState(useCallback(state => {
        return {
            lang: state.ui.lang || `ENG`,
            // dict: Object.assign({}, ...state.dictionary.dictionaryMap.toArray().map(i => i.words))
            dict: {}
        }
    }, []));

    return {
        lang,
        setLang: (lang) => { dispatch(uiActions.setLang(lang)) },
        langs,
        dict,
        getText: (text, key) => {
            let word = (dict[text.toLowerCase().split(` `).join(`_`)] || { ENG: text })[lang]
            switch (key) {
                case `UPPERCASE`: word = word.toUpperCase(); break;
                case `LOWERCASE`: word = word.toLowerCase(); break;
                case `UPPERCASE_FIRST_LETTER`: word = word.charAt(0).toUpperCase() + word.slice(1); break;
            }
            return word
        }
    }
}

export default useDictionary;
/*eslint-enable*/