/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { Link as ImportLink } from 'react-router-dom'
import moment from 'moment'

import mvConsts, { theme } from '../../constants/mvConsts'
import ImportInput from './Input'
import ImportButton from './Button'

let parseLinks = (string) => {
    let splitters = string.allIndexesOf(`|`)
    if (splitters.length > 0) {
        splitters.forEach(i => {
            splitters = string.allIndexesOf(`|`)
            let [a, x, b] = [string.indexOf(`[`), splitters[0], string.indexOf(`]`)]
            if (a > -1, x > a, b > x) {
                let link = string.slice(a + 1, x)
                let text = string.slice(x + 1, b)
                string = string.replace(string.slice(a, b + 1), `<a href="${link.indexOf(`http://`) === -1 && link.indexOf(`https://`) === -1 ? `https://` : ``}${link}" target="_blank" >${text}</a>`)
            }
        })
    }
    if (string.indexOf(`<a href`) > -1) {
        string = `<div style="display: inline-block;" >` + string + `</div>`
    }
    return string
}

export const Input = (props) => <ImportInput {...props} />

export const Button = (props) => <ImportButton {...props} />

export const Textarea = (props) => <ImportInput textarea {...props} />

export const Link = (props) => <ImportLink
    style={{ textDecoration: 'none' }}
    {...props}
    onClick={() => {
        try { document.getElementById(`scrollWrapper`).scrollTo({ top: 0, behavior: "smooth", }); } catch (error) { }
        try { window.scrollTo({ top: 0, behavior: "smooth", }); } catch (error) { }
        if (props.onClick !== undefined) { props.onClick() }
    }}
/>

export const Img = styled.img`
width: ${props => props.width}vw;
height: ${props => props.width}vw;
transition: 0.2s;
border-radius: ${props => props.round && props.width}vw;
visibility: ${props => props.src ? `visible` : `hidden`};
cursor: ${props => props.pointer ? `pointer` : `null`};
${props => props.round ? `object-fit: cover;` : null}
${props => props.extra}
user-select: none;
/* user-drag: none; */
box-sizing: border-box;
@media (min-width: 320px) and (max-width: 480px) {
    width: ${props => props.width * 5}vw;
    height: ${props => props.width * 5}vw;
    border-radius: ${props => props.round && (props.width * 5)}vw;
    transition: 0s;
}`

export const LogoImg = styled(Img).attrs((props) => {
    let dark = props.theme.background.primary === `#ffffff`
    let img
    try { img = require(`../../assets/images/${props.name}_${dark ? `b` : `w`}.svg`) } catch (error) { }
    return ({ width: props.width || 1, src: img, })
})`${props => props.extra}`

export const Frame = styled.div.attrs((props) => ({
    ...props,
    dangerouslySetInnerHTML: typeof (props.children) === `string` ? { __html: parseLinks(props.children) } : undefined,
    children: typeof (props.children) === `string` ? undefined : props.children,
}))`
    display: ${props => props.only_mobile ? `none` : `flex`};
    justify-content: ${props => props.row ? props.start ? `flex-start` : props.end ? `flex-end` : `center` : `center`};
    align-items: ${props => !props.row ? props.start ? `flex-start` : props.end ? `flex-end` : `center` : `center`};
    flex-direction: ${props => props.row ? `row` : `column`};
    transition: 0.2s;
    font-size: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    /* word-break: break-all; */
    ${props => props.pointer ? `cursor: pointer;` : null}
    /* user-select: none; */
    /* cursor: default; */
    @media (min-width: 320px) and (max-width: 480px) {
        display: ${props => props.only_desktop ? `none` : `flex`};
        font-size: 4vw;
        transition: 0s;
    }
    ${props => props.extra}
`

export const Checkbox = (props) => {
    let CheckboxContent = styled.img`width: 12px; height: 12px; opacity: ${props => +props.checked}; transition: 0.2s;`
    let { checked = false, onChange = () => { } } = props
    return (
        <Frame extra={props => `border-radius: 4px; width: 20px; height: 20px; border: 2px solid ${props.theme.green}; cursor: pointer;`} onClick={() => { onChange(!checked) }} >
            <CheckboxContent src={require(`../../assets/images/checkbox_content.svg`)} checked={checked} />
        </Frame>
    )
}

export const PopUp = styled(Frame)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.2s;
    border-radius: 1vw;
    background: ${props => props.theme.background.primary};
    z-index: ${props => props.visible ? 4 : 0};
    position: absolute;
    visibility: ${props => props.visible ? `visible` : `hidden`};
    opacity: ${props => +props.visible};
    padding: 1.8vw;
    box-shadow: 0 0 2vw rgba(0, 0, 0, 0.1);
    // transform: scale(${props => props.visible ? 1 : 1.1});
    @media (min-width: 320px) and (max-width: 480px) {
        position: fixed;
        display: ${props => props.visible ? `flex` : `none`};
        padding-top: 5vw;
        width: 100vw;
        top: 0;
        right: 0;
        display: block;
        height: 100%;
        overflow: scroll;
        transition: 0s;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
`

export const Text = styled(Frame)`
// font-size: ${props => props.size ? props.size : 0.8}vw;
font-size: ${props => props.size ? props.size : 12}px;
color: ${props => props.text_color ? props.text_color : props.theme.text.primary};
font-family: ${props => props.bold ? `Bold` : `Regular`};
white-space: pre-wrap;
${props => props.extra}
@media (min-width: 320px) and (max-width: 480px) {
    font-size: ${props => (props.size ? props.size : 0.8) * 4}vw;
}`

export const Bar = styled(Frame)`
width: 100%;
justify-content: flex-start;
padding: 0.5vw 0 0.5vw 0;
@media (min-width: 320px) and (max-width: 480px) {
    padding: 5vw 0 5vw 0;
    width: 85vw;
}`

export const BarWrapper = styled(Frame)`
> * {
    &:first-child {
        padding-top: 0;
    };
    &:last-child {
        padding-bottom: 0;
    }
}
`

let rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export let ThemeWrapper = (props) => {
    return (
        <ThemeProvider theme={theme} >
            {props.children}
        </ThemeProvider>
    )
}

export let Range = (props) => {
    let { value = 0, onChange = () => { } } = props
    return (
        <Input
            type={`range`}
            value={+value}
            min={0}
            max={1}
            step={0.01}
            extra={`margin: 1vw; width: calc(310px - 20px - 6vw); height: 0.2vw; padding: 0; background: #C4C4C4;`}
            onChange={(e) => { onChange(e.target.value) }}
        />
    )
}

const SwitchWrapper = styled(Frame)`
    position: relative;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background: ${props => props.checked ? `linear-gradient(90deg, #C182FF, #9001FF)` : props.theme.background.secondary};
    border: 1px solid #9001FF;
    transition: 0.2s !important;
    cursor: pointer;
    margin: 0.25vw;
`;

const Knob = styled(Frame)`
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    /* border: 0.1vw solid ${props => props.theme.green}; */
    transform: scale(0.95) translateX(${props => props.checked ? `calc(40px - 20px)` : `0`});
`;

export let Switch = (props) => {
    let { value = false, onChange = () => { } } = props
    return (
        <Frame extra={`padding: 0.1vw; margin: 0.1vw;`}>
            <SwitchWrapper checked={+value === 1} onClick={() => { onChange(!+value) }}>
                <Knob checked={+value === 1} />
            </SwitchWrapper>
        </Frame>
    )
}

export let convertHex = (hex = `#000000`, opacity = 1) => {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

export let rgbToHex = (r = 0, g = 0, b = 0) => {
    if (r > 255 || g > 255 || b > 255) {
        throw `Invalid color component`
    }
    return ((r << 16) | (g << 8) | b).toString(16);
}

export const Book = (props) => {
    const Wrapper = styled(Frame)`
    background: ${props => props.is_weekend ? props.theme.background.primary : props.theme.background.primary};
    cursor: pointer;
    width: 18.5vw;
    border-radius: 1vw;
    margin: 0.25vw;
    padding: 0.75vw;
    flex-direction: row;
    // border: 0.2vw solid ${props => props.is_selected_day ? props.theme.purple : props.is_today ? props.theme.green : props.theme.background.secondary}
    justify-content: space-between;
    // &:hover {
    //     transform: scale(1.05)
    // }
    @media (min-width: 320px) and (max-width: 480px) {
        width: 92vw;
        height: 20vw;
        padding: 2vw;
        margin: 1vw;
        border-radius: 4vw;
        // border: 1vw solid ${props => props.is_selected_day ? props.theme.purple : props.is_today ? props.theme.green : `transparent`}
    }`
    const ImageWrapper = styled(Frame)`
    padding: 0.75vw;
    border-radius: 1vw;
    background: ${props => convertHex(props.theme.green, 0.75)};
    @media (min-width: 320px) and (max-width: 480px) {
        padding: 3vw;
        border-radius: 4vw;
    }`
    return (
        <Wrapper {...props} >
            <Frame row>
                <ImageWrapper>
                    <Img width={1.5} src={props.image} />
                </ImageWrapper>
                <Frame
                    extra={`align-items: flex-start; margin: 0 1vw 0 1vw; @media (min-width: 320px) and (max-width: 480px) { margin: 0 6vw 0 6vw; };`}>
                    <Text size={1} bold>{props.title}</Text>
                    <Text text_color={props => props.theme.text.secondary}>{moment(props.date).format(`DD.MM.YY`)}</Text>
                </Frame>
            </Frame>
            <Frame extra={`align-items: flex-start;`}>
                <Text size={1.2} bold>{moment(props.date).format(`HH:mm`)}</Text>
            </Frame>
            {props.children}
        </Wrapper>
    )
}

export const Rotor = styled(Frame)`animation: ${props => props.rotate === undefined ? rotate : props.rotate ? rotate : null} 2s linear infinite; padding: -2vw;`

export const P = styled(Text)`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    color: ${props => props.theme.text.support};
    ${props => props.extra}

    @media only screen and (max-width: 600px) {
        font-size: 4.5vw;
        line-height: 6.5vw;
        margin-top: 4.5vw;
        margin-bottom: 6.25vw;
    }
`;

export const H1 = styled(Text)`
    font-size: 24px;
    line-height: 46px;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: bold;
    max-width: 925px;

    @media only screen and (max-width: 600px) {
        font-size: 5vw;
        line-height: 8vw;
        margin-bottom: 4.5vw;
        max-width: 90vw;
    }
`;

export const H = styled(Text)`
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 62px;

    @media only screen and (max-width: 600px) {
       font-size: 7.5vw; 
       line-height: 10vw;
    }
`;

export const AddSquare = (props) => {
    return (
        <Frame extra={props => `width: 35px; height: 35px; border-radius: 10px; background: ${convertHex(props.theme.blue, 0.3)};`} >
            <Text bold text_color={props => props.theme.blue} extra={`font-size: 20px;`} >+</Text>
        </Frame>
    )
}
/*eslint-enable*/