import { combineReducers } from 'redux';

import EditorReducer from './EditorReducer.js';
import PlansReducer from './PlansReducer.js';
import ProjectsReducer from './ProjectsReducer.js';
import ServersReducer from './ServersReducer.js';
import TariffsReducer from './TariffsReducer.js';
import TemplatesReducer from './TemplatesReducer.js';
import UIReducer from './UIReducer.js';
import UsersReducer from './UsersReducer.js';

const reducer = combineReducers({
    editor: EditorReducer,
    plans: PlansReducer,
    projects: ProjectsReducer,
    servers: ServersReducer,
    tariffs: TariffsReducer,
    templates: TemplatesReducer,
    ui: UIReducer,
    users: UsersReducer,
});

export default reducer;
