/*eslint-disable*/
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'redux-react-hook';
import styled from 'styled-components';

import Form from '../UIKit/Form';
import { Button, Frame, H } from '../UIKit/styled-templates';
import PopUpWrapper from './PopUpWrapper';

import useCustomDispatch, { customHandler } from '../helpers/useCustomDispatch';
import useDictionary from '../helpers/useDictionary';

let customFields = [
    { name: `Width (px)`, type: `number`, extra: `width: 75px; margin-right: 15px;` },
    { name: `Height (px)`, type: `number`, extra: `width: 75px;` },
];

let gradients = [`#F1D025, #FF3D45`, `90deg, #C182FF, #9001FF`, `90deg, #82BCFF, #0175FF`];

let dimensions = [
    {
        label: `Instagram`,
        dimensions: `1080x1080`,
    },
    {
        label: `Facebook`,
        dimensions: `940x788`,
    },
    {
        label: `Full video`,
        dimensions: `1920x1080`,
    },
    {
        label: `Story`,
        dimensions: `1080x1920`,
    },
    {
        label: `Custom`,
        dimensions: `1000x1000`,
    },
];

let CreateProjectPopUp = () => {
    let path = useLocation().pathname.split(`/`).slice(1);
    let dispatch = useDispatch();
    let { getText } = useDictionary(() => {});

    useCustomDispatch(`OPEN_CREATE_PROJECT_POP_UP`, (e) => {});
    useCustomDispatch(`CLOSE_CREATE_PROJECT_POP_UP`, (e) => {});

    let [selectedDimensions, setSelectedDimensions] = useState(undefined);
    let [customData, setCustomData] = useState({});

    let dimensionsToSave = selectedDimensions === dimensions.slice(-1)[0].dimensions ? Object.values(customData).join(`x`) : selectedDimensions || ``;

    return (
        <PopUpWrapper name={`CREATE_PROJECT`}>
            <H extra={`@media only screen and (max-width: 600px) { width: 90vw; };`}>Select project size</H>
            <Subtitle>you can edit size any time</Subtitle>
            <ScreensWrapper>
                {dimensions.map((item, index) => {
                    let ratio = item.dimensions
                        .split('x')
                        .map((i) => +i)
                        .reduce((a, b) => a / b);
                    return (
                        <Frame key={index}>
                            <Screen
                                ratio={ratio}
                                selected={selectedDimensions === item.dimensions}
                                index={index}
                                onClick={() => {
                                    setSelectedDimensions(item.dimensions);
                                }}
                            >
                                {index !== 3 ? <ScreenIcon name={item.label} /> : null}
                                {index !== 4 ? `(${item.dimensions})` : null}
                            </Screen>
                            <ScreenLabel>{item.label}</ScreenLabel>
                        </Frame>
                    );
                })}
            </ScreensWrapper>
            {selectedDimensions === dimensions.slice(-1)[0].dimensions ? (
                <Form
                    extra={`width: auto;`}
                    data={customData}
                    fields={customFields}
                    onChange={(key, value) => {
                        setCustomData({ ...customData, [key]: value });
                    }}
                />
            ) : null}
            <Button
                disabled={dimensionsToSave.split(`x`).length !== 2}
                onClick={() => {
                    if (dimensionsToSave.split(`x`).length === 2) {
                        customHandler('CREATE_PROJECT', { dimensions: dimensionsToSave });
                    }
                }}
            >
                Save
            </Button>
        </PopUpWrapper>
    );
};

const ScreenIcon = styled(Frame)`
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.5);
    &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: url('${(props) => { let img; try { img = require(`../../assets/images/screen_icon_${props.name.split(` `).join(`_`).toLowerCase()}.svg`); } catch (error) {} return img; }}') no-repeat center center;
    }
`;

const ScreenLabel = styled(Frame)`
    font-family: Bold;
    font-size: 18px;
    margin-top: 10px;
    font-size: 14px;
`;

const Screen = styled(Frame)`
    width: calc(174px * ${(props) => Math.min(1, props.ratio)});
    height: calc(174px / ${(props) => Math.max(1, props.ratio)});
    border-radius: 6px;
    border: 1px solid white;
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    font-size: 14px;
    background: ${(props) => (props.selected ? `linear-gradient(${gradients[props.index % gradients.length]})` : `transparent`)};
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

const ScreensWrapper = styled(Frame)`
    width: 1010px;
    margin: 50px 0;
    flex-direction: row;
    justify-content: space-between;
    color: white;
`;

const Subtitle = styled(Frame)`
    font-size: 18px;
    color: white;
`;

export default CreateProjectPopUp;

/*eslint-enable*/
