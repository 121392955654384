/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { useLocation } from 'react-router-dom'

import * as projectsActions from '../../redux/actions/ProjectsActions'

import { Frame, Input, H1, Button, AddSquare, convertHex, Checkbox, P, H } from '../UIKit/styled-templates'
import Form from '../UIKit/Form'
import PopUpWrapper from './PopUpWrapper'
import CommonHelper from '../../helpers/CommonHelper'

import useDictionary from '../helpers/useDictionary'
import useCustomDispatch, { customHandler } from '../helpers/useCustomDispatch'
import useCurrentUser from '../helpers/useCurrentUser'

let gradients = [
    `#F1D025, #FF3D45`,
    `90deg, #C182FF, #9001FF`,
    `90deg, #82BCFF, #0175FF`,
]

let CreateProjectPopUp = () => {

    let path = useLocation().pathname.split(`/`).slice(1)
    let dispatch = useDispatch()

    let [projectId, setProjectId] = useState(undefined)
    let [projectName, setProjectName] = useState(``)

    useCustomDispatch(`OPEN_RENAME_PROJECT_POP_UP`, (e) => { setProjectId(e.detail.projectId) })
    useCustomDispatch(`CLOSE_RENAME_PROJECT_POP_UP`, (e) => { setProjectId(undefined); setProjectName(``) })

    let { project } = useMappedState(useCallback((state) => ({ project: state.projects.projectsMap.get(projectId) }), [projectId]))
    useEffect(() => { if (project !== undefined) { setProjectName(project.name || ``) } }, [project])

    return (
        <PopUpWrapper name={`RENAME_PROJECT`}>
            <H extra={`@media only screen and (max-width: 600px) { width: 90vw; }; margin-bottom: 15px;`} >Rename project</H>

            <Input extra={`margin-bottom: 15px;`} placeholder={`Project name`} value={projectName} onChange={(e) => { setProjectName(e.target.value) }} />

            <Button disabled={false} onClick={async () => {
                await dispatch(projectsActions.updateProject({ ...project, name: projectName }))
                customHandler(`CLOSE_RENAME_PROJECT_POP_UP`)
            }} >Save</Button>
        </PopUpWrapper >
    );
}

const ScreenIcon = styled(Frame)`
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.5);
    &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: url("${props => { let img; try { img = require(`../../assets/images/screen_icon_${props.name.split(` `).join(`_`).toLowerCase()}.svg`) } catch (error) { } return img }}") no-repeat center center;
    }
`;

const ScreenLabel = styled(Frame)`
    font-family: Bold;
    font-size: 18px;
    margin-top: 10px;
    font-size: 14px;
`;

const Screen = styled(Frame)`
    width: calc(174px * ${props => Math.min(1, props.ratio)});
    height: calc(174px / ${props => Math.max(1, props.ratio)});
    border-radius: 6px;
    border: 1px solid white;
    opacity: ${props => props.selected ? 1 : 0.5};
    font-size: 14px;
    background: ${props => props.selected ? `linear-gradient(${gradients[props.index % gradients.length]})` : `transparent`};
    cursor: pointer;
    &:hover {
        opacity: 1;
    };
`;

const ScreensWrapper = styled(Frame)`
    width: 1010px;
    margin: 50px 0;
    flex-direction: row;
    justify-content: space-between;
`;

const Subtitle = styled(Frame)`
    font-size: 18px;
`;

export default CreateProjectPopUp;

/*eslint-enable*/
