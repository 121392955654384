import React from 'react';
import styled from 'styled-components';

import imageDoubleBackArrow from '../../../assets/images/double-back-arrow.svg';
import { Frame } from '../../UIKit/styled-templates';
import StoreImages from './store-images';
import StoreTemplates from './store-templates';

const Store = (props) => {
    const { store, dimensions } = props;
    
    const title = store ? store.charAt(0).toUpperCase() + store.slice(1) : ``;

    const onCloseStore = () => {
        window.dispatchEvent(new CustomEvent(`CLOSE_STORE`));
    };

    return (
        <Frame>
            <Title
                content={title}
                onClick={() => {
                    onCloseStore();
                }}
            />
            {(() => {
                switch (store) {
                    case `template`:
                        return <StoreTemplates type={store} />;
                    case `wave`:
                        return <StoreTemplates type={store} />;
                    case `progress`:
                        return <StoreTemplates type={store} />;
                    case `images`:
                        return <StoreImages dimensions={dimensions} />;
                    default:
                        return null;
                }
            })()}
        </Frame>
    );
};

const Title = styled(Frame)`
    width: 270px;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;
    color: white;
    font-weight: bold;
    &:before {
        font-size: 24px;
        font-weight: bold;
        content: '${(props) => props.content}';
    }
    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url('${imageDoubleBackArrow}') center no-repeat;
        cursor: pointer;
    }
`;

export default Store;
