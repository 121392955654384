import React from 'react';
import { StoreContext } from 'redux-react-hook';
import { ThemeWrapper } from './components/UIKit/styled-templates';
import RouterApp from './components/apps/RouterApp';
import store from './redux/store';

const App = () => {
    return (
        <StoreContext.Provider value={store}>
            <ThemeWrapper>
                <RouterApp />
            </ThemeWrapper>
        </StoreContext.Provider>
    );
};

export default App;
