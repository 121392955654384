/*eslint-disable*/
import React, { useRef, useCallback, useImperativeHandle, forwardRef, } from 'react';
import styled from 'styled-components';
import Dropzone, { useDropzone } from 'react-dropzone'
import axios from 'axios'

import { Frame } from '../UIKit/styled-templates'
import { UPLOAD_AUDIO_API_ENDPOINT } from "../../constants/config";

let SimpleControledUploadPanel = forwardRef((props, ref) => {
    const {
        uploadSuffix = 'upload',
        // uploadSuffix = 'pm2/upload',
        onUploaded = pld => { },
        onStart = () => { },
        onFail = () => { },
        onProgress = d => { },
        onFileDrop = f => { },
        children,
        accept = '.mp3,.wav,.ogg',
        endpoint = UPLOAD_AUDIO_API_ENDPOINT,
    } = props;

    const selectedFile = useRef();

    useImperativeHandle(ref, () => ({
        uploadFile: async () => {
            let file = selectedFile.current;
            const fd = new FormData();
            fd.append('file', file);
            onStart();
            return await axios.post(endpoint, fd, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    let progress = 100.0 * (+loaded) / (+total);
                    // console.log('progress: ', progress);
                    onProgress({ progress, loaded, total });
                },
                params: { userId: window.currentUserId }
            })
                .then(d => d.data.result).then(pld => { onUploaded(pld); return pld })
                .catch(err => { onFail(err); return err })
        }
    }));

    const onDrop = useCallback((files) => {
        selectedFile.current = files[0];
        onFileDrop(selectedFile.current);
    }, []);

    const { files, getRootProps, getInputProps } = useDropzone({ onDrop });


    return (
        <Wrapper className={'SimpleUploadPanel_wr'} {...getRootProps()} >
            <input {...getInputProps()} accept={accept} />
            {children}
        </Wrapper>
    );
})

const Wrapper = styled(Frame)`
    width: 100%;
    height: 100%;
    border-radius: 10px; 
    outline: none;
    cursor: pointer;
    :hover{
        opacity: 0.8;
    }
`;

export default SimpleControledUploadPanel;
/*eslint-enable*/