import axios from 'axios';

const TemplatesAPI = {
    getAllTemplates: async () => {
        const { data: pld } = await axios.get(`/templates/all`);
        if (pld.error !== undefined) {
            throw pld.error;
        }
        return pld.result;
    },

    createTemplate: async (data) => {
        const { data: pld } = await axios.post(`/template`, data);
        if (pld.error !== undefined) {
            throw pld.error;
        }
        return pld.result;
    },

    updateTemplate: async (data) => {
        const { data: pld } = await axios.post(`/template`, data);
        if (pld.error !== undefined) {
            throw pld.error;
        }
        return pld.result;
    },

    deleteTemplate: async (id) => {
        const { data: pld } = await axios.post(`/template/delete`, { id: id });
        if (pld.error !== undefined) {
            throw pld.error;
        }
        return pld.result;
    },
};

export default TemplatesAPI;
