import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import styled, { keyframes } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import uuid from 'uuid';

import { API_ENDPOINT } from '../../../constants/config';

import Dropzone from '../../UIKit/Dropzone';
import { Frame, Input, Text } from '../../UIKit/styled-templates';

import imageTrashCanRed from '../../../assets/images/trash_can_red.svg';
import useCurrentUser from '../../helpers/useCurrentUser';
import { customHandler } from '../../helpers/useCustomDispatch';

const uploadSuffix = `upload_link`;

const StoreImages = (props) => {
    const { store, dimensions } = props;

    let { currentUser, updateUser } = useCurrentUser();

    let ratio = dimensions.split(`x`)[0] / dimensions.split(`x`)[1];

    let onAddLayer = (item) => {
        window.dispatchEvent(new CustomEvent(`ADD_LAYER`, { detail: item }));
    };

    const [pexels_mode, set_pexels_mode] = useState(0);
    const [search, set_search] = useState(``);
    const [loading, set_loading] = useState(false);
    const [pexels_loading, set_pexels_loading] = useState(false);
    const [progress, set_progress] = useState(0);
    const [image_url, set_image_url] = useState(`https://sun9-61.userapi.com/c854220/v854220873/223638/-68elr0F73Q.jpg`);
    const [pexels, set_pexels] = useState([]);

    let loadPixels = async () => {
        if (search.length > 0) {
            set_pexels_loading(true);
            customHandler(`OPEN_PRELOADER`);
            let images = await (await axios.get(`${API_ENDPOINT}/pexels/find?s=${search}&number=${50}`)).data.result;
            customHandler(`CLOSE_PRELOADER`);
            set_pexels_loading(false);
            set_pexels(images);
        }
    };

    let onAddImageLayer = (url) => {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            let proportion = this.height / this.width;
            onAddLayer({
                ...default_image,
                type: `image`,
                id: uuid(),
                url,
                proportions: proportion,
                width_p: 0.5,
                height_p: 0.5 * proportion * ratio,
                fade: false,
                fade_amount: 1,
                fade_ratio: 0.5,
                shake_amp: 0.5,
                shake_vel: 0.5,
                scale_on_amp: false,
                scale_on_amp_ratio: 0.5,
            });
        };
    };

    const [debouncedLoadPexels] = useDebouncedCallback(
        (a) => {
            loadPixels();
        },
        1000,
        []
    );
    useEffect(() => {
        debouncedLoadPexels();
    }, [debouncedLoadPexels, search]);

    return (
        <Frame extra={`width: 100%;`}>
            <Frame extra={`width: 100%; height: 3vw; justify-content: space-around; margin-bottom: 25px;`}>
                <Frame extra={`width: 100%; flex-direction: row;`}>
                    <Text
                        extra={`width: 50%; font-weight: bold;`}
                        bold
                        pointer
                        onClick={() => {
                            set_pexels_mode(0);
                        }}
                    >
                        My files
                    </Text>
                    <Text
                        extra={`width: 50%; font-weight: bold;`}
                        bold
                        pointer
                        onClick={() => {
                            set_pexels_mode(1);
                        }}
                    >
                        Pexel
                    </Text>
                </Frame>
                <Frame extra={`width: 100%; background: red; flex-direction: row;`}>
                    <SelectionPointer selectedIndex={pexels_mode} />
                </Frame>
            </Frame>
            <Frame extra={`width: 21.5vw; flex-wrap: wrap; flex-direction: row; align-items: space-between;`}>
                {(() => {
                    switch (pexels_mode) {
                        case 0:
                            return (
                                <Frame>
                                    <PhotoWrapper>
                                        {((currentUser.info || { storedPhotos: [] }).storedPhotos || []).map((item, index) => (
                                            <Photo
                                                key={index}
                                                src={item}
                                                onClick={async (e) => {
                                                    if (e.target.id === `trash_can`) {
                                                        return;
                                                    }
                                                    onAddImageLayer(item);
                                                }}
                                            >
                                                <TrashCan
                                                    id={`trash_can`}
                                                    onClick={() => {
                                                        currentUser.info.storedPhotos = (currentUser.info.storedPhotos || []).filter(
                                                            (i) => i !== item
                                                        );
                                                        updateUser(currentUser.info);
                                                    }}
                                                />
                                            </Photo>
                                        ))}
                                    </PhotoWrapper>
                                    <CreateButton>
                                        <Dropzone
                                            accept={'.jpg,.png'}
                                            onUploaded={(payload) => {
                                                updateUser({
                                                    ...(currentUser.info || {}),
                                                    storedPhotos: [...((currentUser.info || {}).storedPhotos || []), payload.url],
                                                });
                                                onAddImageLayer(payload.url);
                                            }}
                                        />
                                    </CreateButton>
                                    {/* <Text>Or paste a direct link here</Text>
                            <Input short={false} extra={`width: 220px;`} placeholder={`image url`} value={image_url} onChange={(e) => { set_image_url(e.target.value) }} />
                            <Button short={false} shaped={image_url.indexOf(`http`) < 0} background={props => props.theme.green} onClick={async () => {
                                set_loading(true)
                                let goodUrl = (await axios.post(`${API_ENDPOINT}/upload_link`, { url: image_url })).data.result.url
                                default_image.url = goodUrl
                                set_loading(false)
                                onAddLayer(...await CommonHelper.loadLayers([{ ...default_image, type: `image`, id: uuid() }]))
                            }}>{loading ? `Loading` : `Download`}</Button> */}
                                </Frame>
                            );
                        case 1:
                            return (
                                <>
                                    <Input
                                        short={false}
                                        extra={`width: 220px;`}
                                        placeholder={`Search...`}
                                        onChange={(e) => {
                                            set_search(e.target.value);
                                        }}
                                    />
                                    {pexels.length > 0 ? (
                                        <Masonry breakpointCols={2} className={`my-masonry-grid`} columnClassName={`my-masonry-grid_column`}>
                                            {pexels.map((image, index) => (
                                                <PexelWrapper key={index}>
                                                    <Pexel src={image.src.large} />
                                                    <AddButton
                                                        onClick={async () => {
                                                            set_loading(true);
                                                            let proportion = image.width / image.height;
                                                            customHandler(`OPEN_PRELOADER`);
                                                            let goodUrl = (
                                                                await axios.post(`${API_ENDPOINT}/${uploadSuffix}`, { url: image.src.original })
                                                            ).data.result.url;
                                                            customHandler(`CLOSE_PRELOADER`);
                                                            set_loading(false);
                                                            onAddImageLayer(goodUrl);
                                                        }}
                                                    >
                                                        Add
                                                    </AddButton>
                                                </PexelWrapper>
                                            ))}
                                        </Masonry>
                                    ) : (
                                        <Frame extra={`width: 100%; height: 20vw;`}>
                                            <Text>Ask for a picture you'd like</Text>
                                        </Frame>
                                    )}
                                </>
                            );
                        default:
                            return null;
                    }
                })()}
            </Frame>
        </Frame>
    );
};

const PhotoWrapper = styled(Frame)`
    width: 270px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const animation = keyframes`
    0% { transform: rotate(0deg) scale(1); }
    33% { transform: rotate(-30deg) scale(1.06); }
    67% { transform: rotate(30deg) scale(1.13); }
    100% { transform: rotate(0deg) scale(1.2); }
`;

const TrashCan = styled(Frame)`
    width: 24px;
    height: 24px;
    background: url('${imageTrashCanRed}') no-repeat center center / cover;
    transition: 0.2s;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    &:hover {
        animation: ${animation} 0.2s linear 1;
        transform: scale(1.2);
    }
`;

const Photo = styled(Frame)`
    width: 130px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background: url('${(props) => props.src}') no-repeat center center / cover;
        transition: 0.2s;
    }
    &:hover {
        &::after {
            transform: scale(1.05);
        }
        ${TrashCan} {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const CreateButton = styled(Frame)`
    width: 190px;
    height: 50px;
    border-radius: 25px;
    background: linear-gradient(135deg, #f8cf3a, #de2bad);
    margin: 45px 0px;
    cursor: pointer;
    &:hover {
        transform: scale(1.02);
    }
    > * {
        &:first-child {
            &:after {
                content: 'Upload image';
                font-size: 18px;
                font-family: Bold;
                color: white;
            }
        }
    }
`;

const PexelWrapper = styled(Frame)`
    position: relative;
    width: 100%;
    border-radius: 0.5vw;
    overflow: hidden;
`;

const SelectionPointer = styled(Frame)`
    width: 50%;
    position: absolute;
    left: 0;
    transform: translateX(${(props) => props.selectedIndex * 100}%);
    &:after {
        display: flex;
        transition: 0.2s;
        content: '';
        width: 130px;
        height: 3px;
        border-radius: 2px;
        background: linear-gradient(90deg, ${(props) => [`#82BCFF, #0175FF`, `#C182FF, #9001FF`][props.selectedIndex]});
    }
`;

const Pexel = styled.img`
    width: 100%;
    // margin: 0.5vw;
    // border-radius: 0.5vw;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;
    &:hover {
        transform: scale(1.05);
    }
`;

const AddButton = styled(Text).attrs((props) => ({ bold: true }))`
    position: absolute;
    bottom: 0.5vw;
    right: 0.5vw;
    background: ${(props) => props.theme.green};
    border-radius: 0.5vw;
    padding: 0.5vw;
    cursor: pointer;
`;

const default_properties = {
    top: 0.25,
    left: 0.25,
    width_p: 0.5,
    height_p: 0.5,
    color: `#2DC76D`,
};

const default_image = {
    type: `image`,
    ...default_properties,
    url: `https://www.englishpatient.org/english-patient-files/3ee363d5-7590-471a-bb41-358aa96b0579.jpeg`,
};

export default StoreImages;
