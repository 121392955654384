/*eslint-disable*/
import Spin from 'arui-feather/spin';
import React, { useState } from 'react';
import styled from 'styled-components';

import pro_settings from '../../projects/helpers/pro_settings';

import { Button } from "../../ui/Button";
import { Input } from "../../ui/Input";
import { Textarea } from "../../ui/Textarea";

import Dropzone from '../../UIKit/Dropzone';
import { Frame, Range, Switch } from '../../UIKit/styled-templates';

import Select from 'react-select';

const TYPES_OPTIONS = [
    {
        label: 'Wave',
        value: 'wave',
    },
    {
        label: 'Progress',
        value: 'progress',
    },
    {
        label: 'Shape',
        value: 'shape',
    },
]

export default function UpdateTemplateForm(props) {
    const {
        onSave = d => { },
        onDelete = d => { },
        loading = false,
        template = {},
    } = props;

    const [type, setType] = useState(template.type === undefined ? 'wave' : template.type);
    const [name, setName] = useState(template.name === undefined ? '' : template.name);
    const [code, setCode] = useState(template.code === undefined ? '' : template.code);

    const [left, setLeft] = useState(props.left === undefined ? 0.1 : props.left);
    const [top, setTop] = useState(props.top === undefined ? 0.1 : props.top);
    const [width_p, setWidth_p] = useState(props.width_p === undefined ? 0.5 : props.width_p);
    const [height_p, setHeight_p] = useState(props.height_p === undefined ? 0.3 : props.height_p);
    const [preview, setPreview] = useState(props.preview);

    let [defaultValues, setDefaultValues] = useState({
        left: 0,
        top: 0,
        width_p: 0,
        height_p: 0,
        ...(template.defaultValues || {}),
    })

    let [availableSettings, setAvailableSettings] = useState(template.availableSettings || [])

    return (
        <Wrapper>

            {
                pro_settings.filter(i => i.layers.indexOf(type) > -1).map((item, index) => {
                    let isAvailable = availableSettings.indexOf(item.name) > -1
                    return (
                        <Frame key={index} extra={`align-items: flex-start; border: 1px solid black;`} >
                            <Frame extra={`flex-direction: row; justify-content: flex-start;`} >
                                <Switch value={isAvailable} onChange={() => {
                                    if (isAvailable) {
                                        setAvailableSettings(availableSettings.filter(i => i !== item.name))
                                    } else {
                                        setAvailableSettings([...availableSettings, item.name])
                                    }
                                }} />
                                {item.name.split(`_`).join(` `).uppercaseFirstLetter()}
                            </Frame>
                            <Frame row >
                                <Frame>Default value</Frame>
                                {(() => {
                                    switch (item.type) {
                                        case `switch`:
                                            return <Switch value={defaultValues[item.name]} onChange={(e) => { setDefaultValues({ ...defaultValues, [item.name]: e }) }} />
                                        case `range`:
                                            return <Range value={defaultValues[item.name]} onChange={(e) => { setDefaultValues({ ...defaultValues, [item.name]: +e }) }} />
                                        default:
                                            break;
                                    }
                                })()}
                            </Frame>
                        </Frame>
                    )
                })
            }

            <Field>
                <Label>Name</Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }} />
            </Field>

            <Field>
                <Label>Type</Label>
                <Select
                    options={TYPES_OPTIONS}
                    value={TYPES_OPTIONS.filter(a => (a.value === type))[0]} onChange={a => {
                        setType(a.value);
                    }} />
            </Field>

            <Field>
                <Label>Code</Label>
                <Textarea value={code} style={{ height: 400 }} onChange={(e) => { setCode(e.target.value) }} />
            </Field>

            <Field>
                <Label>left</Label>
                <Input value={defaultValues.left} onChange={(e) => { setDefaultValues({ ...defaultValues, [`left`]: e.target.value }) }} />
            </Field>

            <Field>
                <Label>top</Label>
                <Input value={defaultValues.top} onChange={(e) => { setDefaultValues({ ...defaultValues, [`top`]: e.target.value }) }} />
            </Field>

            <Field>
                <Label>width_p</Label>
                <Input value={defaultValues.width_p} onChange={(e) => { setDefaultValues({ ...defaultValues, [`width_p`]: e.target.value }) }} />
            </Field>

            <Field>
                <Label>height_p</Label>
                <Input value={defaultValues.height_p} onChange={(e) => { setDefaultValues({ ...defaultValues, [`height_p`]: e.target.value }) }} />
            </Field>

            <Field>
                <Label>Preview</Label>
                <Button>
                    <Dropzone accept={'.jpg,.png'} onUploaded={(payload) => { setPreview(payload.url) }} >
                        Select preview image
                    </Dropzone>
                </Button>
                <Frame>{preview}</Frame>
            </Field>

            <Field>
                <Spin visible={loading} />
                {loading === true ? null :
                    <Button onClick={async () => {
                        onSave({ name, type, defaultValues, preview, code, availableSettings, })
                    }}>
                        Save
                    </Button>
                }
                <Button onClick={() => {
                    onDelete(props.id)
                }}>
                    Delete
                </Button>
            </Field>

        </Wrapper>
    );
}

const DropBox = styled(Frame)`
    width: 15vw;
    height: 15vw;
    border-radius: 1vw;
    margin: 1vw;
    border: 0.5vw dashed ${props => props.theme.background.support};
    cursor: pointer;
`;

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.div`
    font-weight: bold;
`;

/*eslint-enable*/
