import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import CommonHelper from '../../../helpers/CommonHelper';

const SortableItem = SortableElement(({ value }) => <>{value}</>);

const SortableList = SortableContainer(({ items, disabled }) => {
    return (
        <div>
            {items.map((value, index) => (
                // @ts-ignore
                <SortableItem key={`item-${index}`} index={index} value={value} disabled={disabled} />
            ))}
        </div>
    );
});

function Sortable(props) {
    const { values, onSortEnd, component, anchorClassName, disabled = false } = props;

    const getNewValuesAndChange = ({ oldIndex, newIndex }) => {
        const newItems = values.slice();
        newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
        onSortEnd(newItems);
    };

    return (
        <SortableList
            items={values.map(component)}
            onSortEnd={getNewValuesAndChange}
            lockAxis="y"
            helperContainer={document.querySelector('tbody > div')}
            helperClass="sortable-helper"
            shouldCancelStart={(e) => {
                const classPath = CommonHelper.getElementClassPath(e.target);
                return (
                    !(anchorClassName && classPath.some((className) => className?.includes(anchorClassName)))
                );
            }}
            disabled={disabled}
        />
    );
}

export default Sortable;
