import axios from 'axios';

const CommonHelper = {
    getContainerDimensions(containerSize = {}, proportion = 1.0) {
        let { width, height } = containerSize;
        let containerRatio = width === undefined ? 1 : (1.0 * width) / height;
        let videoWidth = width,
            videoHeight = height;
        if (proportion > containerRatio) {
            // wider
            videoWidth = width;
            videoHeight = (1.0 * width) / proportion;
        } else {
            // narrower
            videoHeight = height;
            videoWidth = 1.0 * height * proportion;
        }
        return {
            width: videoWidth,
            height: videoHeight,
        };
    },

    getCurrentUserRole(state) {
        if (state === undefined) {
            return false;
        }
        let { currentUserId, usersMap } = state.users;
        let currentUser = usersMap.get(currentUserId);
        if (currentUser === undefined) {
            return undefined;
        }
        return currentUser.userRole;
    },

    getUserName(user) {
        if (user === undefined) {
            return 'Guest';
        }
        let { firstName, lastName, name } = user;
        if (firstName === undefined && lastName === undefined) {
            return `Carol Black`;
        }
        return `${firstName} ${lastName}`;
    },

    downloadFile(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    async getFile(url, type = 'audio/mp3') {
        // console.log('getFile: url, type = ', url, type);
        let d = (
            await axios.get(url, {
                responseType: 'arraybuffer',
            })
        ).data;
        const blob = new Blob([d], {
            type: type,
        });
        return blob;
    },

    async getCachedFileBlobUrl(url, type = 'image/jpg') {
        let registry = window.cachedRegistry || {};
        if (registry[url] !== undefined) {
            return registry[url];
        }
        let d = await this.getFile(url, type);
        let objectUrl = window.URL.createObjectURL(d);
        registry[url] = objectUrl;
        window.cachedRegistry = registry;
        return objectUrl;
    },

    async getCachedAudioUrl(url) {
        let registry = window.cachedRegistry || {};
        if (registry[url] !== undefined) {
            return registry[url];
        }
        let d = await this.getFile(url, 'audio/mp3');
        let objectUrl = window.URL.createObjectURL(d);
        registry[url] = objectUrl;
        window.cachedRegistry = registry;
        return objectUrl;
    },

    getGradColor(ratio = 0.5) {
        let color1 = '27E634';
        let color2 = 'F73434';
        let hex = function (x) {
            x = x.toString(16);
            return x.length === 1 ? '0' + x : x;
        };
        let r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
        let g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
        let b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));
        return `#${hex(r)}${hex(g)}${hex(b)}`;
    },

    async get_waveform(url) {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        const filterData = (audioBuffer) => {
            const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
            // const samples = audioBuffer.duration * 30; // 30 fps
            const samples = 1000;
            const blockSize = Math.floor(rawData.length / samples); // Number of samples in each subdivision
            const filteredData = [];
            for (let i = 0; i < samples; i++) {
                filteredData.push(rawData[i * blockSize]);
            }
            return filteredData;
        };
        return new Promise((resolve, reject) => {
            fetch(url)
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
                .then((audioBuffer) => resolve(filterData(audioBuffer)));
        });
    },

    async loadLayers(item) {
        let promises = [];
        item.filter((i) => i.type === `image` || i.type === `shape`).forEach((layer) => {
            // console.log(layer);
            promises.push(
                new Promise((resolve, reject) => {
                    CommonHelper.getCachedAudioUrl(layer.url).then(async (layer_cached_url) => {
                        layer.cached_url = layer_cached_url;
                        resolve();
                    });
                })
            );
        });
        await Promise.all(promises);
        return item;
    },

    getElementClassPath: (element) => {
        let path = [];
        path.push(element?.className);
        const goToParent = (element) => {
            path.push(element?.className);
            if (element?.parentNode) {
                goToParent(element?.parentNode);
            }
        };
        goToParent(element);
        return path;
    },
};

export default CommonHelper;
