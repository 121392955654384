/*eslint-disable*/
import React, { useState, useRef } from 'react';

import SimpleControledUploadPanel from '../upload/SimpleControledUploadPanel'
import { customHandler } from '../helpers/useCustomDispatch'

let Dropzone = (props) => {

    let uploaderRef = useRef(null)
    let [name, setName] = useState(``)

    return (
        <SimpleControledUploadPanel
            ref={uploaderRef}
            onUploaded={(pld) => {
                customHandler(`CLOSE_PRELOADER`)
                if (props.onUploaded !== undefined) {
                    props.onUploaded({ ...pld, name })
                }
            }}
            onFileDrop={(f) => {
                customHandler(`OPEN_PRELOADER`)
                setName(f.name)
                uploaderRef.current.uploadFile()
                if (props.onFileDrop !== undefined) {
                    props.onFileDrop(f)
                }
            }}
            accept={props.accept || `.png,.jpg`}
            // endpoint={`https://wave.rest/api/upload`}
            // endpoint={`https://os.mipt.ru/wa/audio/upload`}
            endpoint={props.endpoint || `https://api3.aux.makewave.online/upload/image?height=1080`}
        >
            {props.children}
        </SimpleControledUploadPanel>
    );
}

export default Dropzone;

/*eslint-enable*/