import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import imageMLetter from '../../assets/images/m-letter.svg';
import imageVertDots from '../../assets/images/vert_dots.svg';

import * as projectsActions from '../../redux/actions/ProjectsActions';

import Dropzone from '../UIKit/Dropzone';
import { Frame, Link } from '../UIKit/styled-templates';

import { useHistory } from 'react-router-dom';
import useCustomDispatch, { customHandler } from '../helpers/useCustomDispatch';

const gradients = [`#F1D025, #FF3D45`, `90deg, #C182FF, #9001FF`, `90deg, #82BCFF, #0175FF`];

const secondsToReadableTime = (seconds) => {
    const m = moment.duration(seconds, 'seconds');
    const hours = m.hours();
    const minutes = m.minutes();
    const _seconds = m.seconds();
    return `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}${_seconds ? _seconds + 's' : ''}`;
};

const ProjectsList = (props) => {
    const { userId } = props;

    const { push } = useHistory();

    const { projects, uId } = useMappedState(
        useCallback(
            (state) => {
                let uId = userId === undefined ? state.users.currentUserId : userId;
                return {
                    loading: state.users.loading || state.projects.loading,
                    projects: state.projects.projectsMap
                        .toArray()
                        .filter((a) => a.userId === uId)
                        .sort((a, b) => +b.timestamp - +a.timestamp),
                    currentUser: state.users.usersMap.get(state.users.currentUserId),
                    currentUserId: state.users.currentUserId,
                    uId: uId,
                };
            },
            [userId]
        )
    );
    const { availableSeconds } = useMappedState((state) => state.users.usersMap.get(state.users.currentUserId));

    const dispatch = useDispatch();

    let [optionsPopUpVisible, setOptionsPopUpVisible] = useState(undefined);

    useEffect(() => {
        dispatch(projectsActions.loadUserProjects(uId));
    }, [dispatch, uId]);

    useEffect(() => {
        projects.forEach((project) => {
            let element = document.getElementById(`${project.id}_pop_up`);
            if (element) {
                element.style.opacity = project.id === optionsPopUpVisible ? 1 : 0;
                element.style.visibility = project.id === optionsPopUpVisible ? `visible` : `hidden`;
                element.style.transform = project.id === optionsPopUpVisible ? `translateY(12px)` : `translateY(20px)`;
            }
        });
    }, [projects, optionsPopUpVisible]);

    let [newProject, setNewProject] = useState({ layers: [] });

    useCustomDispatch(
        `CREATE_PROJECT`,
        async (d) => {
            let { project } = await dispatch(
                projectsActions.createProject({
                    ...newProject,
                    dimensions: d.detail.dimensions,
                })
            );
            customHandler(`CLOSE_CREATE_PROJECT_POP_UP`);
            push(`/project/${project.id}/amp`);
        },
        []
    );

    return (
        <Wrapper
            onClick={(e) => {
                if (e.target.id.indexOf('_pop_up') === -1 && e.target.id.indexOf('dots') === -1) {
                    setOptionsPopUpVisible(undefined);
                }
            }}
            extra=".available-seconds-text { color: #ffffff; }"
        >
            <Content>
                <CreateButton>
                    <Dropzone
                        accept={'.mp3,.wav'}
                        endpoint={`https://api3.aux.makewave.online/audio/upload`}
                        onUploaded={(pld) => {
                            let { url, name, waveUrl, duration } = pld;
                            setNewProject({ ...newProject, audioUrl: url, name: name, waveUrl: waveUrl, duration: duration });
                            customHandler('OPEN_CREATE_PROJECT_POP_UP');
                        }}
                    />
                </CreateButton>
                <span className="available-seconds-text">Rendering time available: {secondsToReadableTime(availableSeconds)}</span>
                {projects.map((project, index) => {
                    return (
                        <InlineBox preset={project.preset} key={index}>
                            <Link to={`/project/${project.id}/amp`}>
                                <Frame row>
                                    <Avatar index={index} />
                                    <Frame extra={`margin-left: 20px; align-items: flex-start;`}>
                                        <ProjectName>{project.name}</ProjectName>
                                        <Frame extra={`color: grey; font-size: 12px; line-height: 32px;`}>
                                            Last edited {moment(project.updatedTimestamp).format(`DD.MM.YYYY`)}
                                        </Frame>
                                    </Frame>
                                </Frame>
                            </Link>
                            <Dots
                                id={`dots`}
                                onClick={() => {
                                    setOptionsPopUpVisible(project.id);
                                }}
                            />
                            <OptionsPopUp id={`${project.id}_pop_up`}>
                                <Link to={`/project/${project.id}/amp`}>Edit</Link>
                                <Frame
                                    onClick={() => {
                                        customHandler(`OPEN_RENAME_PROJECT_POP_UP`, { projectId: project.id });
                                    }}
                                >
                                    Rename
                                </Frame>
                                <Frame
                                    onClick={() => {
                                        let duplicateProject = { ...project };
                                        delete duplicateProject.id;
                                        dispatch(
                                            projectsActions.createProject({
                                                ...duplicateProject,
                                                name: (duplicateProject.name || ``) + ` (copy)`,
                                            })
                                        );
                                    }}
                                >
                                    Duplicate
                                </Frame>
                                <Frame
                                    onClick={() => {
                                        dispatch(projectsActions.deleteProject(project.id));
                                    }}
                                >
                                    Delete
                                </Frame>
                            </OptionsPopUp>
                        </InlineBox>
                    );
                })}
            </Content>
        </Wrapper>
    );
};

const CreateButton = styled(Frame)`
    width: 350px;
    height: 70px;
    border-radius: 35px;
    background: linear-gradient(90deg, #0a7bff, #0175ff);
    margin: 45px 0px;
    cursor: pointer;

    &:hover {
        transform: scale(1.02);
    }

    > * {
        &:first-child {
            &:after {
                content: '+Create new project';
                font-size: 24px;
                font-family: Bold;
                color: white;
            }
        }
    }
`;

const OptionsPopUp = styled(Frame)`
    padding: 15px 15px 15px 30px;
    border-radius: 6px;
    background: ${(props) => props.theme.background.overlay};
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 50%;
    right: 0;
    align-items: flex-end;
    z-index: 2;
    opacity: 0;
    visibility: hidden;

    > * {
        color: white;
        font-size: 14px;
        font-weight: bold;
        line-height: 38px;

        &:hover {
            transform: translateX(3px);
        }
    }
`;

const Dots = styled(Frame)`
    width: 24px;
    height: 24px;
    background: url('${imageVertDots}') center no-repeat;
`;

const ProjectName = styled(Frame)`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 600px;
    font-size: 16px;
    font-weight: bold;
    color: white;
`;

const Avatar = styled(Frame)`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: linear-gradient(${(props) => gradients[props.index % gradients.length]});

    &:after {
        content: '';
        width: 26px;
        height: 23px;
        opacity: 0.8;
        background: url('${imageMLetter}') center no-repeat;
    }
`;

const Wrapper = styled(Frame)`
    width: 100vw;
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
`;

const InlineBox = styled(Frame)`
    width: 730px;
    height: 110px;
    border-top: 1px solid #0d1244;
    box-shadow: 0 0 0.5vw ${(props) => (props.preset ? props.theme.green : `transparent`)};
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    > * {
        cursor: pointer;
    }
`;

export default ProjectsList;
