import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';

import * as projectsActions from '../../redux/actions/ProjectsActions';
import * as templatesActions from '../../redux/actions/TemplatesActions';
import * as usersActions from '../../redux/actions/UsersActions';

import AdminConfigsPage from '../pages/admin-configs-page';
import BillingPage from '../pages/billing-page';
import ChangePasswordPage from '../pages/change-password-page';
import EditorPage from '../pages/editor-page';
import LandingPage from '../pages/landing-page';
import LoginPage from '../pages/login-page';
import PreloaderPage from '../pages/preloader-page';
import PricingPage from '../pages/pricing-page';
import ProjectsList from '../pages/projects-list';
import RenderingPage from '../pages/rendering-page';
import SettingsPage from '../pages/settings-page';
import SignUp from '../pages/signup-page';
import AdminServersPanel from '../servers/panels/AdminServersPanel';
import PageWrapper from '../templates/PageWrapper';
import TemplatesPanel from '../wave_templates/panels/TemplatesPanel';

import CreateProjectPopUp from '../pop-ups/create-project-popup';
import RenameProjectPopUp from '../pop-ups/rename-project-popup';

const adminRoute = (
    <>
        <Switch>
            <Route exact path="/project/:id/amp" component={EditorPage} />{' '}
            <Route
                exact
                component={() => (
                    <PageWrapper>
                        {/* <Route exct path='/subscriptions' component={AdminSubscriptionsApp} /> */}{' '}
                        {/* <Route exact path='/users' component={AdminUsersApp} /> */}
                        <Route exact path="/templates" component={TemplatesPanel} /> <Route exact path="/configs" component={AdminConfigsPage} />
                        <Route exact path="/servers" component={AdminServersPanel} />
                        <Route exact path="/project/:id/view" component={RenderingPage} />
                        <Route exact path="/billing" component={BillingPage} />
                        <Route exact path="/pricing" component={PricingPage} />
                        <Route exact path="/settings" component={SettingsPage} />
                        <Route exact path="/change-password" component={ChangePasswordPage} />
                        <Route exact path="/workspace" component={ProjectsList} />
                        {/* <Route exact path='/connections' component={ProfileConnectionsPage} /> */}
                        <Route exact path="/" component={ProjectsList} />
                        <Redirect to="/" />
                    </PageWrapper>
                )}
            />
        </Switch>
    </>
);

const userRoute = (
    <>
        <Switch>
            <Route exact path="/project/:id/amp" component={EditorPage} />
            {/* prettier-ignore */}
            <Route exact path="/project/:id/view" component={() => (<PageWrapper><RenderingPage /></PageWrapper>)}/>
            {/* prettier-ignore */}
            <Route exact path="/billing" component={() => (<PageWrapper><BillingPage /></PageWrapper>)}/>
            {/* prettier-ignore */}
            <Route exact path="/pricing" component={() => (<PageWrapper><PricingPage /></PageWrapper>)}/>
            {/* prettier-ignore */}
            <Route exact path="/settings" component={() => (<PageWrapper><SettingsPage /></PageWrapper>)}/>
            {/* prettier-ignore */}
            <Route exact path="/change-password" component={() => (<PageWrapper><ChangePasswordPage /></PageWrapper>)}/>
            {/* prettier-ignore */}
            <Route exact path="/workspace" component={() => (<PageWrapper><ProjectsList /></PageWrapper>)}/>
            {/* prettier-ignore */}
            <Route exact path="/" component={() => (<PageWrapper><ProjectsList /></PageWrapper>)}/>
            <Redirect to="/" />
        </Switch>
    </>
);

const guestRoute = (
    <>
        <Switch>
            <Route exact path="/login" component={LoginPage} />
            {/* prettier-ignore */}
            <Route exact path="/signup" component={() => <PageWrapper><SignUp/></PageWrapper>} />
            {/* prettier-ignore */}
            <Route exact path="/pricing" component={() => <PageWrapper><PricingPage/></PageWrapper>} />
            <Route component={LandingPage} />
            <Redirect to="/" />
        </Switch>
    </>
);

const RouterApp = () => {
    const { initialized, role } = useMappedState((state) => {
        const currentUser = state.users.currentUserId && state.users.usersMap.get(state.users.currentUserId);
        return {
            initialized: state.users.initialized,
            role: currentUser ? currentUser.userRole : 'guest',
        };
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then((pld) => {
            if (pld.user !== undefined) {
                dispatch(templatesActions.loadAllTemplates());
                dispatch(projectsActions.loadUserProjects(pld.user.id));
            }
        });
    }, [dispatch]);

    if (initialized === false) {
        return null;
    }

    const route =
        {
            admin: adminRoute,
            user: userRoute,
            guest: guestRoute,
        }[role] || guestRoute;

    return (
        <BrowserRouter>
            <Wrapper>
                <PreloaderPage />
                <CreateProjectPopUp />
                <RenameProjectPopUp />
                {route}
            </Wrapper>
        </BrowserRouter>
    );
};

const Wrapper = styled.div`
    background: ${(props) => props.theme.background.primary};
    width: 100vw;
    height: auto;
`;

export default RouterApp;
