/*eslint-disable*/
import axios from 'axios';

const UsersAPI = {
    parselessLogout: async () => {
        try {
            window.localStorage.removeItem(`auth_token`);
        } catch (exc) {
            throw new Error(exc);
        }
    },
    parselessLogin: async (email, password) => {
        try {
            const lowerCaseEmail = email.toLowerCase();
            const { data: pld } = await axios.post('/users/login', { email: lowerCaseEmail, password });
            const { token } = pld.result;
            window.localStorage.setItem(`auth_token`, token);
            const { data: user } = await axios.get(`/user/token/${token}`);
            return user;
        } catch (error) {
            throw error.response.data;
        }
    },
    parselessSignup: async (email, password) => {
        try {
            const { data: pld } = await axios.post(`/users/signup`, { email, password });
            if (pld.error !== undefined) {
                throw pld.error;
            }
            const { token } = pld.result;
            window.localStorage.setItem('auth_token', token);
            const { data: user } = await axios.get(`/user/token/${token}`);
            return user;
        } catch (error) {
            throw error.response.data;
        }
    },
    parselesslyGetCurrentUser: async () => {
        const { data: pld } = await axios.get('/user');
        if (pld.error !== undefined) {
            return undefined;
        }
        return pld.result;
    },
    createUser(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/users/create`, data)
                .then((d) => d.data)
                .then((user) => {
                    if (user.error !== undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                })
                .catch((err) => reject(err));
        });
    },
    getUsersByIds(ids) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/users/by_ids`, { ids: ids })
                .then((d) => d.data)
                .then((users) => {
                    if (users.error !== undefined) {
                        return reject(users.error);
                    }
                    resolve(users);
                })
                .catch((err) => reject(err));
        });
    },
    updateUser(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/users/update`, data)
                .then((d) => d.data)
                .then((pld) => {
                    if (pld.error !== undefined) {
                        return reject(pld.error);
                    }
                    resolve(pld.result);
                })
                .catch((err) => reject(err));
        });
    },
    deleteUser(id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/users/delete/:${id}`)
                .then((d) => d.data)
                .then(() => resolve(id))
                .catch((err) => reject(err));
        });
    },
    getUsersByQueryMap(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/users/query`, {
                    query: queryMap,
                })
                .then((d) => d.data)
                .then((users) => resolve(users))
                .catch((err) => reject(err));
        });
    },
    getAllUsers(queryMap = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/users/all`)
                .then((d) => d.data.result)
                .then((users) => {
                    resolve(users);
                })
                .catch((err) => reject(err));
        });
    },
    getAllStudents() {
        return new Promise((resolve, reject) => {
            let queryMap = {
                equalTo: [['userRole', 'student']],
            };
            this.getUsersByQueryMap(queryMap)
                .then((users) => {
                    resolve(users.sort((a, b) => +b.tiemsatmp - +a.timestamp));
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getAllTeachers() {
        return new Promise((resolve, reject) => {
            let queryMap = {
                equalTo: [['userRole', 'teacher']],
            };
            this.getUsersByQueryMap(queryMap)
                .then((users) => {
                    resolve(users.sort((a, b) => +b.timestamp - +a.timestamp));
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getUserById(userId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/user/${userId}`)
                .then((d) => d.data)
                .then((user) => {
                    if (user.error !== undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    createStudentTeacherLink(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/student_teacher_links/create`, data)
                .then((d) => d.data)
                .then((user) => {
                    if (user.error !== undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                })
                .catch((err) => reject(err));
        });
    },
    updateStudentTeacherLink(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/student_teacher_links/update`, data)
                .then((d) => d.data)
                .then((user) => {
                    if (user.error !== undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                })
                .catch((err) => reject(err));
        });
    },
    deleteStudentTeacherLink(id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/student_teacher_links/delete/:${id}`)
                .then((d) => d.data)
                .then(() => resolve(id))
                .catch((err) => reject(err));
        });
    },
    getStudentTeacherLinkById(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/student_teacher_link/${id}`)
                .then((d) => d.data)
                .then((user) => {
                    if (user.error !== undefined) {
                        return reject(user.error);
                    }
                    resolve(user);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    createStudentTeacherLinkByTeacherEmail(studentId, teacherEmai) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/student_teacher_links/add_teacher_by_email/${studentId}`, {
                    email: teacherEmai,
                })
                .then((d) => d.data)
                .then((link) => {
                    if (link.error !== undefined) {
                        return reject(link.error);
                    }
                    resolve(link);
                })
                .catch((err) => reject(err));
        });
    },
    createStudentTeacherLinkByStudentEmail(teacherId, studentEmail) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/student_teacher_links/add_student_by_email/${teacherId}`, {
                    email: studentEmail,
                })
                .then((d) => d.data)
                .then((link) => {
                    if (link.error !== undefined) {
                        return reject(link.error);
                    }
                    resolve(link);
                })
                .catch((err) => reject(err));
        });
    },
    getTeacherUserLinks(teacherId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/student_teacher_links/teacher/${teacherId}`)
                .then((d) => d.data)
                .then((links) => {
                    resolve(links);
                })
                .catch((err) => reject(err));
        });
    },
};

export default UsersAPI;

/*eslint-enable*/
