import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import styled from 'styled-components';
import imageMakewaveOnlineLogo from '../../assets/images/makewave-online-logo.svg';

import * as usersActions from '../../redux/actions/UsersActions';

import { API_ENDPOINT } from '../../constants/config';
import Form from '../UIKit/Form';
import { Frame, H, Link, P } from '../UIKit/styled-templates';

let SignUpFields = [
    { name: 'Email', type: 'string' },
    { name: 'Password', type: 'password' },
];

const validateEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

const SignUpPage = (props) => {
    const dispatch = useDispatch();

    const [tempData, setTempData] = useState({});

    const onSignUp = () => {
        if (!validateEmail(tempData.email)) {
            window.alert(`Email is not valid`);
            return;
        }
        axios
            .post(`${API_ENDPOINT}/users/create`, { email: tempData.email.toLowerCase(), password: tempData.password })
            .then((d) => d.data)
            .then((pld) => {
                if (pld.error !== undefined) {
                    window.alert(pld.error.message);
                    return;
                }
                console.log(pld);
                dispatch(usersActions.logIn({ email: tempData.email.toLowerCase(), password: tempData.password }));
            });
    };

    return (
        <Wrapper>
            <Workspace>
                <Link to="/">
                    <Logo />
                </Link>
                <FormWrapper>
                    <Title>Create account</Title>
                    {/* <Frame row extra={`width: 540px; justify-content: space-between; margin-top: 15px;`}>
                        <SocialMediaLogin>Continue with Facebook</SocialMediaLogin>
                        <SocialMediaLogin shaped>Continue with Google</SocialMediaLogin>
                    </Frame> */}
                    <SeparationLine />
                    <Form
                        fields={SignUpFields}
                        data={tempData}
                        onChange={(key, value) => {
                            setTempData({ ...tempData, [key]: value });
                        }}
                    />
                    <Frame row>
                        <P>Your personal data shall be processed in accordance with our</P>
                        <P extra={(props) => `color: ${props.theme.blue}; margin-left: 5px; cursor: pointer;`}>Privacy Policy</P>
                    </Frame>
                    <SignUpButton onClick={onSignUp} />
                    <Frame row>
                        <P>Already have an account?</P>
                        <Link to='/login'>
                            <P extra={(props) => `color: ${props.theme.pink}; margin-left: 5px; cursor: pointer;`}>Sign in</P>
                        </Link>
                    </Frame>
                </FormWrapper>
            </Workspace>
        </Wrapper>
    );
};

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`;

const SocialMediaLogin = styled(Frame)`
    width: 255px;
    height: 50px;
    border-radius: 25px;
    background: ${(props) => (props.shaped ? `transparent` : `rgba(255, 255, 255, 0.5)`)};
    border: 1px solid ${(props) => (props.shaped ? `rgba(255, 255, 255, 0.5)` : `transparent`)};
    box-sizing: border-box;
    font-size: 13px;
    cursor: pointer;
`;

const SeparationLine = styled(Frame)`
    width: 540px;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    z-index: 2;
    margin: 25px 0;
    &:after {
        content: 'or';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 12px;
        border-radius: 15px;
        color: ${(props) => props.theme.text.secondary};
        background: ${(props) => props.theme.background.primary};
        position: absolute;
    }
`;

const SignUpButton = styled(Frame)`
    width: 300px;
    height: 70px;
    border-radius: 35px;
    background: ${(props) => props.theme.yellow};
    margin: 15px 0;
    cursor: pointer;
    &:hover {
        transform: scale(1.02);
    }
    &:after {
        content: 'Sign up with email';
        font-size: 17px;
        font-weight: bold;
        color: ${(props) => props.theme.background.primary};
    }
    @media only screen and (max-width: 600px) {
        width: 90vw;
        margin-bottom: 5vw;
    }
`;

const Title = styled(H)`
    font-family: Bold;
    width: 486px;
    justify-content: center;
    text-align: center;
    @media only screen and (max-width: 600px) {
        margin: 10vw 0 4vw 0;
        width: 80vw;
    }
`;

const RememberWrapper = styled(Frame)`
    width: 100%;
    justify-content: space-between;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        width: 90vw;
    }
`;

let Arrow = styled.img.attrs((props) => {
    let img;
    try {
        img = require(`../../assets/images/arrow_${props.color}.svg`);
    } catch (error) {}
    return { src: img };
})`
    width: 16px;
    height: 16px;
    margin-left: 8px;

    @media only screen and (max-width: 600px) {
    }
`;

const FormWrapper = styled(Frame)`
    margin-top: 50px;
    @media only screen and (max-width: 600px) {
        margin-top: 0px;
    }
`;

let Logo = styled(Frame)`
    width: 99px;
    height: 87px;
    background: url('${imageMakewaveOnlineLogo}') center no-repeat;
    margin-top: 50px;
`;

const Header = styled(Frame)`
    width: 730px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    @media only screen and (max-width: 600px) {
        width: 90vw;
        height: auto;
        margin-top: 2vw;
        justify-content: center;
    }
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 100vh;
    justify-content: flex-start;
    position: relative;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 100vh;
    }
`;

export default SignUpPage;
