/*eslint-disable*/
import Spin from 'arui-feather/spin';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';

import Select from 'react-select';

const ACTIVE_OPTIONS = [
    {
        label: 'Active',
        value: true,
    },
    {
        label: 'Not active',
        value: false,
    },
];

export default function UpdateServerForm(props) {
    const { loading = false, onSave = (d) => {} } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [url, setUrl] = useState(props.url == undefined ? '' : props.url);
    const [priority, setPriority] = useState(props.priority == undefined ? '' : props.priority);
    const [active, setActive] = useState(props.active == undefined ? false : props.active);

    return (
        <Wrapper>
            <Field>
                <Label>Name</Label>
                <Input
                    value={name}
                    onChange={(evt) => {
                        setName(evt.target.value);
                    }}
                />
            </Field>

            <Field>
                <Label>Url</Label>
                <Input
                    value={url}
                    onChange={(evt) => {
                        setUrl(evt.target.value);
                    }}
                />
            </Field>

            <Field>
                <Label>Priority</Label>
                <Input
                    value={priority}
                    onChange={(evt) => {
                        setPriority(evt.target.value);
                    }}
                />
            </Field>

            <Field>
                <Label>Active</Label>
                <Select
                    options={ACTIVE_OPTIONS}
                    value={ACTIVE_OPTIONS.filter((a) => a.value == active)}
                    onChange={(a) => {
                        setActive(a.value);
                    }}
                />
            </Field>

            <Field>
                <Spin visible={loading} />
                {loading == true ? null : (
                    <Button
                        onClick={() => {
                            onSave({ name, url, priority, active });
                        }}
                    >
                        Save
                    </Button>
                )}
            </Field>
        </Wrapper>
    );
}

const Wrapper = styled.div``;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

/*eslint-enable*/
