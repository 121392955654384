import axios from 'axios';

const ProjectsAPI = {
    updateProjectSubtitles: async (id, subtitles = []) => {
        const { data: pld } = await axios.post(`/project/update_subtitles`, { id, subtitles });
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },
    getProject: async (id) => {
        const { data: pld } = await axios.get(`/project/${id}`, { params: { with_wave: 1 } });
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    getProjectAwsSubtitles: async (id) => {
        const { data: pld } = await axios.get(`/project/${id}`);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result.awsSubtitles === undefined ? [] : pld.result.awsSubtitles;
    },

    getAllProjects: async () => {
        const { data: pld } = await axios.get(`/projects/all`);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    getUserProjects: async (userId) => {
        const { data: pld } = await axios.get(`/user/${userId}/projects`);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    createProject: async (data) => {
        const { data: pld } = await axios.post(`/project`, data);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    updateProject: async (data) => {
        const { data: pld } = await axios.post(`/project`, data);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    deleteProject: async (id) => {
        const { data: pld } = await axios.post(`/project/delete`, { id });
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },
};

export default ProjectsAPI;
