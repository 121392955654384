/*eslint-disable*/
import Sidebar from 'arui-feather/sidebar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import styled from 'styled-components';
import mvConsts from '../../../constants/mvConsts';
import * as actions from '../../../redux/actions/TemplatesActions';
import UpdateTemplateForm from '../forms/UpdateTemplateForm';

import Button from '../../UIKit/Button';
import { Frame, H1, Text } from '../../UIKit/styled-templates';

export default function TemplatesPanel() {
    const [addVisible, setAddVisible] = useState(false);

    const { loading, templates } = useMappedState(
        useCallback(
            (state) => ({
                templates: state.templates.templatesMap.toArray(),
                loading: state.templates.loading || state.projects.loading,
                currentUser: state.users.usersMap.get(state.users.currentUserId),
                currentUserId: state.users.currentUserId,
            }),
            []
        )
    );
    const dispatch = useDispatch();

    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        dispatch(actions.loadAllTemplates());
    }, []);

    const selectedTemplate = selectedId === undefined ? undefined : templates.filter((a) => a.id === selectedId)[0];

    let [] = useState(false);

    return (
        <Wrapper>
            <TopPlaceholder>
                <Button
                    background={mvConsts.theme.green}
                    shaped
                    onClick={() => {
                        setAddVisible(true);
                    }}
                >
                    Add template
                </Button>
            </TopPlaceholder>

            <TemplatesItems>
                {[`wave`, `progress`, `shape`].map((store, store_index) => {
                    return (
                        <Frame key={store_index} extra={`align-items: flex-start;`}>
                            <H1>{store ? store[0].toUpperCase() + store.substring(1, store.length) : null}</H1>
                            <Frame
                                extra={`width: 100%; flex-wrap: wrap; flex-direction: row; align-items: flex-start; justify-content: flex-start; `}
                            >
                                {templates
                                    .filter((i) => i.type === store)
                                    .map((item) => {
                                        return (
                                            <Frame
                                                key={item.id}
                                                onClick={() => {
                                                    setSelectedId(item.id);
                                                }}
                                                extra={`width: 15vw; height: 15vw; border-radius: 0.75vw; margin: 1vw; border: 0.1vw solid rgba(255, 255, 255, 0.6); &:hover { background: rgba(255, 255, 255, 0.5); > * { transition: 0.5s; transform: scale(1.1); }; }; cursor: pointer; position: relative;`}
                                            >
                                                {store ? <Canvas id={item.id} /> : null}
                                                <Text text_color={`white`} extra={`position: absolute; bottom: 1vw;`}>
                                                    {item.name}
                                                </Text>
                                            </Frame>
                                        );
                                    })}
                            </Frame>
                        </Frame>
                    );
                })}
            </TemplatesItems>

            <Sidebar
                width={Math.min(920, window.innerWidth)}
                visible={selectedTemplate !== undefined}
                onCloserClick={() => {
                    setSelectedId(undefined);
                }}
            >
                {selectedTemplate === undefined ? null : (
                    <div>
                        <UpdateTemplateForm
                            template={selectedTemplate}
                            loading={loading}
                            onSave={async (d) => {
                                await dispatch(actions.updateTemplate({ ...d, id: selectedTemplate.id }));
                                setSelectedId(undefined);
                            }}
                            onDelete={async (d) => {
                                await dispatch(actions.deleteTemplate(d));
                                setAddVisible(false);
                            }}
                        />
                    </div>
                )}
            </Sidebar>

            <Sidebar
                width={Math.min(920, window.innerWidth)}
                visible={addVisible}
                onCloserClick={() => {
                    setAddVisible(false);
                }}
            >
                {addVisible === false ? null : (
                    <div>
                        <UpdateTemplateForm
                            loading={loading}
                            onSave={async (d) => {
                                await dispatch(actions.createTemplate(d));
                                setAddVisible(false);
                            }}
                        />
                    </div>
                )}
            </Sidebar>
        </Wrapper>
    );
}

const Canvas = styled.canvas`
    width: 10vw;
    height: 10vw;
`;

const Wrapper = styled.div``;

const TemplatesItems = styled.div`
    display: block;
`;

const TopPlaceholder = styled.div`
    margin-bottom: 20px;
`;

/*eslint-enable*/
