/*eslint-disable*/
import Spin from 'arui-feather/spin';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { API_ENDPOINT } from '../../../constants/config';

import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { Textarea } from '../../ui/Textarea';

export default function UpdateConfigInputTool(props) {
    const {
        mode = 'input',
        paramName = 'sabir',
        type = 'string', //or number
    } = props;
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');

    useEffect(() => {
        axios
            .get(`${API_ENDPOINT}/dictionary/${paramName}`)
            .then((d) => d.data.result.value)
            .then((s) => {
                setValue(s);
                setLoading(false);
            });
    }, [paramName]);

    let isTextarea = mode == 'textarea';

    return (
        <Wrapper>
            <InputPlaceholder>
                {isTextarea == true ? null : (
                    <Input
                        placeholder={`Please, provide ${type}`}
                        value={value}
                        onChange={(evt) => {
                            setValue(evt.target.value);
                        }}
                    />
                )}

                {isTextarea == false ? null : (
                    <Textarea
                        placeholder={`Please, provide ${type}`}
                        style={{ minHeight: '32em' }}
                        value={value}
                        onChange={(evt) => {
                            setValue(evt.target.value);
                        }}
                    />
                )}
            </InputPlaceholder>

            <Button
                disabled={loading}
                onClick={async () => {
                    setLoading(true);
                    await axios.put(`${API_ENDPOINT}/dictionary/${paramName}`, { value: type == 'number' ? +value : `${value}` });
                    setLoading(false);
                }}
            >
                <Spin visible={loading} />
                {loading == true ? null : 'Save'}
            </Button>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
`;

const InputPlaceholder = styled.div`
    margin-right: 15px;
    flex: 1;
`;

/*eslint-enable*/
