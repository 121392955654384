/*eslint-disable*/
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Frame, H1, H, P, Link } from '../../UIKit/styled-templates'

let Third = (props) => {

    return (
        <Content>
            <Title>
                <Frame>MakeWave helps you to</Frame>
                <Frame>promote your audio</Frame>
            </Title>
            <LongText>
                If you are a musician, podcast creator or produce any other audios then you can easyly share your audio very effectively on any social media platforms like Instagram, Facebook, YouTube, etc.
            </LongText>
            <Grid>
                <Frame><Square /><Frame>Sound animations</Frame></Frame>
                <Frame><Square /><Frame>Progress animations</Frame></Frame>
                <Frame><Square /><Frame>Fast rendering</Frame></Frame>
                <Frame><Square /><Frame>Drag&Drop Editor</Frame></Frame>
                <Frame><Square /><Frame>Hundreds of templates</Frame></Frame>
                <Frame><Square /><Frame>Any social media</Frame></Frame>
            </Grid>
        </Content>
    )
}

const Square = styled(Frame)`
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    margin-right: 15px;
`;

const Grid = styled(Frame)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    > * {
        flex-direction: row;
        width: 350px;
        margin-bottom: 60px;
        justify-content: flex-start;
        > * {
            &:nth-child(2) {
                width: 255px;
                font-size: 32px;
                font-weight: bold;
            }
        }
    }
`;

const LongText = styled(Frame)`
    width: 1060px;
    font-size: 36px;
    margin: 50px 0;
`;

const Title = styled(Frame)`
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    > * {
        font-family: Bold;
        font-size: 80px;
        line-height: 120px;
        &:nth-child(2) {
            /* color: ${props => props.theme.pink}; */
            background: -webkit-linear-gradient(#F1D025, #FF3D45);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    min-height: calc(100vh - 80px - 50px);
    justify-content: flex-start;
`;

export default Third;
/*eslint-enable*/