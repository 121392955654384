import axios from 'axios';

const TariffsApi = {
    getTariffs: async () => {
        const { data } = await axios.get('/tariffs');
        return data;
    },
    createCheckoutToken: async (tariffId) => {
        const { data } = await axios.get('/stripe/checkout-token', { params: { tariffId } });
        return data;
    },
};

export default TariffsApi;
