/*eslint-disable*/
import { Map, Stack, Set } from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,
    loaded: false,

    templatesMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
}

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error }
}

const TemplatesReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_WAVE_TEMPLATE:
        case types.UPDATE_WAVE_TEMPLATE:
        case types.DELETE_WAVE_TEMPLATE:
        case types.LOAD_WAVE_TEMPLATES:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: undefined
            }

        case types.CREATE_WAVE_TEMPLATE_FAIL:
        case types.UPDATE_WAVE_TEMPLATE_FAIL:
        case types.DELETE_WAVE_TEMPLATE_FAIL:
        case types.LOAD_WAVE_TEMPLATES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_WAVE_TEMPLATE_SUCCESS:
        case types.UPDATE_WAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                templatesMap: state.templatesMap.set(action.template.id, action.template),
                loaded: true,
            }


        case types.DELETE_WAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                templatesMap: state.templatesMap.delete(action.id),
            }


        case types.LOAD_WAVE_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                templatesMap: state.templatesMap
                    .merge(action.templates.reduce((map, form) => map.set(form.id, form), Map())),
                loaded: true
            }


        default:
            return state;
    }

}

export default TemplatesReducer;

/*eslint-enable*/