import { useCallback } from 'react';
import { useMappedState } from 'redux-react-hook';

import * as usersActions from '../../redux/actions/UsersActions';
import store from '../../redux/store';

let useCurrentUser = () => {
    return useMappedState(
        useCallback((state) => {
            let currentUser = state.users.currentUserId ? state.users.usersMap.get(state.users.currentUserId) : { userRole: `guest` };
            let userRole = state.users.currentUserId ? state.users.usersMap.get(state.users.currentUserId).userRole : `guest`;
            // let dict = Object.assign({}, ...state.dictionary.dictionaryMap.toArray().map(i => i.words))
            return {
                currentUser,
                userRole,
                isAdmin: userRole === `admin`,
                logOut: () => {
                    store.dispatch(usersActions.logOut());
                },
                usersProjects: state.projects.projectsMap
                    .filter((i) => (i.applications || []).map((j) => j.userId).indexOf(state.users.currentUserId) > -1)
                    .toArray()
                    .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
                    .sort((a, b) => +(b.pinned === true) - +(a.pinned === true)),
                updateUser: async (info) =>
                    state.users.currentUserId
                        ? store.dispatch(usersActions.updateUser({ info: { ...info }, id: state.users.currentUserId }))
                        : () => {},
            };
        }, [])
    );
};

export default useCurrentUser;
