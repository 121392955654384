/*eslint-disable*/
export const BROWN = '#AC7D59';
export const LIGHT_BROWN = '#d49e7a';

export const GREEN = '#20bf6b';
export const RED = '#fc5c65';

export const DARK_SIDE = '#444951';
// export const MAIN_ORANGE_COLOR = '#E9822C';
export const MAIN_ORANGE_COLOR = '#0E1418';



/*eslint-enable*/