import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import { useDispatch } from 'redux-react-hook';
import styled from 'styled-components';
// import {ReactSortable} from "react-sortablejs";
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce';
import uuid from 'uuid';
import imageDoubleBackArrow from '../../../assets/images/double-back-arrow.svg';
import imageDragAnchorW from '../../../assets/images/drag_anchor_w.svg';
import imageLayersButton from '../../../assets/images/layers-button.svg';
import imageMakewaveOnlineLogo from '../../../assets/images/makewave-online-logo.svg';
import imageVolumeMuted from '../../../assets/images/volume-muted.svg';

import * as projectsActions from '../../../redux/actions/ProjectsActions';

import { API_ENDPOINT } from '../../../constants/config';
import mvConsts, { FontList } from '../../../constants/mvConsts';
import ReactHelper from '../../../helpers/ReactHelper';
import { Frame } from '../../UIKit/styled-templates';
import { draw } from '../helpers/DrawHelper';
import Parameters from './Parameters';
import Store from './store';

import { useHistory } from 'react-router-dom';
import useComponentSize from '../../helpers/useComponentSize';
import useCustomDispatch, { customHandler } from '../../helpers/useCustomDispatch';
import Sortable from './sortable';

let shouldPlayAgain = false;

let convert = (value) => (typeof value === `string` ? +value.split(`px`)[0] : value);

let getAmp = (points, t) => {
    if (t === undefined || points === undefined || points.length === 0) {
        return 0;
    }
    let frameNumber = Math.min(points.length - 1, Math.floor(t * 30));
    return points[frameNumber];
};

function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}

let canDragOutside = false;

const DIMENSIONS_OPTIONS = [
    {
        label: `Instagram`,
        value: `1080x1080`,
    },
    {
        label: `Facebook`,
        value: `940x788`,
    },
    {
        label: `Full video`,
        value: `1920x1080`,
    },
    {
        label: `Story`,
        value: `1080x1920`,
    },
    // {
    //     label: `Custom`,
    //     value: `1000x1000`,
    // },
];

const RESOLUTION_OPTIONS = [
    {
        label: `Use original quality`,
        value: 1 / 1,
    },
    {
        label: `1 / 2`,
        value: 1 / 2,
    },
    {
        label: `1 / 4`,
        value: 1 / 4,
    },
    {
        label: `1 / 8`,
        value: 1 / 8,
    },
    {
        label: `1 / 16`,
        value: 1 / 16,
    },
    {
        label: `1 / 32`,
        value: 1 / 32,
    },
];

const SPEED_OPTIONS = [
    {
        label: `30 FPS`,
        value: 30,
    },
    {
        label: `15 FPS`,
        value: 15,
    },
    {
        label: `10 FPS`,
        value: 10,
    },
    {
        label: `5 FPS`,
        value: 5,
    },
    {
        label: `2 FPS`,
        value: 2,
    },
    {
        label: `1 FPS`,
        value: 1,
    },
];

let activeHistoryId = uuid();
let lastSavedId = activeHistoryId;

let useKeyHandler = (key, handlerFunction) => {
    useEffect(() => {
        let handler = (e) => {
            if (document.activeElement.tagName.toLowerCase() !== `input`) {
                if (e.code === key) {
                    e.preventDefault();
                    handlerFunction();
                }
            }
        };
        window.addEventListener(`keydown`, handler);
        return () => {
            window.removeEventListener(`keydown`, handler);
        };
    });
};

const Editor = (props) => {
    const { push } = useHistory();
    let dispatch = useDispatch();
    let wrapperRef = useRef();
    let wrapperSize = useComponentSize(wrapperRef);
    let waveformRef = useRef();
    let menuPointerRef = useRef();
    let waveformSize = useComponentSize(waveformRef);

    let [upd, setUpd] = useState(+new Date());

    let { project } = props;
    let layersRef = useRef();
    let [layers, setLayers] = useState(project.layers);

    let [jobs, setJobs] = useState([]);
    let is_rendering = jobs.filter((i) => i.status !== `finished`).length > 0;
    useEffect(() => {
        axios
            .get(`${API_ENDPOINT}/project/${project.id}/jobs`)
            .then((d) => d.data.result)
            .then((arr) => {
                setJobs(arr);
            });
    }, [project.id]);

    const onCreateJob = async () => {
        if (!is_rendering) {
            await axios.post(`${API_ENDPOINT}/project/${project.id}/create_job`);
        }
        push(`/project/${project.id}/view`);
    };

    useCustomDispatch(`ADD_LAYER`, (e) => {
        setLayers([...layers, e.detail]);
        setSelectedLayerId(e.detail.id);
    });
    useCustomDispatch(`CLOSE_STORE`, (e) => {
        setStoreVisible(undefined);
    });
    useCustomDispatch(`OPEN_STORE`, (e) => {
        setSelectedLayerId(undefined);
        setStoreVisible(e.detail);
    });
    useCustomDispatch(`UNSELECT_LAYER`, (e) => {
        setSelectedLayerId(undefined);
    });
    useCustomDispatch(`APPLY_PRESET`, (e) => {
        setLayers(e.detail.layers);
        setDimensions(e.detail.dimensions);
    });

    let history = useRef([{ id: activeHistoryId, project }]);

    // Clear history after saving
    // useEffect(() => { history.current = [{ id: activeHistoryId, project }] }, [project])

    useEffect(() => {
        if (!shallowEqual(history.current.get(activeHistoryId).project.layers, layers)) {
            let new_id = uuid();
            history.current = [
                ...history.current.filter((a, b) => b <= history.current.map((i) => i.id).indexOf(activeHistoryId)),
                {
                    id: new_id,
                    project: { ...history.current.get(activeHistoryId).project, layers },
                },
            ];
            activeHistoryId = new_id;
            setUpd(+new Date());
        }
    }, [layers]);

    let undo = () => {
        let prevId = history.current.map((i) => i.id)[Math.max(0, history.current.map((i) => i.id).indexOf(activeHistoryId) - 1)];
        activeHistoryId = prevId;
        setLayers(history.current.get(prevId).project.layers);
    };
    let redo = () => {
        let nextId = history.current.map((i) => i.id)[
            Math.min(history.current.length - 1, history.current.map((i) => i.id).indexOf(activeHistoryId) + 1)
        ];
        activeHistoryId = nextId;
        setLayers(history.current.get(nextId).project.layers);
    };
    let setDimensions = (dimensions) => {
        let new_id = uuid();
        let old_project = { ...history.current.get(activeHistoryId).project };
        let old_ratio = old_project.dimensions.split(`x`)[0] / old_project.dimensions.split(`x`)[1];
        let new_ratio = dimensions.split(`x`)[0] / dimensions.split(`x`)[1];
        old_project.layers = (old_project.layers || []).map((i) =>
            i.type === `image`
                ? {
                      ...i,
                      height_p: (i.height_p / old_ratio) * new_ratio,
                  }
                : i
        );
        history.current = [...history.current, { id: new_id, project: { ...old_project, dimensions } }];
        activeHistoryId = new_id;
        // setUpd(+new Date())
        setLayers(old_project.layers);
    };

    let { dimensions } = history.current.get(activeHistoryId).project;
    let aspect_ratio = dimensions.split(`x`).reduce((a, b) => +b / +a);
    let editorCanvasScale = 0.8;
    let canvasWrapperSize = {
        width: (wrapperSize.height / aspect_ratio) * editorCanvasScale,
        height: wrapperSize.height * editorCanvasScale,
    };

    let [layersVisible, setLayersVisible] = useState(localStorage.getItem(`layersVisible`) == `true`);
    let [storeVisible, setStoreVisible] = useState(undefined);
    let [selectedLayerId, setSelectedLayerId] = useState(undefined);
    let [volumeVisible, setVolumeVisible] = useState(false);
    let [dimensionsVisible, setDimensionsVisible] = useState(false);
    let [resolutionVisible, setResolutionVisible] = useState(false);
    let [trimmerVisible, setTrimmerVisible] = useState(false);

    let selectedLayerType = { type: undefined, ...layers.get(selectedLayerId) }.type;

    useEffect(() => {
        if (storeVisible || selectedLayerType) {
            let selectedIndex = 0;
            switch (storeVisible || selectedLayerType) {
                case `template`:
                    selectedIndex = 0;
                    break;
                case `wave`:
                    selectedIndex = 1;
                    break;
                case `progress`:
                    selectedIndex = 2;
                    break;
                case `image`:
                case `images`:
                    selectedIndex = 3;
                    break;
                case `text`:
                    selectedIndex = 4;
                    break;
            }
            menuPointerRef.current.style.transform = `translateY(${85 * selectedIndex}px)`;
            menuPointerRef.current.style.opacity = 1;
        } else {
            menuPointerRef.current.style.opacity = 0;
        }
    }, [storeVisible, selectedLayerId, selectedLayerType]);

    useEffect(() => {
        localStorage.setItem(`layersVisible`, layersVisible);
    });

    let storeRef = useRef();
    ReactHelper.useOnClickOutside(storeRef, (e) => {
        if (e.target.id !== `menu`) {
            setStoreVisible(undefined);
        }
    });
    let volumWindowRef = useRef();
    ReactHelper.useOnClickOutside(volumWindowRef, () => {
        setVolumeVisible(false);
    });
    let dimensionsWindowRef = useRef();
    ReactHelper.useOnClickOutside(dimensionsWindowRef, () => {
        setDimensionsVisible(false);
    });
    let resolutionWindowRef = useRef();
    ReactHelper.useOnClickOutside(resolutionWindowRef, () => {
        setResolutionVisible(false);
    });
    let trimmerRef = useRef();
    ReactHelper.useOnClickOutside(trimmerRef, (e) => {
        if (e.target.id !== `playPauseToggle`) {
            setTrimmerVisible(false);
        }
    });

    useEffect(() => {
        let handler = (e) => {
            if (e.target.className === wrapperRef.current.className || e.target.id.indexOf(`layer`) > -1) {
                setSelectedLayerId(undefined);
            }
        };
        wrapperRef.current.addEventListener(`click`, handler);
        return () => {
            wrapperRef.current.removeEventListener(`click`, handler);
        };
    }, [wrapperRef]);

    let shiftLayersWindow = () => {
        setLayersVisible(!layersVisible);
    };
    let openStoreWindow = (store) => {
        customHandler(`OPEN_STORE`, store);
    };
    let onSaveProject = async () => {
        await dispatch(projectsActions.updateProject(history.current.get(activeHistoryId).project));
        lastSavedId = activeHistoryId;
        setUpd(+new Date());
        return;
    };
    let playPauseToggle = () => {
        let audio = document.getElementById(`audioRef`);
        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
        }
    };
    let muted = document.getElementById(`audioRef`) ? document.getElementById(`audioRef`).muted : false;
    let muteToggle = () => {
        let audio = document.getElementById(`audioRef`);
        audio.muted = !audio.muted;
        setUpd(+new Date());
    };
    let volumeHandler = (e) => {
        let audio = document.getElementById(`audioRef`);
        audio.muted = false;
        audio.volume = e.target.value / 100;
        localStorage.setItem(`volume`, e.target.value / 100);
    };

    let volume = localStorage.getItem(`volume`) * 100 || 50;
    // try { volume = document.getElementById(`audioRef`).volume * 100 } catch { }
    // useEffect(() => {
    //     if (document.getElementById(`audioRef`)) {
    //         document.getElementById(`audioRef`).volume = localStorage.getItem(`volume`)
    //     }
    // }, [document.getElementById(`audioRef`)])

    let renderCanvas = useCallback((optimised = false) => {
        let audio = document.getElementById(`audioRef`);
        if (!audio) {
            return;
        }
        let currentTime = audio.currentTime;
        if (history.current.get(activeHistoryId) === undefined) {
            return;
        }
        // console.log(history.current.map(i => i.id), activeHistoryId);
        let { project } = history.current.get(activeHistoryId);
        // console.log(`Render project frame(t = ${currentTime}): `, project);
        let amp_ratio = getAmp(project.wavePoints, currentTime) / Math.max.apply(null, project.wavePoints);
        layersRef.current.forEach((layer, index) => {
            let canvas = document.getElementById(`layer_${index}`);
            if (!canvas) {
                return;
            }
            let ctx = canvas.getContext('2d');
            // ctx.canvas.width = canvasWrapperSize.width * (audio.paused ? 1 : resolutionDrop)
            // ctx.canvas.height = canvasWrapperSize.height * (audio.paused ? 1 : resolutionDrop)
            ctx.canvas.width = canvasWrapperSize.width;
            ctx.canvas.height = canvasWrapperSize.height;
            draw(ctx, amp_ratio, currentTime, { ...project, layers: [layer] });
        });
    });

    let moveLayerUp = () => {
        if (selectedLayerId !== undefined) {
            let index = layers.map((i) => i.id).indexOf(selectedLayerId);
            setLayers([...layers].swap(index, index - 1));
        }
    };
    let moveLayerDown = () => {
        if (selectedLayerId !== undefined) {
            let index = layers.map((i) => i.id).indexOf(selectedLayerId);
            setLayers([...layers].swap(index, index + 1));
        }
    };
    let dublicateLayer = () => {
        if (selectedLayerId !== undefined) {
            let index = layers.map((i) => i.id).indexOf(selectedLayerId);
            let new_id = uuid();
            setLayers(
                [...layers].insert(index + 1, {
                    ...layers[index],
                    left: layers[index].left + 0.01,
                    top: layers[index].top + 0.01,
                    id: new_id,
                })
            );
            setSelectedLayerId(new_id);
        }
    };
    let deleteLayer = () => {
        if (selectedLayerId !== undefined) {
            let index = layers.map((i) => i.id).indexOf(selectedLayerId);
            setSelectedLayerId(undefined);
            setLayers([...layers].remove(index));
        }
    };

    useEffect(() => {
        layersRef.current = layers.map((i) => {
            delete i.chosen;
            return i;
        });
    }, [layers]);

    useEffect(() => {
        renderCanvas();
    }, [canvasWrapperSize, renderCanvas]);

    // 18.01.2021 Cut preview settings from the project. FPS and resolution are hardcoded
    let FPS = 30;
    let resolutionDrop = 1;
    // let [FPS, setFPS] = useState(+localStorage.getItem(`FPS`) || SPEED_OPTIONS[0].value)
    // let [resolutionDrop, setResolutionDrop] = useState(+localStorage.getItem(`resolutionDrop`) || RESOLUTION_OPTIONS[0].value)

    /* < - - - - - - - - - - - - - - - - - - - - - > */
    // Dog nail. Can't find a problem of dropping FPS when changing dimensions so I'm stopping and playing again if it's playing
    useEffect(() => {
        shouldPlayAgain = !document.getElementById(`audioRef`).paused;
        document.getElementById(`audioRef`).pause();
        localStorage.setItem(`FPS`, FPS);
        localStorage.setItem(`resolutionDrop`, resolutionDrop);
        if (shouldPlayAgain) {
            document.getElementById(`audioRef`).play();
        }
    }, [FPS, resolutionDrop, dimensions]);
    /* < - - - - - - - - - - - - - - - - - - - - - > */

    const interval = useRef();
    useEffect(() => {
        let audio = document.getElementById(`audioRef`);
        let handler = () => {
            clearInterval(interval.current);
            if (!audio.paused) {
                interval.current = setInterval(() => {
                    renderCanvas();
                }, 1000 / FPS);
            }
        };
        audio.addEventListener(`play`, handler);
        audio.addEventListener(`pause`, handler);
        return () => {
            clearInterval(interval.current);
            audio.removeEventListener(`play`, handler);
            audio.removeEventListener(`pause`, handler);
        };
    }, [FPS, renderCanvas]);

    // let trim = { from: 0, to: 1, ...history.current.get(activeHistoryId).project.trim }
    //

    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(1);

    const [trim, set_trim] = useState({ from, to });

    useEffect(() => {
        set_trim({ from, to });
        // set_trim({ ...history.current.get(activeHistoryId).project.trim })
    }, [from, to]);

    let setTrim = (from, to) => {
        setFrom(from);
        setTo(to);
        let new_id = uuid();
        history.current = [
            ...history.current,
            {
                id: new_id,
                project: { ...history.current.get(activeHistoryId).project, trim: { from, to } },
            },
        ];
        activeHistoryId = new_id;
    };

    let [time_p, setTime_p] = useState(trim.from);

    let audio = document.getElementById(`audioRef`);
    let paused = audio ? audio.paused : true;

    useEffect(() => {
        let audio = document.getElementById(`audioRef`);
        let handler = (e) => {
            setTime_p(audio.currentTime / audio.duration);
        };
        audio.addEventListener(`timeupdate`, handler);
        return () => {
            audio.removeEventListener(`timeupdate`, handler);
        };
    }, [document.getElementById(`audioRef`)]);

    useEffect(() => {
        let audio = document.getElementById(`audioRef`);
        if (time_p < trim.from) {
            audio.currentTime = trim.from * audio.duration;
            return;
        }
        if (time_p > trim.to) {
            audio.currentTime = trim.from * audio.duration;
            audio.pause();
            return;
        }
    }, [time_p, trim.from, trim.to]);

    useEffect(() => {
        let canvas = trimmerRef.current.childNodes[0];
        let ctx = canvas.getContext('2d');
        let n = 100;
        ctx.strokeStyle = mvConsts.theme.yellow;
        ctx.lineWidth = 1;
        new Array(n).fill(0).forEach((i, j) => {
            project.wavePoints = project.wavePoints || [];
            let amp = project.wavePoints[Math.round((j / n) * project.wavePoints.length)] / Math.max.apply(null, project.wavePoints);
            ctx.moveTo((300 * j) / n, 55 + 20 + (55 * amp) / 2 + 1);
            ctx.lineTo((300 * j) / n, 55 + 20 - (55 * amp) / 2 - 1);
            ctx.stroke();
        });
    }, [project.wavePoints, props, trimmerRef]);

    useEffect(() => {
        if (selectedLayerId !== undefined) {
            setStoreVisible(undefined);
            setDimensionsVisible(false);
            setResolutionVisible(false);
        }
    }, [selectedLayerId]);
    useEffect(() => {
        if (storeVisible !== undefined) {
            setSelectedLayerId(undefined);
            setDimensionsVisible(false);
            setResolutionVisible(false);
        }
    }, [storeVisible]);
    useEffect(() => {
        if (dimensionsVisible === true) {
            setStoreVisible(undefined);
            setSelectedLayerId(undefined);
            setResolutionVisible(false);
        }
    }, [dimensionsVisible]);
    useEffect(() => {
        if (resolutionVisible === true) {
            setStoreVisible(undefined);
            setSelectedLayerId(undefined);
            setDimensionsVisible(false);
        }
    }, [resolutionVisible]);

    useCustomDispatch(`ON_PARAM_CHANGE`, (e) => {
        setLayers(layersRef.current.map((i) => (i.id === selectedLayerId ? { ...i, ...e.detail } : i)));
    });
    let onParamChange = (key, value) => {
        customHandler(`ON_PARAM_CHANGE`, { [key]: value });
    };

    // Debounce save project
    const [debouncedSaveProject] = useDebouncedCallback(
        () => {
            onSaveProject();
        },
        2000,
        []
    );
    useEffect(() => {
        debouncedSaveProject();
    }, [debouncedSaveProject]);

    // Key press handler
    useKeyHandler(`Backspace`, () => {
        if (selectedLayerId !== undefined) {
            deleteLayer();
        }
    });
    useKeyHandler(`Space`, () => {
        playPauseToggle();
    });
    useKeyHandler(`Escape`, () => {
        setSelectedLayerId(undefined);
    });
    useKeyHandler(`ArrowRight`, () => {
        if (selectedLayerId !== undefined) {
            onParamChange(`left`, layersRef.current.get(selectedLayerId).left + 0.01);
        }
    });
    useKeyHandler(`ArrowLeft`, () => {
        if (selectedLayerId !== undefined) {
            onParamChange(`left`, layersRef.current.get(selectedLayerId).left - 0.01);
        }
    });
    useKeyHandler(`ArrowUp`, () => {
        if (selectedLayerId !== undefined) {
            onParamChange(`top`, layersRef.current.get(selectedLayerId).top - 0.01);
        }
    });
    useKeyHandler(`ArrowDown`, () => {
        if (selectedLayerId !== undefined) {
            onParamChange(`top`, layersRef.current.get(selectedLayerId).top + 0.01);
        }
    });
    useKeyHandler(`Equal`, () => {
        if (selectedLayerId !== undefined) {
            setLayers(
                layersRef.current.map((i) =>
                    i.id === selectedLayerId
                        ? {
                              ...i,
                              top: i.top - 0.01,
                              left: i.left - 0.01,
                              height_p: i.height_p + 0.02,
                              width_p: i.width_p + 0.02,
                          }
                        : i
                )
            );
        }
    });
    useKeyHandler(`Minus`, () => {
        if (selectedLayerId !== undefined) {
            setLayers(
                layersRef.current.map((i) =>
                    i.id === selectedLayerId
                        ? {
                              ...i,
                              top: i.top + 0.01,
                              left: i.left + 0.01,
                              height_p: i.height_p - 0.02,
                              width_p: i.width_p - 0.02,
                          }
                        : i
                )
            );
        }
    });

    return (
        <Wrapper>
            <Audio src={project.audioUrl} id={`audioRef`} />
            <LayersButton onClick={shiftLayersWindow} />
            <StoreWindow ref={storeRef} visible={storeVisible !== undefined}>
                <Store store={storeVisible} dimensions={dimensions} />
            </StoreWindow>
            <ParametersWindow visible={selectedLayerId !== undefined}>
                <Parameters selectedLayer={layers.get(selectedLayerId)} dimensions={dimensions} />
            </ParametersWindow>
            <TrimmerWindow visible={trimmerVisible} ref={trimmerRef}>
                <WaveformCanvas />
                <Waveform ref={waveformRef}>
                    <Rnd
                        disableDragging={false}
                        size={{ width: (trim.to - trim.from) * waveformSize.width, height: 110 }}
                        position={{ x: trim.from * waveformSize.width, y: 0 }}
                        bounds={`parent`}
                        style={{
                            border: `1px solid ${mvConsts.theme.green}`,
                            borderRadius: `10px`,
                            boxShadow: `inset 0 0 10px ${mvConsts.theme.green}`,
                        }}
                        maxWidth={waveformSize.width}
                        enableResizing={{ left: true, right: true }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                            setTrim(position.x / waveformSize.width, position.x / waveformSize.width + convert(ref.style.width) / waveformSize.width);
                        }}
                        onDragStop={(e, d) => {
                            setTrim(d.x / waveformSize.width, trim.to - trim.from + d.x / waveformSize.width);
                        }}
                        resizeHandleComponent={{
                            right: (
                                <Frame
                                    extra={(props) =>
                                        `width: 10px; height: 10px; border-radius: 50%; margin-top: 50px; margin-right: 5px; background: ${props.theme.green};`
                                    }
                                ></Frame>
                            ),
                            left: (
                                <Frame
                                    extra={(props) =>
                                        `width: 10px; height: 10px; border-radius: 50%; margin-top: 50px; margin-left: 0; background: ${props.theme.green};`
                                    }
                                ></Frame>
                            ),
                        }}
                    />
                    <Rnd
                        disableDragging={false}
                        size={{ width: 10, height: 120 }}
                        position={{ x: waveformSize.width * time_p, y: -10 }}
                        bounds={`parent`}
                        maxWidth={10}
                        enableResizing={false}
                        onDragStart={() => {
                            shouldPlayAgain = !document.getElementById(`audioRef`).paused;
                            document.getElementById(`audioRef`).pause();
                        }}
                        onDragStop={(e, d) => {
                            let audio = document.getElementById(`audioRef`);
                            audio.currentTime = (audio.duration * d.x) / waveformSize.width;
                            if (shouldPlayAgain) {
                                audio.play();
                            }
                        }}
                    >
                        <Frame extra={`width: 10px; height: 100%;`}>
                            <Frame extra={`width: 1px; height: 100%; background: red; box-shadow: 0 0 10px white;`} />
                        </Frame>
                    </Rnd>
                </Waveform>
            </TrimmerWindow>
            {/* <ResolutionWindow ref={resolutionWindowRef} visible={resolutionVisible} >
                <Frame extra={`margin: 0.5vw; font-weight: bold;`} bold size={1.5} >Pre-render settings</Frame>
                <Frame row >
                    <Frame>
                        <Frame extra={`width: 90%; align-items: flex-start; margin-bottom: 5px; font-size: 14px;`} bold size={1} >Quality</Frame>
                        {
                            RESOLUTION_OPTIONS.map((item, index) => <ResolutionOption
                                key={index}
                                selected={item.value === resolutionDrop}
                                content={item.label}
                                onClick={() => { setResolutionDrop(item.value) }}
                            />)
                        }
                    </Frame>
                    <Frame extra={`margin-left: 5px;`} >
                        <Frame extra={`width: 90%; align-items: flex-start; margin-bottom: 5px; font-size: 14px;`} bold size={1} >FPS</Frame>
                        {
                            SPEED_OPTIONS.map((item, index) => <ResolutionOption
                                key={index}
                                selected={item.value === FPS}
                                content={item.label}
                                onClick={() => { setFPS(item.value) }}
                            />)
                        }
                    </Frame>
                </Frame>
            </ResolutionWindow> */}
            <DimensionsWindow ref={dimensionsWindowRef} visible={dimensionsVisible}>
                {DIMENSIONS_OPTIONS.map((item, index) => (
                    <ResolutionOption
                        key={index}
                        selected={item.value === dimensions}
                        content={item.label}
                        before={item.label}
                        after={item.value}
                        onClick={() => {
                            setDimensions(item.value);
                        }}
                    />
                ))}
            </DimensionsWindow>
            <LayersWindow visible={layersVisible}>
                <Title
                    content={`Layers`}
                    onClick={() => {
                        setLayersVisible(false);
                    }}
                />
                <Sortable
                    values={layers}
                    onSortEnd={setLayers}
                    anchorClassName="sortable-anchor"
                    component={(item, index) => {
                        const selected = selectedLayerId === item.id;
                        return (
                            <LayerWrapper
                                key={index}
                                selected={selected}
                                onClick={() => {
                                    setSelectedLayerId(item.id);
                                }}
                            >
                                <div></div>
                                <div>{item.type}</div>
                                <div className="sortable-anchor"></div>
                            </LayerWrapper>
                        );
                    }}
                />

                {/* <ReactSortable list={layers} setList={(d) => {
                    d.forEach(i => {
                        delete i.chosen
                    })
                    if (shallowEqual(d.map(a => a.id), layers.map(a => a.id))) {
                        return;
                    }
                    setLayers(d)
                }}>
                    {
                        layers.map((item, index) => {
                            let selected = selectedLayerId === item.id
                            return <LayerWrapper key={index} selected={selected} onClick={() => {
                                setSelectedLayerId(item.id)
                            }}>
                                <div></div>
                                <div>{item.type}</div>
                                <div></div>
                            </LayerWrapper>
                        })
                    }
                </ReactSortable> */}
            </LayersWindow>
            <VolumeWindow visible={volumeVisible} ref={volumWindowRef}>
                <MuteButton muted={muted} onClick={muteToggle} />
                <VolumeRange onChange={volumeHandler} defaultValue={volume} />
            </VolumeWindow>
            <Top>
                <Logo
                    onClick={async () => {
                        await onSaveProject();
                        push(`/workspace`);
                    }}
                />
                <ProjectControls>
                    <ControlItem icon={`undo-button`} active={history.current.map((i) => i.id).indexOf(activeHistoryId) > 0} onClick={undo} />
                    <ControlItem
                        icon={`redo-button`}
                        active={history.current.map((i) => i.id).indexOf(activeHistoryId) < history.current.length - 1}
                        onClick={redo}
                    />
                    {/* <ControlItem icon={`resolution-button`} active={false} onClick={() => { setResolutionVisible(true) }} /> */}
                    <ControlItem
                        icon={`resize-button`}
                        active={true}
                        onClick={() => {
                            setDimensionsVisible(true);
                        }}
                    />
                </ProjectControls>
                <ProjectTitle>{/*{project.name} */}</ProjectTitle>
                <LayersControls>
                    <ControlItem
                        icon={`layer-up-button`}
                        active={selectedLayerId !== undefined && layers.map((i) => i.id).indexOf(selectedLayerId) > 0}
                        onClick={moveLayerUp}
                    />
                    <ControlItem
                        icon={`layer-down-button`}
                        active={selectedLayerId !== undefined && layers.map((i) => i.id).indexOf(selectedLayerId) < layers.length - 1}
                        onClick={moveLayerDown}
                    />
                    <ControlItem icon={`duplicate-layer-button`} active={selectedLayerId !== undefined} onClick={dublicateLayer} />
                    <ControlItem icon={`delete-layer-button`} active={selectedLayerId !== undefined} onClick={deleteLayer} />
                </LayersControls>
                <DownloadButton onClick={onCreateJob} is_rendering={is_rendering} />
            </Top>
            <Middle>
                <Menu>
                    <MenuPointer ref={menuPointerRef} />
                    <MenuItem
                        name={`template`}
                        selected={storeVisible === `template` || selectedLayerType === `template`}
                        onClick={() => {
                            openStoreWindow(`template`);
                        }}
                    />
                    <MenuItem
                        name={`wave`}
                        selected={storeVisible === `wave` || selectedLayerType === `wave`}
                        onClick={() => {
                            openStoreWindow(`wave`);
                        }}
                    />
                    <MenuItem
                        name={`progress`}
                        selected={storeVisible === `progress` || selectedLayerType === `progress`}
                        onClick={() => {
                            openStoreWindow(`progress`);
                        }}
                    />
                    <MenuItem
                        name={`image`}
                        selected={storeVisible === `images` || selectedLayerType === `image`}
                        onClick={() => {
                            openStoreWindow(`images`);
                        }}
                    />
                    <MenuItem
                        name={`text`}
                        selected={selectedLayerType === `text`}
                        onClick={() => {
                            window.dispatchEvent(
                                new CustomEvent(`ADD_LAYER`, {
                                    detail: {
                                        ...default_properties,
                                        text: `Text`,
                                        type: `text`,
                                        font_family: FontList[0],
                                        height_p: 0.1,
                                        id: uuid(),
                                    },
                                })
                            );
                        }}
                    />
                    <MenuItem name={`save`} selected={lastSavedId !== activeHistoryId} onClick={onSaveProject} />
                    <MenuItem
                        name={`back`}
                        onClick={() => {
                            push(`/`);
                        }}
                    />
                </Menu>
                <Workspace ref={wrapperRef}>
                    <WorkspaceWrapper size={canvasWrapperSize} id="canvas">
                        <CanvasWrapper size={canvasWrapperSize} canDragOutside={canDragOutside}>
                            {
                                // selectedLayerId && layers.get(selectedLayerId).type === `text` ? <CanvasInput id={`canvas_input`} layer={layers.get(selectedLayerId)} value={layers.get(selectedLayerId).text} onChange={(e) => { onParamChange(`text`, e.target.value) }} /> : null
                            }
                            {layers.map((layer, index) => {
                                let selected = selectedLayerId === layer.id;
                                return (
                                    <Canvas
                                        key={layers[index].id}
                                        id={`layer_${index}`}
                                        layer={layer}
                                        resolutionDrop={resolutionDrop}
                                        selected={selected}
                                    />
                                );
                            })}
                            {layers.map((layer, index) => {
                                let [width, height, left, top] = [
                                    layer.width_p * canvasWrapperSize.width,
                                    layer.height_p * canvasWrapperSize.height,
                                    canvasWrapperSize.width * (layer.left - 0.5 * canDragOutside),
                                    canvasWrapperSize.height * (layer.top - 0.5 * canDragOutside),
                                ];
                                let selected = selectedLayerId === layer.id;
                                let is_text = selectedLayerId !== undefined && layers.get(selectedLayerId).type !== `text`;
                                const newHeight = layer?.newHeight
                                    ? layer?.newHeight * canvasWrapperSize.height
                                    : layer.height_p * canvasWrapperSize.height;

                                return (
                                    <Rnd
                                        // bounds="#canvas"
                                        key={index}
                                        style={{ border: `1px solid ${selected ? mvConsts.theme.purple : `transparent`}` }}
                                        id={`rnd_${index}`}
                                        disableDragging={false}
                                        enableResizing={{
                                            left: true,
                                            right: true,
                                            top: is_text,
                                            bottom: is_text,
                                            topRight: is_text,
                                            bottomRight: is_text,
                                            topLeft: is_text,
                                            bottomLeft: is_text,
                                        }}
                                        size={{ width: width, height: is_text ? height : newHeight }}
                                        position={{ x: left, y: top }}
                                        // resizeHandleStyles={{
                                        //     topRight: { width: `15px`, height: `15px`, background: selected ? mvConsts.theme.purple : `transparent` },
                                        //     bottomRight: { width: `15px`, height: `15px`, background: selected ? mvConsts.theme.purple : `transparent` },
                                        //     bottomLeft: { width: `15px`, height: `15px`, background: selected ? mvConsts.theme.purple : `transparent` },
                                        //     topLeft: { width: `15px`, height: `15px`, background: selected ? mvConsts.theme.purple : `transparent` },
                                        // }}
                                        onResizeStop={(e, direction, ref, delta, position) => {
                                            // console.log(convert(height) / canvasWrapperSize.height)
                                            // console.log(convert(newHeight) / canvasWrapperSize.height)

                                            setLayers(
                                                [...layers].map((a, b) =>
                                                    b === index
                                                        ? {
                                                              ...a,
                                                              left:
                                                                  (position.x + (canvasWrapperSize.width / 2) * +canDragOutside) /
                                                                  canvasWrapperSize.width,
                                                              top:
                                                                  (position.y + (canvasWrapperSize.height / 2) * +canDragOutside) /
                                                                  canvasWrapperSize.height,
                                                              width_p: convert(ref.style.width) / canvasWrapperSize.width,
                                                              height_p: is_text
                                                                  ? convert(ref.style.height) / canvasWrapperSize.height
                                                                  : convert(height) / canvasWrapperSize.height,
                                                              // height_p: convert(ref.style.height) / canvasWrapperSize.height,
                                                          }
                                                        : a
                                                )
                                            );
                                        }}
                                        onDragStop={(e, d) => {
                                            const percentWidth = (width / canvasWrapperSize.width) * 0.8;
                                            const percentHeight = (height / canvasWrapperSize.height) * 0.8;
                                            // console.log( (d.x / canvasWrapperSize.width)  , percentWidth  )
                                            // console.log((d.y / canvasWrapperSize.height) , percentHeight)
                                            // console.log((d.x + canvasWrapperSize.width / 2 * +0) / canvasWrapperSize.width)
                                            // console.log((d.x + canvasWrapperSize.width / 2 * +canDragOutside) / canvasWrapperSize.width)
                                            setLayers(
                                                [...layers].map((a, b) =>
                                                    b === index
                                                        ? {
                                                              ...a,
                                                              // left: (d.x + canvasWrapperSize.width / 2 * +canDragOutside) / canvasWrapperSize.width,
                                                              // top: (d.y + canvasWrapperSize.height / 2 * +canDragOutside) / canvasWrapperSize.height,
                                                              left:
                                                                  d.x / canvasWrapperSize.width < -percentWidth
                                                                      ? -percentWidth
                                                                      : d.x / canvasWrapperSize.width > 0.9
                                                                      ? 0.9
                                                                      : d.x / canvasWrapperSize.width,
                                                              top:
                                                                  d.y / canvasWrapperSize.height < -percentHeight
                                                                      ? -percentHeight
                                                                      : d.y / canvasWrapperSize.height > 0.9
                                                                      ? 0.9
                                                                      : (d.y + (canvasWrapperSize.height / 2) * +canDragOutside) /
                                                                        canvasWrapperSize.height,
                                                          }
                                                        : a
                                                )
                                            );
                                        }}
                                        onMouseDown={() => {
                                            setSelectedLayerId(layer.id);
                                        }}
                                    ></Rnd>
                                );
                            })}
                        </CanvasWrapper>
                    </WorkspaceWrapper>
                    <AudioControls size={canvasWrapperSize}>
                        {/* <ControlItem icon={muted ? `volume-muted` : `volume`} onClick={() => { setVolumeVisible(true) }} /> */}
                        <ControlItem
                            icon={paused ? `play-button` : `pause-button`}
                            extra={`width: 80px; margin: 0 5px; background: linear-gradient(135deg, #F8CF3A, #DE2BAD);`}
                            onClick={playPauseToggle}
                            id={`playPauseToggle`}
                        />
                        <ControlItem
                            icon={`trim-button`}
                            onClick={() => {
                                setTrimmerVisible(!trimmerVisible);
                            }}
                        />
                    </AudioControls>
                </Workspace>
            </Middle>
        </Wrapper>
    );
};

const Title = styled(Frame)`
    width: 190px;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    font-weight: bold;

    &:before {
        font-size: 24px;
        font-weight: bold;
        content: '${(props) => props.content}';
    }

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url('${imageDoubleBackArrow}') center no-repeat;
        cursor: pointer;
        transform: rotate(180deg);
    }
`;

const WaveformCanvas = styled.canvas`
    width: 700px;
    height: 110px;
    position: fixed;
`;

const CanvasInput = styled.input`
    width: calc(100% * ${(props) => props.layer.width_p});
    height: calc(100% * ${(props) => props.layer.height_p});
    background: transparent;
    align-items: center;
    font-size: calc(100% * ${(props) => props.layer.height_p / window.devicePixelRatio} * 6.15) !important;
    text-align: center;
    color: red !important;
    caret-color: white;
    position: absolute;
    top: calc(100% * ${(props) => props.layer.top});
    left: calc(100% * ${(props) => props.layer.left});
    z-index: 2;
`;

let default_properties = {
    top: 0.25,
    left: 0.25,
    width_p: 0.5,
    height_p: 0.5,
    color: `#2DC76D`,
};

const Cros = styled(Frame)`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
`;

const Waveform = styled(Frame)`
    width: 700px;
    height: 110px;
`;

const TrimmerWindow = styled(Frame)`
    padding: 20px 10px 10px 10px;
    position: fixed;
    bottom: 70px;
    z-index: 2;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 10px;
    transform: translateY(${(props) => (props.visible ? 0 : `calc(100% + 70px)`)});
    background: ${(props) => props.theme.background.support};
`;

const ResolutionOption = styled(Frame)`
    width: 200px;
    height: 30px;
    margin-bottom: 5px;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    cursor: pointer;
    /* &:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.yellow};
        background: ${(props) => (props.selected ? (props) => props.theme.yellow : `transparent`)};
        box-sizing: border-box;
        margin-left: 8px;
    } */

    &:before {
        content: '${(props) => props.before}';
        font-weight: ${(props) => (props.selected ? `bold` : `normal`)};
        margin-right: 8px;
        font-size: 14px;
        color: ${(props) => props.theme.text.primary};
    }

    &:after {
        content: '(${(props) => props.after})';
        margin-right: 8px;
        font-size: 14px;
        color: #888ca6;
    }
`;

const VolumeRange = styled.input.attrs((props) => ({
    type: `range`,
}))`
    flex: 1;
    -webkit-appearance: none;
    user-select: none;
    background: ${(props) => props.theme.green};
    height: 2px;
    outline: none;

    ::-webkit-slider-thumb {
        border: 0.1vw solid #0ff050;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: ${(props) => props.theme.background.primary};
        cursor: pointer;
        -webkit-appearance: none;
    }
`;

const MuteButton = styled(Frame)`
    width: 25px;
    height: 25px;
    margin-left: 5px;
    background: url('${imageVolumeMuted}') center no-repeat;
    opacity: ${(props) => 1 - +!props.muted * 0.5};
    cursor: pointer;
`;

const Audio = styled.audio.attrs((props) => ({
    controls: false,
    preload: `metadata`,
}))``;

const Canvas = styled.canvas`
    width: calc(100% * ${(props) => props.resolutionDrop});
    height: calc(100% * ${(props) => props.resolutionDrop});
    transform: scale(${(props) => 1 / props.resolutionDrop});
    position: absolute;
`;

const LayerWrapper = styled(Frame)`
    width: 220px;
    padding: 0 10px;
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    background: rgba(255, 255, 255, ${(props) => (props.selected ? 0.1 : 0)});

    > * {
        &:nth-child(1) {
            box-sizing: border-box;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: ${(props) => (props.selected ? `linear-gradient(#FF81FF, #A93AFF)` : `rgba(255, 255, 255, 0.2)`)};
            padding: 0;
            margin-left: 8px;
        }

        &:nth-child(2) {
            //width: calc(100% - 4px);
            margin-left: 4px;
            flex: 1;
            align-items: flex-start;
        }

        &:nth-child(3) {
            width: 20px;
            height: 20px;
            background: url('${imageDragAnchorW}') center no-repeat;
            opacity: ${(props) => (props.selected ? 1 : 0.2)};
            box-sizing: border-box;
            margin-right: 8px;
            cursor: move;
        }
    }
`;

const ResolutionWindow = styled(Frame)`
    padding: 10px 10px 0 10px;
    position: fixed;
    top: 80px;
    left: calc(120px);
    transform: translateX(${(props) => (props.visible ? `0%` : `calc(-100% - 10px)`)});
    opacity: ${(props) => +props.visible};
    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
    z-index: 3;
    align-items: flex-start;
    background: ${(props) => props.theme.background.overlay};
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
`;

const DimensionsWindow = styled(Frame)`
    width: 220px;
    position: fixed;
    padding: 10px 0 0 0;
    top: 80px;
    left: calc(120px + 180px - 60px);
    transform: translateY(${(props) => (props.visible ? `0%` : `-10px`)});
    opacity: ${(props) => +props.visible};
    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
    z-index: 3;
    background: ${(props) => props.theme.background.overlay};
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
`;

const ParametersWindow = styled(Frame)`
    width: 290px;
    height: calc(100vh - 80px - 20px);
    /* max-height: calc(100vh - 80px); */
    padding: 10px 10px 10px 10px;
    position: fixed;
    top: 80px;
    left: 120px;
    transform: translateX(${(props) => (props.visible ? `0%` : `calc(-100% - 120px)`)});
    z-index: 2;
    align-items: flex-start;
    overflow-y: scroll;
    display: block;
    border-radius: 0 6px 6px 0;
    background: ${(props) => props.theme.background.secondary};
`;

const StoreWindow = styled(Frame)`
    width: 290px;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    padding: 10px 10px 10px 10px;
    display: block;
    overflow-y: scroll;
    position: fixed;
    top: 80px;
    left: 120px;
    transform: translateX(${(props) => (props.visible ? `0%` : `calc(-100% - 120px)`)});
    z-index: 2;
    border-radius: 0 6px 6px 0;
    background: ${(props) => props.theme.background.secondary};
`;

const LayersWindow = styled(Frame)`
    width: 220px;
    min-height: 200px;
    position: fixed;
    right: 0px;
    top: 80px;
    transform: translateX(${(props) => (props.visible ? `0%` : `calc(100% + 10px)`)});
    z-index: 1;
    justify-content: flex-start;
    border-radius: 6px 0 0 6px;
    background: ${(props) => props.theme.background.primary};
    overflow-y: scroll;
    max-height: calc(100vh - 80px);

    > * {
        /* &:first-child {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;
    } */
    }
`;

const LayersButton = styled(Frame)`
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background: linear-gradient(90deg, #84bcff, #0175ff);
    position: fixed;
    right: 10px;
    bottom: 10px;
    cursor: pointer;

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url('${imageLayersButton}') center no-repeat;
    }
`;

const MenuItem = styled(Frame).attrs((props) => ({
    ...props,
    id: `menu`,
}))`
    width: 120px;
    height: 84px;
    cursor: pointer;
    /* background: ${(props) => (props.selected ? props.theme.background.secondary : `#0B1256`)}; */
    background: #0b1256;

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        z-index: 1;
        background: url('${(props) => (props.name ? require(`../../../assets/images/menu-${props.name}.svg`) : null)}') center no-repeat;
        opacity: ${(props) => (props.selected ? 1 : 0.5)};
    }

    &:after {
        content: '${(props) => props.name.charAt(0).toUpperCase() + props.name.slice(1)}';
        font-size: 14px;
        margin-top: 10px;
        color: white;
        z-index: 1;
        opacity: ${(props) => (props.selected ? 1 : 0.5)};
    }
`;

const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: ${(props) => props.theme.background.primary};

    /* * { border: 1px solid red; box-sizing: border-box; } */

    /* * {
        border: 1px solid red; box-sizing: border-box; color: ${(props) => props.theme.text.primary}; font-size: 12px;
    }; */
`;

const WorkspaceWrapper = styled(Frame)`
    width: calc(${(props) => props.size.width}px + 20vw);
    height: calc(${(props) => props.size.height}px + 50vh);
    margin-right: 80px;
`;

const CanvasWrapper = styled(Frame)`
    width: ${(props) => props.size.width}px;
    height: ${(props) => props.size.height}px;
    overflow: hidden;
    position: ${(props) => (props.canDragOutside ? `inherit` : `relative`)};
    //margin-right: 80px;
    background: ${(props) => props.theme.background.secondary};
`;

const DownloadButton = styled(Frame)`
    width: 163px;
    height: 50px;
    border-radius: 25px;
    margin: 15px;
    background: linear-gradient(#ff81ff, #a93aff);
    cursor: pointer;

    &:after {
        content: '${(props) => (props.is_rendering ? 'Rendering' : 'Render')}';
        font-size: 15px;
        font-family: Bold;
        color: white;
    }
`;

const Logo = styled(Frame)`
    width: 120px;
    height: 80px;
    cursor: pointer;

    &:after {
        content: '';
        width: 73px;
        height: 64px;
        background: url('${imageMakewaveOnlineLogo}') center no-repeat;
    }
`;

const ProjectTitle = styled(Frame)`
    width: 100%;
    flex: 1;
    height: 50px;

    font-size: 13px;
    font-weight: bold;
    color: ${(props) => props.theme.text.primary};
`;

const ControlItem = styled(Frame)`
    width: 50px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    background: ${(props) => props.theme.background.support};

    &:after {
        content: '';
        width: 24px;
        height: 24px;
        background: url('${(props) => (props.icon ? require(`../../../assets/images/${props.icon}.svg`) : null)}') center no-repeat;
        opacity: ${(props) => (props.active ? 1 : 0.5)};
        transition: 0.2s;
    }

    ${(props) => props.extra}
`;

const VolumeWindow = styled(Frame)`
    width: 220px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    transform: translateY(${(props) => (props.visible ? `calc(-100% - 5px)` : 0)});
    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
    opacity: ${(props) => +props.visible};
    background: ${(props) => props.theme.background.secondary};
    border-radius: 10px;
`;

const AudioControls = styled(Frame)`
    height: 50px;
    /* position: fixed; */
    /* bottom: 10px; */
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    width: ${(props) => props.size.width}px;
    margin: 20px 80px 10px 0;
`;

const ProjectControls = styled(Frame)`
    width: calc(50px * 3 + 10px * 2);
    height: 50px;
    flex-direction: row;
    justify-content: space-between;
`;

const LayersControls = styled(Frame)`
    width: 230px;
    height: 50px;
    margin-right: 10px;
    flex-direction: row;
    justify-content: space-between;
    background: ${(props) => props.theme.background.secondary};
`;

const Workspace = styled(Frame)`
    width: calc(100vw - 120px);
    height: calc(100vh - 80px);
    /* margin-bottom: 70px; */
`;

const MenuPointer = styled(Frame)`
    width: 100px;
    height: 80px;
    border-radius: 6px;
    position: absolute;
    top: 82.5px;
    background: linear-gradient(90deg, #84bcff, #0175ff);
`;

const Menu = styled(Frame)`
    width: 120px;
    height: calc(100vh - 80px);
    justify-content: flex-start;
    /* background: ${(props) => props.theme.background.secondary}; */
    z-index: 2;
`;

const Top = styled(Frame)`
    width: 100vw;
    height: 80px;
    background: ${(props) => props.theme.background.secondary};
    z-index: 2;

    flex-direction: row;
    justify-content: space-between;
`;

const Middle = styled(Frame)`
    width: 100vw;
    height: calc(100vh - 80px);

    flex-direction: row;
`;

export default Editor;
