/*eslint-disable*/
import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useMappedState } from 'redux-react-hook'

import { Frame, H1, H, Button } from '../UIKit/styled-templates'
import Form from '../UIKit/Form'

import useCurrentUser from '../helpers/useCurrentUser'

let fields = [
    { type: `password`, name: `Old password` },
    { type: `password`, name: `New password` },
    { type: `password`, name: `Confirm` },
]

let ChangePasswordPage = (props) => {

    let { currentUser } = useCurrentUser()
    let [data, setData] = useState({ email: currentUser.email })

    return (
        <Frame>
            <Content>
                <Title>Profile</Title>
                <Form
                    data={data}
                    fields={fields}
                    onChange={(key, value) => { setData({ ...data, [key]: value }) }}
                />
                <SaveButton />
            </Content>
        </Frame>
    )
}

const SaveButton = styled(Frame)`
    width: 220px;
    height: 55px;
    border-radius: 27.5px;
    background: ${props => props.theme.yellow};
    color: red;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:after {
        content: 'Save';
        font-size: 14px;
        font-family: Bold;
    }
`;

const Content = styled(Frame)`
    width: 1100px;
    margin-bottom: 100px;
    > * {
        &:nth-child(3) {
            margin-top: 45px;
        }
    }
`;

const Title = styled(H)`
    margin: 60px 0px;
`;

export default ChangePasswordPage;
/*eslint-enable*/