import axios from 'axios';

const ServersAPI = {
    getServer: async (id) => {
        const { data: pld } = await axios.get(`/server/${id}`);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    getAllServers: async () => {
        const { data: pld } = await axios.get(`/servers/all`);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    createServer: async (data) => {
        const { data: pld } = await axios.post(`/server`, data);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    updateServer: async (data) => {
        const { data: pld } = await axios.post(`/server`, data);
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },

    deleteServer: async (id) => {
        const { data: pld } = await axios.post(`/server/delete`, { id });
        if (pld.error) {
            throw pld.error;
        }
        return pld.result;
    },
};

export default ServersAPI;
