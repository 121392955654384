/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useMappedState } from 'redux-react-hook'
import styled, { keyframes } from 'styled-components';

import { Frame } from '../UIKit/styled-templates'
import LoadingAnimation from './loading-animation'

import useCustomDispatch from '../helpers/useCustomDispatch'

let PreloaderPage = (props) => {

    let {loading} = useMappedState(useCallback((state) => ({
        loading: state.users.loading && state.templates.loading && state.projects.loading,
    }), []))

    let [calls, setCalls] = useState(0)
    useCustomDispatch('OPEN_PRELOADER', () => { setCalls(calls + 1) })
    useCustomDispatch('CLOSE_PRELOADER', () => { setCalls(Math.max(0, calls - 1)) })

    return (
        <Wrapper visible={calls > 0 || loading} >
            {/* <LoadingAnimation /> */}
        </Wrapper>
    );
}
const Wrapper = styled(Frame)`
    width: 100vw;
    height: 100vh;
    /* background: ${props => props.theme.background.primary}; */
    background: rgba(0, 0, 0, ${props => props.visible ? 0.1 : 0});
    visibility: ${props => props.visible ? `visible` : `hidden`};
    backdrop-filter: blur(${props => props.visible ? 8 : 0}px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    &:after {
        content: 'Loading';
        font-size: 24px;
        font-weight: bold;
        color: rgba(255, 255, 255, ${props => props.visible ? 1 : 0});
        display: flex;
    }
`;

export default PreloaderPage;
/*eslint-enable*/