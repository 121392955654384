export const DEFAULT_TIMEZONE = 'Europe/Moscow';

export const FACELESS_AVATAR = 'https://www.englishpatient.org/storage/e7d099fe-653b-488e-9970-f693ab585f05.png';

// export const API_ENDPOINT = window.location.hostname.includes('localhost') ? 'http://localhost:5000' : 'https://makewave.online/api';
export const API_ENDPOINT = 'https://makewave.online/api';
// export const UPLOAD_AUDIO_API_ENDPOINT = 'https://os.mipt.ru/wa/audio/upload';
export const UPLOAD_AUDIO_API_ENDPOINT = 'https://api3.aux.makewave.online/audio/upload';
export const NCHAN_SUB_ENDPOINT = 'https://api.subtitles.love/sub';

export const LOGIN_BACKGROUNR_IMAGE = 'https://www.englishpatient.org/english-patient-files/36208fa0-49a1-406a-8ac7-ecfaeb810a55.jpg';

// export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';

export const SUPPORTED_LANGUAGES = ['English', 'Russian'];
