import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import imageMakewaveOnlineLogo from '../../assets/images/makewave-online-logo.svg';

import { Frame, Link } from '../UIKit/styled-templates';

import useCurrentUser from '../helpers/useCurrentUser';
import useOnClickOutside from '../helpers/useOnClickOutside';

const PageWrapper = (props) => {
    const { currentUser, logOut } = useCurrentUser();
    let namItems = GUEST_ITEMS;

    switch (currentUser.userRole) {
        case `guest`:
            namItems = GUEST_ITEMS;
            break;
        case `user`:
            namItems = USER_ITEMS;
            break;
        case `admin`:
            namItems = ADMIN_ITEMS;
            break;
        default:
            break;
    }

    let [popUpVisible, setPopUpVisible] = useState(false);
    let popUpRef = useRef();
    useOnClickOutside(popUpRef, () => {
        setPopUpVisible(false);
    });

    return (
        <Wrapper>
            <Header>
                <Workspace extra={`flex-direction: row; justify-content: space-between;`}>
                    <Link to={`/`}>
                        <Logo />
                    </Link>
                    <Frame row>
                        {namItems.map((item, index) => {
                            return (
                                <Link key={index} to={item.url}>
                                    <MenuItem content={item.label} />
                                </Link>
                            );
                        })}
                        {currentUser.userRole !== `guest` ? (
                            <MenuItem
                                onClick={() => {
                                    setPopUpVisible(true);
                                }}
                                content={`Account`}
                            />
                        ) : (
                            <Link to={`/login`}>
                                <LoginButton />
                            </Link>
                        )}
                        <OptionsPopUp ref={popUpRef} visible={popUpVisible}>
                            {/* <Link to={`/settings`} ><Frame>Profile Settings</Frame></Link> */}
                            {/* <Link to={`/connections`} ><Frame>Profile Connections</Frame></Link> */}
                            {/* <Link to={`/billing`}>
                                <Frame>Billing</Frame>
                            </Link> */}
                            {/* <Link to={`/change-password`} ><Frame>Change password</Frame></Link> */}
                            <Frame onClick={logOut}>Logout</Frame>
                        </OptionsPopUp>
                    </Frame>
                </Workspace>
            </Header>
            <Body>{props.children}</Body>
            <Footer>
                <Workspace extra={`flex-direction: row; justify-content: space-between; * { font-size: 14px; color: white; };`}>
                    <Frame row extra={`> * { margin-left: 40px; };`}>
                        <Frame>Contact us</Frame>
                        <Frame>Privacy Policy</Frame>
                        <Frame>Terms and Conditions </Frame>
                    </Frame>
                    <Frame row>
                        <SocialMedia name={`linkedin`} />
                        <SocialMedia name={`twitter`} />
                        <SocialMedia name={`facebook`} />
                        <SocialMedia name={`instagram`} />
                    </Frame>
                </Workspace>
            </Footer>
        </Wrapper>
    );
};

const LoginButton = styled(Frame)`
    width: 150px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid white;
    box-sizing: border-box;
    margin-left: 40px;
    &:hover {
        opacity: 0.8;
        transform: scale(1.02);
    }
    &:after {
        content: 'Login';
        font-size: 18px;
        font-weight: bold;
        color: white;
    }
`;

const OptionsPopUp = styled(Frame)`
    padding: 15px 15px 15px 30px;
    border-radius: 6px;
    background: ${(props) => props.theme.background.overlay};
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 70px;
    right: 0;
    align-items: flex-end;
    z-index: 2;
    opacity: ${(props) => +props.visible};
    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};
    transform: translateY(${(props) => (props.visible ? 10 : 0)}px);
    * {
        color: white;
        font-size: 14px;
        font-weight: bold;
        line-height: 38px;
        cursor: pointer;
        &:hover {
            transform: translateX(3px);
        }
    }
`;

const SocialMedia = styled(Frame)`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${(props) => props.theme.background.secondary};
    margin-left: 10px;
    cursor: pointer;
    &:after {
        content: '';
        width: 30px;
        height: 30px;
        background: url('${(props) => (props.name ? require(`../../assets/images/social-media-${props.name}.svg`) : null)}') center no-repeat;
    }
`;

const MenuItem = styled(Frame)`
    margin-left: 27px;
    cursor: pointer;
    &:after {
        content: '${(props) => props.content}';
        font-size: 16px;
        font-weight: bold;
        color: white;
    }
`;

const Logo = styled(Frame)`
    width: 73px;
    height: 64px;
    background: url('${imageMakewaveOnlineLogo}') center no-repeat;
    cursor: pointer;
`;

const Workspace = styled(Frame)`
    width: 1100px;
    height: 100%;
    position: relative;
`;

const Body = styled(Frame)`
    width: 1100px;
    min-height: calc(100vh - 80px - 50px);
    justify-content: flex-start;
    height: auto;
    margin-top: 80px;
`;

const Header = styled(Frame)`
    width: 100vw;
    height: 80px;
    background: ${(props) => props.theme.background.secondary};
    position: absolute;
    top: 0;
`;

const Footer = styled(Frame)`
    width: 100vw;
    height: 50px;
    background: ${(props) => props.theme.background.secondary};
`;

const Wrapper = styled(Frame)`
    width: 100vw;
    min-height: 100vh;
`;

const GUEST_ITEMS = [
    // {
    //     label: 'Features',
    //     name: 'features',
    //     url: '/features'
    // },
    // {
    //     label: 'Pricing',
    //     name: 'pricing',
    //     url: '/pricing'
    // },
    // {
    //     label: 'Blog',
    //     name: 'blog',
    //     url: '/blog'
    // },
    // {
    //     label: 'FAQ',
    //     name: 'faq',
    //     url: '/faq'
    // },
];

const USER_ITEMS = [
    {
        label: 'Workspace',
        name: 'workspace',
        url: '/workspace',
    },
    {
        label: 'Pricing',
        name: 'pricing',
        url: '/pricing',
    },
];

const ADMIN_ITEMS = [
    {
        label: 'Workspace',
        name: 'workspace',
        url: '/workspace',
    },
    {
        label: 'Templates',
        name: 'templates',
        url: '/templates',
    },
    // {
    //     label: 'Users',
    //     name: 'users',
    //     url: '/users',
    // },
    // {
    //     label: 'Subscriptions',
    //     name: 'subscriptions',
    //     url: '/subscriptions',
    // },
    {
        label: 'Servers',
        name: 'servers',
        url: '/servers',
    },
    {
        label: 'Configs',
        name: 'configs',
        url: '/configs',
    },
];

export default PageWrapper;
